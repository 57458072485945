// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { BundleSubproductRecordModel, BundleSubproductRecordModelType } from "./BundleSubproductRecordModel"
import { BundleSubproductRecordModelSelector } from "./BundleSubproductRecordModel.base"
import { FileFieldModel, FileFieldModelType } from "./FileFieldModel"
import { FileFieldModelSelector } from "./FileFieldModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { MarketingContentApprovalWorkflowStageEnumType } from "./MarketingContentApprovalWorkflowStageEnum"
import { MenuHierarchyRecordModel, MenuHierarchyRecordModelType } from "./MenuHierarchyRecordModel"
import { MenuHierarchyRecordModelSelector } from "./MenuHierarchyRecordModel.base"
import { ProductCategoryRecordModel, ProductCategoryRecordModelType } from "./ProductCategoryRecordModel"
import { ProductCategoryRecordModelSelector } from "./ProductCategoryRecordModel.base"
import { ProductDietaryTagRecordModel, ProductDietaryTagRecordModelType } from "./ProductDietaryTagRecordModel"
import { ProductDietaryTagRecordModelSelector } from "./ProductDietaryTagRecordModel.base"
import { ProductInstructionRecordModel, ProductInstructionRecordModelType } from "./ProductInstructionRecordModel"
import { ProductInstructionRecordModelSelector } from "./ProductInstructionRecordModel.base"
import { ProductModelDescriptionFieldModel, ProductModelDescriptionFieldModelType } from "./ProductModelDescriptionFieldModel"
import { ProductModelDescriptionFieldModelSelector } from "./ProductModelDescriptionFieldModel.base"
import { ProductPrimaryTagRecordModel, ProductPrimaryTagRecordModelType } from "./ProductPrimaryTagRecordModel"
import { ProductPrimaryTagRecordModelSelector } from "./ProductPrimaryTagRecordModel.base"
import { ProductRecordModel, ProductRecordModelType } from "./ProductRecordModel"
import { ProductSecondaryTagRecordModel, ProductSecondaryTagRecordModelType } from "./ProductSecondaryTagRecordModel"
import { ProductSecondaryTagRecordModelSelector } from "./ProductSecondaryTagRecordModel.base"
import { ProductSubcategoryRecordModel, ProductSubcategoryRecordModelType } from "./ProductSubcategoryRecordModel"
import { ProductSubcategoryRecordModelSelector } from "./ProductSubcategoryRecordModel.base"
import { ProductTagRecordModel, ProductTagRecordModelType } from "./ProductTagRecordModel"
import { ProductTagRecordModelSelector } from "./ProductTagRecordModel.base"
import { ProductTertiaryTagRecordModel, ProductTertiaryTagRecordModelType } from "./ProductTertiaryTagRecordModel"
import { ProductTertiaryTagRecordModelSelector } from "./ProductTertiaryTagRecordModel.base"
import { RetailMenuHierarchyRecordModel, RetailMenuHierarchyRecordModelType } from "./RetailMenuHierarchyRecordModel"
import { RetailMenuHierarchyRecordModelSelector } from "./RetailMenuHierarchyRecordModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  replacementProduct: ProductRecordModelType;
}

/**
 * ProductRecordBase
 * auto generated base class for the model ProductRecordModel.
 *
 * Record of type Product (product)
 */
export const ProductRecordModelBase = withTypedRefs<Refs>()(ModelBase
  .named('ProductRecord')
  .props({
    __typename: types.optional(types.literal("ProductRecord"), "ProductRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _stage: types.union(types.undefined, MarketingContentApprovalWorkflowStageEnumType),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    defaultBreadcrumb: types.union(types.undefined, types.array(types.late((): any => MenuHierarchyRecordModel))),
    description: types.union(types.undefined, types.null, types.late((): any => ProductModelDescriptionFieldModel)),
    dietaryTags: types.union(types.undefined, types.array(types.late((): any => ProductDietaryTagRecordModel))),
    discontinuedAt: types.union(types.undefined, types.null, types.frozen()),
    displayCategory: types.union(types.undefined, types.null, types.late((): any => ProductCategoryRecordModel)),
    displayName: types.union(types.undefined, types.null, types.string),
    displayPageImages: types.union(types.undefined, types.array(types.late((): any => FileFieldModel))),
    displaySubcategory: types.union(types.undefined, types.array(types.late((): any => ProductSubcategoryRecordModel))),
    grossPrice: types.union(types.undefined, types.null, types.frozen()),
    id: types.union(types.undefined, types.frozen()),
    liveAt: types.union(types.undefined, types.null, types.frozen()),
    menuHierarchy: types.union(types.undefined, types.array(types.late((): any => MenuHierarchyRecordModel))),
    netPrice: types.union(types.undefined, types.null, types.frozen()),
    newTagEndDate: types.union(types.undefined, types.null, types.frozen()),
    newTagStartDate: types.union(types.undefined, types.null, types.frozen()),
    ogImage: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    orderQuantityLimit: types.union(types.undefined, types.null, types.frozen()),
    primaryDisplayTag: types.union(types.undefined, types.null, types.late((): any => ProductPrimaryTagRecordModel)),
    primaryName: types.union(types.undefined, types.null, types.string),
    productInstruction: types.union(types.undefined, types.null, types.late((): any => ProductInstructionRecordModel)),
    productStatus: types.union(types.undefined, types.null, types.string),
    promotionTotal: types.union(types.undefined, types.null, types.frozen()),
    quantityTag: types.union(types.undefined, types.null, types.string),
    replacementProduct: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => ProductRecordModel))),
    retailMenuHierarchy: types.union(types.undefined, types.array(types.late((): any => RetailMenuHierarchyRecordModel))),
    secondaryDisplayTag: types.union(types.undefined, types.null, types.late((): any => ProductSecondaryTagRecordModel)),
    secondaryName: types.union(types.undefined, types.null, types.string),
    seoDescription: types.union(types.undefined, types.null, types.string),
    seoTitle: types.union(types.undefined, types.null, types.string),
    sku: types.union(types.undefined, types.null, types.string),
    skuvantageImages: types.union(types.undefined, types.array(types.late((): any => FileFieldModel))),
    slug: types.union(types.undefined, types.null, types.string),
    subProductsNew: types.union(types.undefined, types.array(types.late((): any => BundleSubproductRecordModel))),
    tags: types.union(types.undefined, types.array(types.late((): any => ProductTagRecordModel))),
    tertiaryDisplayTag: types.union(types.undefined, types.null, types.late((): any => ProductTertiaryTagRecordModel)),
    tileImage: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class ProductRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _stage() { return this.__attr(`_stage`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get discontinuedAt() { return this.__attr(`discontinuedAt`) }
  get displayName() { return this.__attr(`displayName`) }
  get grossPrice() { return this.__attr(`grossPrice`) }
  get id() { return this.__attr(`id`) }
  get liveAt() { return this.__attr(`liveAt`) }
  get netPrice() { return this.__attr(`netPrice`) }
  get newTagEndDate() { return this.__attr(`newTagEndDate`) }
  get newTagStartDate() { return this.__attr(`newTagStartDate`) }
  get orderQuantityLimit() { return this.__attr(`orderQuantityLimit`) }
  get primaryName() { return this.__attr(`primaryName`) }
  get productStatus() { return this.__attr(`productStatus`) }
  get promotionTotal() { return this.__attr(`promotionTotal`) }
  get quantityTag() { return this.__attr(`quantityTag`) }
  get secondaryName() { return this.__attr(`secondaryName`) }
  get seoDescription() { return this.__attr(`seoDescription`) }
  get seoTitle() { return this.__attr(`seoTitle`) }
  get sku() { return this.__attr(`sku`) }
  get slug() { return this.__attr(`slug`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  defaultBreadcrumb(builder: string | MenuHierarchyRecordModelSelector | ((selector: MenuHierarchyRecordModelSelector) => MenuHierarchyRecordModelSelector) | undefined) { return this.__child(`defaultBreadcrumb`, MenuHierarchyRecordModelSelector, builder) }
  description(builder: string | ProductModelDescriptionFieldModelSelector | ((selector: ProductModelDescriptionFieldModelSelector) => ProductModelDescriptionFieldModelSelector) | undefined) { return this.__child(`description`, ProductModelDescriptionFieldModelSelector, builder) }
  dietaryTags(builder: string | ProductDietaryTagRecordModelSelector | ((selector: ProductDietaryTagRecordModelSelector) => ProductDietaryTagRecordModelSelector) | undefined) { return this.__child(`dietaryTags`, ProductDietaryTagRecordModelSelector, builder) }
  displayCategory(builder: string | ProductCategoryRecordModelSelector | ((selector: ProductCategoryRecordModelSelector) => ProductCategoryRecordModelSelector) | undefined) { return this.__child(`displayCategory`, ProductCategoryRecordModelSelector, builder) }
  displayPageImages(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`displayPageImages`, FileFieldModelSelector, builder) }
  displaySubcategory(builder: string | ProductSubcategoryRecordModelSelector | ((selector: ProductSubcategoryRecordModelSelector) => ProductSubcategoryRecordModelSelector) | undefined) { return this.__child(`displaySubcategory`, ProductSubcategoryRecordModelSelector, builder) }
  menuHierarchy(builder: string | MenuHierarchyRecordModelSelector | ((selector: MenuHierarchyRecordModelSelector) => MenuHierarchyRecordModelSelector) | undefined) { return this.__child(`menuHierarchy`, MenuHierarchyRecordModelSelector, builder) }
  ogImage(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`ogImage`, FileFieldModelSelector, builder) }
  primaryDisplayTag(builder: string | ProductPrimaryTagRecordModelSelector | ((selector: ProductPrimaryTagRecordModelSelector) => ProductPrimaryTagRecordModelSelector) | undefined) { return this.__child(`primaryDisplayTag`, ProductPrimaryTagRecordModelSelector, builder) }
  productInstruction(builder: string | ProductInstructionRecordModelSelector | ((selector: ProductInstructionRecordModelSelector) => ProductInstructionRecordModelSelector) | undefined) { return this.__child(`productInstruction`, ProductInstructionRecordModelSelector, builder) }
  replacementProduct(builder: string | ProductRecordModelSelector | ((selector: ProductRecordModelSelector) => ProductRecordModelSelector) | undefined) { return this.__child(`replacementProduct`, ProductRecordModelSelector, builder) }
  retailMenuHierarchy(builder: string | RetailMenuHierarchyRecordModelSelector | ((selector: RetailMenuHierarchyRecordModelSelector) => RetailMenuHierarchyRecordModelSelector) | undefined) { return this.__child(`retailMenuHierarchy`, RetailMenuHierarchyRecordModelSelector, builder) }
  secondaryDisplayTag(builder: string | ProductSecondaryTagRecordModelSelector | ((selector: ProductSecondaryTagRecordModelSelector) => ProductSecondaryTagRecordModelSelector) | undefined) { return this.__child(`secondaryDisplayTag`, ProductSecondaryTagRecordModelSelector, builder) }
  skuvantageImages(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`skuvantageImages`, FileFieldModelSelector, builder) }
  subProductsNew(builder: string | BundleSubproductRecordModelSelector | ((selector: BundleSubproductRecordModelSelector) => BundleSubproductRecordModelSelector) | undefined) { return this.__child(`subProductsNew`, BundleSubproductRecordModelSelector, builder) }
  tags(builder: string | ProductTagRecordModelSelector | ((selector: ProductTagRecordModelSelector) => ProductTagRecordModelSelector) | undefined) { return this.__child(`tags`, ProductTagRecordModelSelector, builder) }
  tertiaryDisplayTag(builder: string | ProductTertiaryTagRecordModelSelector | ((selector: ProductTertiaryTagRecordModelSelector) => ProductTertiaryTagRecordModelSelector) | undefined) { return this.__child(`tertiaryDisplayTag`, ProductTertiaryTagRecordModelSelector, builder) }
  tileImage(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`tileImage`, FileFieldModelSelector, builder) }
}
export function selectFromProductRecord() {
  return new ProductRecordModelSelector()
}

export const productRecordModelPrimitives = selectFromProductRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._stage._status._unpublishingScheduledAt._updatedAt.createdAt.discontinuedAt.displayName.grossPrice.liveAt.netPrice.newTagEndDate.newTagStartDate.orderQuantityLimit.primaryName.productStatus.promotionTotal.quantityTag.secondaryName.seoDescription.seoTitle.sku.slug.updatedAt
