// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CustomerIdentifierQuestionOutputModel, CustomerIdentifierQuestionOutputModelType } from "./CustomerIdentifierQuestionOutputModel"
import { CustomerIdentifierQuestionOutputModelSelector } from "./CustomerIdentifierQuestionOutputModel.base"
import { RootStoreType } from "./index"


/**
 * CustomerIdentifierQuestionsOutputBase
 * auto generated base class for the model CustomerIdentifierQuestionsOutputModel.
 *
 * Generate Customer Identifier Questions Output
 */
export const CustomerIdentifierQuestionsOutputModelBase = ModelBase
  .named('CustomerIdentifierQuestionsOutput')
  .props({
    __typename: types.optional(types.literal("CustomerIdentifierQuestionsOutput"), "CustomerIdentifierQuestionsOutput"),
    questions: types.union(types.undefined, types.array(types.late((): any => CustomerIdentifierQuestionOutputModel))),
    token: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CustomerIdentifierQuestionsOutputModelSelector extends QueryBuilder {
  get token() { return this.__attr(`token`) }
  questions(builder: string | CustomerIdentifierQuestionOutputModelSelector | ((selector: CustomerIdentifierQuestionOutputModelSelector) => CustomerIdentifierQuestionOutputModelSelector) | undefined) { return this.__child(`questions`, CustomerIdentifierQuestionOutputModelSelector, builder) }
}
export function selectFromCustomerIdentifierQuestionsOutput() {
  return new CustomerIdentifierQuestionsOutputModelSelector()
}

export const customerIdentifierQuestionsOutputModelPrimitives = selectFromCustomerIdentifierQuestionsOutput().token
