// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ImgixParamsTransparency {
  grid="grid"
}

/**
* ImgixParamsTransparency
*/
export const ImgixParamsTransparencyEnumType = types.enumeration("ImgixParamsTransparency", [
        "grid",
      ])
