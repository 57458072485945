// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { SampleImageModel, SampleImageModelType } from "./SampleImageModel"
import { SampleImageModelSelector } from "./SampleImageModel.base"
import { RootStoreType } from "./index"


/**
 * GuestCartBase
 * auto generated base class for the model GuestCartModel.
 */
export const GuestCartModelBase = ModelBase
  .named('GuestCart')
  .props({
    __typename: types.optional(types.literal("GuestCart"), "GuestCart"),
    extraBoxFee: types.union(types.undefined, types.null, types.number),
    sampleImages: types.union(types.undefined, types.null, types.array(types.late((): any => SampleImageModel))),
    upsells: types.union(types.undefined, types.array(types.string)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GuestCartModelSelector extends QueryBuilder {
  get extraBoxFee() { return this.__attr(`extraBoxFee`) }
  get upsells() { return this.__attr(`upsells`) }
  sampleImages(builder: string | SampleImageModelSelector | ((selector: SampleImageModelSelector) => SampleImageModelSelector) | undefined) { return this.__child(`sampleImages`, SampleImageModelSelector, builder) }
}
export function selectFromGuestCart() {
  return new GuestCartModelSelector()
}

export const guestCartModelPrimitives = selectFromGuestCart().extraBoxFee.upsells
