// @ts-nocheck
import { roundTotal, formatLineItems } from '@/utils/helpers';
import _ from 'lodash';
import { cast, Instance, SnapshotIn, types, flow } from 'mobx-state-tree';
import {
  MIN_WEIGHT,
  MACRO_TRANSLATIONS,
  LoadingState,
  DEFAULT_ERROR_MESSAGE,
  goalName,
  goalBasedQuestions,
  WLP_WEIGHT_THRESHOLD,
} from '@/utils/constants';
import {
  PACKS_COLLECTION_TYPE,
  BUCKET_CONFIG,
  API_GOAL_MAP,
} from '@/utils/api-constants';
import { withRootStore } from '@/models/helpers/with-root-store';
import { GoalBasedPlanRtoModelType } from '../api';
import {
  PACK_SELECTOR,
  SWAP_CONFIG_SELECTOR,
} from '@/graphql/selectors/goalBased';
import { OrderPlanModel, OrderPlan } from '@/models/cart/order-plan';
import { validateOrderPlan } from '@/models/helpers/validation';
import randomise from 'randomatic';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';
import DefaultSwapConfig from '@/mock-data/swap-config.json';

import { Gender, AgeRanges, Goal } from '@/models/api';

export const GoalBasedModel = types
  .model('GoalBasedStore')
  .props({
    goal: types.maybe(types.enumeration(Object.values(Goal))),
    gender: types.maybe(types.enumeration(Object.values(Gender))),
    exercise: types.maybe(types.string),
    packOverview: types.maybe(types.array(OrderPlanModel)),
    bucket: types.maybe(types.number),
    numberOfDays: types.maybe(types.number),
    activeDay: types.optional(types.number, 1),
    personalised: false, // true for macro calculator;
    ageRange: types.maybe(types.enumeration(Object.values(AgeRanges))),
    weight: types.maybe(types.number),
    fixedCalories: types.maybe(types.number),
    packDetailState: types.optional(
      types.enumeration(Object.values(LoadingState)),
      LoadingState.INITIAL
    ),
    swapConfig: types.maybe(types.frozen()),
    swapCategory: types.maybe(types.string),
    errorMessage: types.maybe(types.string),
  })
  .extend(withRootStore)
  .views((self) => ({
    get isMinimumWeight(): boolean {
      return !_.isNaN(self?.weight) && self?.weight >= MIN_WEIGHT;
    },
    get showActivityLevel(): boolean {
      return self.gender && self.ageRange && this.isMinimumWeight;
    },
    get showCalories(): boolean {
      return !!self.exercise;
    },
    get showPlans(): boolean {
      return !!self.exercise;
    },
    get selectedSwapConfig(): any {
      const found = self?.swapConfig?.find(
        (config: any) => config.goal === API_GOAL_MAP[self.goal]
      );
      if (!found) {
        return self?.swapConfig?.find(
          (config: any) => config.goal === 'default'
        );
      }
      return found;
    },
    get showBulkDiscountBar(): Boolean {
      const selectedSwapCategory = self?.selectedSwapConfig?.swapCategory?.find(
        (config: any) => config.key === self?.swapCategory
      );
      // return true if default swap config is loaded from disk
      return selectedSwapCategory?.showBulkDiscountBar ?? true;
    },
    get allowedHierarchies(): string[] {
      if (!self.selectedSwapConfig) return [];
      const menuHierarchyList = self.selectedSwapConfig?.swapCategory?.find(
        (config: any) => config.key === self.swapCategory
      );
      const menuHierarchyKeys = menuHierarchyList?.menuHierarchy?.map(
        (menuHierarchy: any) => menuHierarchy.key
      );
      return menuHierarchyKeys;
    },
    get presetStr() {
      return [self.gender, self.ageRange, self.exercise, self.weight].join('-');
    },
    get disabled(): boolean {
      return (
        [
          self.goal,
          self.gender,
          self.exercise,
          self.weight,
          self.ageRange,
          self.numberOfDays,
        ].some(_.isNil) || !Boolean(self.weight)
      );
    },
    get showButton(): boolean {
      return (
        [
          self.goal,
          self.gender,
          self.exercise,
          self.weight,
          self.ageRange,
        ].some(_.isNil) || !Boolean(self.weight)
      );
    },
    get macros() {
      const { calConfig, pConfig, cConfig, fConfig } =
        BUCKET_CONFIG[self.goal || Goal.MGAIN];
      const offset = self.gender === Gender.MALE ? 0 : 7.5;
      const bucket = self.bucket || 0;
      const calKg =
        ((calConfig.base + bucket * calConfig.coe) * (100 - offset)) / 100;
      const cal =
        self.fixedCalories || roundTotal(calKg * (self.weight || 0), 0);
      const p = roundTotal(
        (cal * (pConfig.base + pConfig.coe * bucket)) / MACRO_TRANSLATIONS.p,
        1
      );
      const c = roundTotal(
        (cal * (cConfig.base + cConfig.coe * bucket)) / MACRO_TRANSLATIONS.c,
        1
      );
      const f = roundTotal(
        (cal * (fConfig.base + fConfig.coe * bucket)) / MACRO_TRANSLATIONS.f,
        1
      );
      const fibre = self.gender === Gender.MALE ? 34 : 28;
      return { p, c, f, fibre, cal };
    },
    get exerciseKey(): string {
      if (self.goal === Goal.MGAIN || self.goal === Goal.CCTRL) {
        return PACKS_COLLECTION_TYPE[self.goal];
      } else if (self.goal === Goal.WLOSS) {
        const weightSuffix =
          self.weight > WLP_WEIGHT_THRESHOLD ? 'over-70' : 'under-70';
        return `${PACKS_COLLECTION_TYPE[Goal.WLOSS]}-${weightSuffix}`;
      }
      return '';
    },
  }))
  .actions((self) => ({
    setPreset<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T[K]): void {
      self[key] = cast(value);
    },
    setActiveDay(day: number): void {
      self.activeDay = day;
    },
    setBucket(bucket: number): void {
      self.bucket = bucket;
    },
    setSwapCategory(category: string): void {
      self.swapCategory = category;
    },
    reset(): void {
      self.gender = undefined;
      self.exercise = undefined;
      self.numberOfDays = undefined;
      self.packOverview = undefined;
      self.ageRange = undefined;
      self.activeDay = 1;
      self.weight = undefined;
      self.errorMessage = undefined;
      self.fixedCalories = undefined;
      self.packDetailState = LoadingState.INITIAL;
      self.goal = undefined;
    },
    getPackDetails: flow(function* () {
      if (!self.goal || !self.gender || !self.ageRange || !self.macros.cal)
        throw new Error('Invalid Preset');
      const { goal, gender, ageRange, exercise, bucket, weight } = self;
      self.packOverview = undefined;
      try {
        self.packDetailState = LoadingState.LOADING;
        const response: { goalBasedPlans: GoalBasedPlanRtoModelType[] } =
          yield self.rootStore.api.queryGoalBasedPlans(
            {
              ageRange,
              goal,
              bucket,
              weight,
              gender,
              numberOfDays: self.numberOfDays,
              ...(goal === Goal.WLOSS && { key: exercise }),
            },
            PACK_SELECTOR
          ).promise;
        const { goalBasedPlans } = response;

        const formattedPlans = goalBasedPlans.map((plan: any) => {
          return {
            ...plan,
            planId: randomise('Aa0', 16),
            image: plan?.cms?.squareImage?.url,
            lineItems: formatLineItems(plan.lineItems),
          };
        });

        formattedPlans.forEach((formattedPlan: OrderPlan) => {
          const isValid = validateOrderPlan(
            formattedPlan,
            self.rootStore.productStore.productsLookup
          );
          if (!isValid) {
            throw new Error(DEFAULT_ERROR_MESSAGE);
          }
        });

        self.packOverview = cast(formattedPlans);
        self.packDetailState = LoadingState.DONE;
      } catch (error: any) {
        self.packDetailState = LoadingState.ERROR;
        const message = DEFAULT_ERROR_MESSAGE;
        toast.error(message || DEFAULT_ERROR_MESSAGE);
      }
    }),
    getSwapConfig: flow(function* () {
      try {
        const response = yield self.rootStore.api.queryAllSwapConfigsCms(
          { filter: { active: { eq: true } } },
          SWAP_CONFIG_SELECTOR
        ).promise;
        const { allSwapConfigsCms } = response;
        self.swapConfig = allSwapConfigsCms;
      } catch (error: any) {
        self.swapConfig = DefaultSwapConfig;
        Sentry.captureException(error);
      }
    }),
    resetPackDetails(): void {
      self.packDetailState = LoadingState.INITIAL;
      self.packOverview = undefined;
    },
  }));

export type GoalBasedType = Instance<typeof GoalBasedModel>;
export interface GoalBasedStore extends GoalBasedType {}
