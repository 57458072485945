// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ToplineBannerRetailModelOrderBy {
  _createdAt_ASC="_createdAt_ASC",
_createdAt_DESC="_createdAt_DESC",
_firstPublishedAt_ASC="_firstPublishedAt_ASC",
_firstPublishedAt_DESC="_firstPublishedAt_DESC",
_isValid_ASC="_isValid_ASC",
_isValid_DESC="_isValid_DESC",
_publicationScheduledAt_ASC="_publicationScheduledAt_ASC",
_publicationScheduledAt_DESC="_publicationScheduledAt_DESC",
_publishedAt_ASC="_publishedAt_ASC",
_publishedAt_DESC="_publishedAt_DESC",
_status_ASC="_status_ASC",
_status_DESC="_status_DESC",
_unpublishingScheduledAt_ASC="_unpublishingScheduledAt_ASC",
_unpublishingScheduledAt_DESC="_unpublishingScheduledAt_DESC",
_updatedAt_ASC="_updatedAt_ASC",
_updatedAt_DESC="_updatedAt_DESC",
active_ASC="active_ASC",
active_DESC="active_DESC",
createdAt_ASC="createdAt_ASC",
createdAt_DESC="createdAt_DESC",
displayTime_ASC="displayTime_ASC",
displayTime_DESC="displayTime_DESC",
endDate_ASC="endDate_ASC",
endDate_DESC="endDate_DESC",
id_ASC="id_ASC",
id_DESC="id_DESC",
key_ASC="key_ASC",
key_DESC="key_DESC",
position_ASC="position_ASC",
position_DESC="position_DESC",
startDate_ASC="startDate_ASC",
startDate_DESC="startDate_DESC",
updatedAt_ASC="updatedAt_ASC",
updatedAt_DESC="updatedAt_DESC",
visibilityRule_ASC="visibilityRule_ASC",
visibilityRule_DESC="visibilityRule_DESC"
}

/**
* ToplineBannerRetailModelOrderBy
*/
export const ToplineBannerRetailModelOrderByEnumType = types.enumeration("ToplineBannerRetailModelOrderBy", [
        "_createdAt_ASC",
  "_createdAt_DESC",
  "_firstPublishedAt_ASC",
  "_firstPublishedAt_DESC",
  "_isValid_ASC",
  "_isValid_DESC",
  "_publicationScheduledAt_ASC",
  "_publicationScheduledAt_DESC",
  "_publishedAt_ASC",
  "_publishedAt_DESC",
  "_status_ASC",
  "_status_DESC",
  "_unpublishingScheduledAt_ASC",
  "_unpublishingScheduledAt_DESC",
  "_updatedAt_ASC",
  "_updatedAt_DESC",
  "active_ASC",
  "active_DESC",
  "createdAt_ASC",
  "createdAt_DESC",
  "displayTime_ASC",
  "displayTime_DESC",
  "endDate_ASC",
  "endDate_DESC",
  "id_ASC",
  "id_DESC",
  "key_ASC",
  "key_DESC",
  "position_ASC",
  "position_DESC",
  "startDate_ASC",
  "startDate_DESC",
  "updatedAt_ASC",
  "updatedAt_DESC",
  "visibilityRule_ASC",
  "visibilityRule_DESC",
      ])
