// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ImgixParamsFlip {
  h="h",
hv="hv",
v="v"
}

/**
* ImgixParamsFlip
*/
export const ImgixParamsFlipEnumType = types.enumeration("ImgixParamsFlip", [
        "h",
  "hv",
  "v",
      ])
