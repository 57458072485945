import { gql } from 'graphql-request';

export const CART_SELECTOR = gql`
 _id
message {
  message
  status
  title
  type
}
checkoutStartAt
customer {
  email
  firstName
  lastName
  phone
}
giftCertificates {
  lineitemText
  netPrice
  recipientEmail
  recipientName
  senderName
}
plans {
  attributes {
    name
    value
  }
  category
  image
  lineItems {
    promotionTotal
    qty
    sku
    attributes {
      name
      value
    }
  }
  name
  netPrice
  planId
  subCategory
}
source
totals {
  itemTotal
  orderTotal
  shippingTotal
}
updatedAt
upsells
`;
