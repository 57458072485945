import type { LocalizationResource } from '@clerk/types';

export const CLERK_LOCALIZATION: LocalizationResource = {
  signIn: {
    start: {
      title: 'Sign in to your account',
      subtitle:
        'Sign in to order, manage your account and add to your liked products.',
    },
  },
  signUp: {
    start: {
      title: 'New to My Muscle Chef?',
      subtitle:
        'Create an account to order, favourite items you like and unlock exclusive offers.',
    },
  },
  footerPageLink__help: 'Trouble signing in? Read our FAQs.',
  userButton: {
    action__manageAccount: 'My Dashboard',
  },
};
