// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { MarketingContentApprovalWorkflowStageEnumType } from "./MarketingContentApprovalWorkflowStageEnum"
import { PricingGroupRecordModel, PricingGroupRecordModelType } from "./PricingGroupRecordModel"
import { PricingGroupRecordModelSelector } from "./PricingGroupRecordModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { ToplineBannerRetailModelTextFieldModel, ToplineBannerRetailModelTextFieldModelType } from "./ToplineBannerRetailModelTextFieldModel"
import { ToplineBannerRetailModelTextFieldModelSelector } from "./ToplineBannerRetailModelTextFieldModel.base"
import { RootStoreType } from "./index"


/**
 * ToplineBannerRetailRecordBase
 * auto generated base class for the model ToplineBannerRetailRecordModel.
 *
 * Record of type Topline Banner Retail (topline_banner_retail)
 */
export const ToplineBannerRetailRecordModelBase = ModelBase
  .named('ToplineBannerRetailRecord')
  .props({
    __typename: types.optional(types.literal("ToplineBannerRetailRecord"), "ToplineBannerRetailRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _stage: types.union(types.undefined, MarketingContentApprovalWorkflowStageEnumType),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    active: types.union(types.undefined, types.null, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    displayTime: types.union(types.undefined, types.null, types.frozen()),
    endDate: types.union(types.undefined, types.null, types.frozen()),
    id: types.union(types.undefined, types.frozen()),
    key: types.union(types.undefined, types.null, types.string),
    position: types.union(types.undefined, types.null, types.frozen()),
    pricingGroups: types.union(types.undefined, types.array(types.late((): any => PricingGroupRecordModel))),
    startDate: types.union(types.undefined, types.null, types.frozen()),
    text: types.union(types.undefined, types.null, types.late((): any => ToplineBannerRetailModelTextFieldModel)),
    updatedAt: types.union(types.undefined, types.frozen()),
    visibilityRule: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ToplineBannerRetailRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _stage() { return this.__attr(`_stage`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get active() { return this.__attr(`active`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get displayTime() { return this.__attr(`displayTime`) }
  get endDate() { return this.__attr(`endDate`) }
  get id() { return this.__attr(`id`) }
  get key() { return this.__attr(`key`) }
  get position() { return this.__attr(`position`) }
  get startDate() { return this.__attr(`startDate`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get visibilityRule() { return this.__attr(`visibilityRule`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  pricingGroups(builder: string | PricingGroupRecordModelSelector | ((selector: PricingGroupRecordModelSelector) => PricingGroupRecordModelSelector) | undefined) { return this.__child(`pricingGroups`, PricingGroupRecordModelSelector, builder) }
  text(builder: string | ToplineBannerRetailModelTextFieldModelSelector | ((selector: ToplineBannerRetailModelTextFieldModelSelector) => ToplineBannerRetailModelTextFieldModelSelector) | undefined) { return this.__child(`text`, ToplineBannerRetailModelTextFieldModelSelector, builder) }
}
export function selectFromToplineBannerRetailRecord() {
  return new ToplineBannerRetailRecordModelSelector()
}

export const toplineBannerRetailRecordModelPrimitives = selectFromToplineBannerRetailRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._stage._status._unpublishingScheduledAt._updatedAt.active.createdAt.displayTime.endDate.key.position.startDate.updatedAt.visibilityRule
