export const CMS_SEO_SELECTOR = `
seoTitle
seoDescription
ogImage {
  url
}
seoBlurb {
  links
  value
  blocks
}
`;

export const LANDING_PAGE_SEO_SELECTOR = `
${CMS_SEO_SELECTOR}
faq {
  title
  description
}
`;

export const LANDING_PAGE_SLUGS_SELECTOR = `
slug
`;

export const TOPLINE_BANNER_SELECTOR = `
id
displayTime
active
text {
  value
  blocks
  links
}
`;

export const CUSTOMER_EMAIL_CHANGE_SELECTOR = `
... on CustomerEmailChange {
  newEmail
  oldEmail
}
... on MessageRto {
  message
  status
  title
  type
}
`;

export const GOAL_BASED_QUIZ_SELECTOR = `
heading
sections {
  ... on QuizInputSectionRecord {
    inputDefaultValue
    inputName
    key
    questionText
  }
  ... on QuizButtonSectionRecord {
    key
    questionText
    quizAnswers {
      value
      subText
      name
      id
    }
  }
  ... on QuizPlanSectionRecord {
    key
    plans {
      quizAnswers {
        value
        subText
        name
        id
        calorie
        bucket
      }
      questionText
      key
      id
      goal
    }
  }
}
`;

export const COUNTDOWN_TIMER_SELECTOR = `
sections {
  ... on CountdownSectionRecord {
    __typename
    backgroundImage {
      url
    }
    buttonLink
    buttonText
    buttonTheme
    countdownEndDate
    countdownText
    active
    endedText
  }
}
`;

export const MENU_HIERARCHY_SELECTOR = `
name
slug
category
parentCategory {
  name
}
childrenCategories {
  name
}
`;

export const MENU_CATEGORY_SELECTOR = `
${CMS_SEO_SELECTOR}
id
name
active
slug
category
description
showOnNavbar
showBulkDiscountBar
productWhereInput
defaultSortOption {
  name
  value
}
image {
  alt
  url
  title
}
description
childrenCategories {
  id
  name
  active
  slug
  extraLabel
  image {
    alt
    url
    title
  }
  childrenCategories {
    id
    name
    active
    slug
    extraLabel
    image {
      alt
      url
      title
    }
  }
}
`;

export const MENU_CATEGORY_LINK_SELECTOR = `
id
name
slug
category
`;
