import Link from 'next/link';
import { useState } from 'react';

import type { ProfileStageRecordModelType } from '@/models/api';

import type { ProfileStageAttribute } from './helper';
import { extractAttributes, validateAttributes } from './helper';
import ProfileStageFooter from './ProfileStageFooter';
import ProfileStageSection from './ProfileStageSection';
import ProfileStageStructuredText from './ProfileStageStructuredText';

export type ProfileStageFormProps = {
  onSubmit: (data: ProfileStageAttribute[]) => Promise<void>;
  onSkip?: () => void;
  data: ProfileStageRecordModelType;
  loading?: boolean;
};

const ProfileStageForm: React.FC<ProfileStageFormProps> = ({
  onSubmit,
  onSkip,
  data,
  loading: loadingOverride,
}) => {
  const sections = data.sections || [];
  const [attributes, setAttributes] = useState<ProfileStageAttribute[]>(
    extractAttributes(data),
  );
  const [loading, setLoading] = useState(false);
  const handleChange = (name: string, value: any) => {
    const newAttributes = attributes.filter((attr) => attr.name !== name);
    setAttributes([...newAttributes, { name, value }]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(attributes);
    setLoading(false);
  };

  const isDisabled = !validateAttributes(data, attributes);

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-1 flex-col gap-6 px-6 py-5">
        <div className="font-interSemibold text-xl">
          {data.title || "We'd Love to Hear From you!"}
        </div>
        <div className="flex flex-1 grow flex-col gap-6">
          {sections.map((section) => (
            <ProfileStageSection
              key={section.id}
              section={section}
              onChange={handleChange}
            />
          ))}
        </div>
        {data.footer ? (
          <ProfileStageStructuredText content={data.footer} />
        ) : (
          <div className="font-interMedium text-xxs">
            <span className="font-interBold">
              Why are we asking for this information?{' '}
            </span>
            <span>
              {`MYMC uses this information to improve our products and services and
            provide a better, more tailored experience for our customers. We're
            committed to keeping your information secure. For more information,
            you can find our Privacy Policy `}
            </span>
            <Link
              href={process.env.NEXT_PUBLIC_PROFILE_STAGE_URL || ''}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="text-attention underline">here.</span>
            </Link>
          </div>
        )}
      </div>
      <ProfileStageFooter
        title={'Submit'}
        disabled={isDisabled}
        loading={loading || loadingOverride}
        onClick={handleSubmit}
        secondaryTitle="Skip for now"
        onSecondaryClick={onSkip}
      />
    </div>
  );
};

export default ProfileStageForm;
