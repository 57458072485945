// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DashboardImagesModel, DashboardImagesModelType } from "./DashboardImagesModel"
import { DashboardImagesModelSelector } from "./DashboardImagesModel.base"
import { DashboardTargetActionModel, DashboardTargetActionModelType } from "./DashboardTargetActionModel"
import { DashboardTargetActionModelSelector } from "./DashboardTargetActionModel.base"
import { RootStoreType } from "./index"


/**
 * PlanMetaBase
 * auto generated base class for the model PlanMetaModel.
 *
 * Plan Meta
 */
export const PlanMetaModelBase = ModelBase
  .named('PlanMeta')
  .props({
    __typename: types.optional(types.literal("PlanMeta"), "PlanMeta"),
    checkable: types.union(types.undefined, types.boolean),
    componentData: types.union(types.undefined, types.null, types.frozen()),
    componentName: types.union(types.undefined, types.string),
    dismissable: types.union(types.undefined, types.boolean),
    images: types.union(types.undefined, types.late((): any => DashboardImagesModel)),
    key: types.union(types.undefined, types.string),
    targetAction: types.union(types.undefined, types.late((): any => DashboardTargetActionModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PlanMetaModelSelector extends QueryBuilder {
  get checkable() { return this.__attr(`checkable`) }
  get componentData() { return this.__attr(`componentData`) }
  get componentName() { return this.__attr(`componentName`) }
  get dismissable() { return this.__attr(`dismissable`) }
  get key() { return this.__attr(`key`) }
  images(builder: string | DashboardImagesModelSelector | ((selector: DashboardImagesModelSelector) => DashboardImagesModelSelector) | undefined) { return this.__child(`images`, DashboardImagesModelSelector, builder) }
  targetAction(builder: string | DashboardTargetActionModelSelector | ((selector: DashboardTargetActionModelSelector) => DashboardTargetActionModelSelector) | undefined) { return this.__child(`targetAction`, DashboardTargetActionModelSelector, builder) }
}
export function selectFromPlanMeta() {
  return new PlanMetaModelSelector()
}

export const planMetaModelPrimitives = selectFromPlanMeta().checkable.componentData.componentName.dismissable.key
