import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { CAMPAIGN_INFO } from '@/shared/constant';
import { load, save } from '@/utils/local-storage';

export type CampaignInfoType = {
  source: string; // Most recent value of the utm_source url parameter
  medium: string; // Most recent value of the utm_medium url parameter
  name: string; // Most recent value of the utm_campaign url parameter
  gclid?: string; // Most recent value of the gclid url parameter
  wbraid?: string; // Most recent value of the wbraid url parameter (mobile only; web-to-app)
  gbraid?: string; // Most recent value of the gbraid url parameter (mobile only; app-to-app)
  msclkid?: string; // Most recent value of the msclkid url parameter
  fbp?: string; // Retrieve from _fbp cookie (if the facebook client side script is being used)
  fbc?: string; // Retrieve from _fbc cookie (if the facebook client side script is being used)
  ttclid?: string; // Retrieve from ttclid cookie (if the facebook client side script is being used)
  ttp?: string; // Retrieve from _ttp cookie (if the tiktok client side script is being used)
};
// TODO: fbclid from query url parameter

export const useStoreCampaignInfo = () => {
  const router = useRouter();

  const extractCampaignInfoFromCookie = (key: string) =>
    document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${key}=`))
      ?.split('=')[1];

  useEffect(() => {
    const { query } = router;

    // Extract campaign data from URL parameters
    const campaignInfo: CampaignInfoType = {
      source: query.utm_source as string,
      medium: query.utm_medium as string,
      name: query.utm_campaign as string,
      gclid: query.gclid as string,
      wbraid: query.wbraid as string,
      gbraid: query.gbraid as string,
      msclkid: query.msclkid as string,
    };

    if (!campaignInfo.source) {
      return;
    }

    // Extract campaign data from cookies
    if (typeof window !== 'undefined') {
      campaignInfo.fbp = extractCampaignInfoFromCookie('_fbp');
      campaignInfo.fbc = extractCampaignInfoFromCookie('_fbc');
      campaignInfo.ttclid = extractCampaignInfoFromCookie('ttclid');
      campaignInfo.ttp = extractCampaignInfoFromCookie('_ttp');
    }

    // Store campaign information in localStorage
    if (Object.values(campaignInfo).some((value) => !!value)) {
      const existingCampaignInfo = load(CAMPAIGN_INFO) || {};
      const cleanCampaignInfo = _.omitBy(campaignInfo, _.isNil);
      const newCampaignInfo = _.assign(existingCampaignInfo, cleanCampaignInfo);
      save(CAMPAIGN_INFO, newCampaignInfo);
    }
  }, [router.query]);
};
