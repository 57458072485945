// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { createStoreContext, createUseQueryHook } from "mst-gql"
import * as React from "react"
import { RootStoreType } from "./root-store"

export const StoreContext = createStoreContext<RootStoreType>(React)
StoreContext.displayName = "MstStoreContext"

export const useQuery = createUseQueryHook(StoreContext, React)
