import Button from '@/components/common/Button/Button';

export type ProfileStageFooterProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  secondaryTitle?: string;
  onSecondaryClick?: () => void;
};

const ProfileStageFooter: React.FC<ProfileStageFooterProps> = ({
  title,
  onClick,
  disabled = false,
  loading = false,
  secondaryTitle,
  onSecondaryClick,
}: ProfileStageFooterProps) => {
  return (
    <div className="sticky bottom-0 left-0 bg-white">
      <hr />
      <div className="px-6 py-4">
        <Button
          className="w-full"
          onClick={onClick}
          disabled={disabled}
          loading={loading}
          theme="primary"
        >
          <span className="font-inter">{title}</span>
        </Button>
        {secondaryTitle && onSecondaryClick && (
          <Button
            className="mt-2 w-full"
            onClick={onSecondaryClick}
            theme="quaternary"
          >
            <span className="font-inter">{secondaryTitle}</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProfileStageFooter;
