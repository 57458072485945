import type { ProductStatus } from '@/models/api';
import { Frequency } from '@/models/api';

// discount
export const DISCOUNTS = {
  ZERO: { value: 0, valueString: '0', threshold: 0, start: -1 },
  FIRST: { value: 2.5, valueString: '2.5', threshold: 13, start: 0 },
  SECOND: { value: 5, valueString: '5', threshold: 16, start: 13 },
  THIRD: { value: 10, valueString: '10', threshold: 21, start: 16 },
  FOURTH: { value: 15, valueString: '15', threshold: 25, start: 21 },
};

export const SLICED_NUMBER = 20;

export const NAV_HEIGHT = 86;

export const DEFAULT_REVALIDATE_SECONDS = 60;

export const DATE_FORMAT = 'MMMM DD,YYYY';
export const DELIVERY_DATE_FORMAT = 'ddd, D MMM YYYY';

export const API_QUERY_LIMIT = 10;

export const SUBSCRIPTION_FREQUENCIES = [
  { id: 1, label: 'Week', popular: true, value: Frequency.WEEKLY },
  { id: 2, label: '2 Weeks', value: Frequency.FORTNIGHTLY },
  { id: 3, label: '4 Weeks', value: Frequency.MONTHLY },
];

export const amounts = ['50', '75', '100', '150', '200', 'Other'];

export type AddressMethodTypes = {
  [key: string]: string;
};
export const AddressMethod: AddressMethodTypes = {
  home: 'Residential',
  work: 'Business',
};

export enum CreditCardType {
  VISA = 'Visa',
  MASTER = 'Master',
  AMEX = 'Amex',
  MASTERCARD = 'MasterCard',
  AMERICANEXPRESS = 'American Express',
}

export const RECAPTCHA_EMAIL_HIDE_ADDRESS = '@mymusclechef.com.au';
// localStorage keys
export const CAMPAIGN_INFO = 'campaignInfo';
export const PROFILE_STAGE_SKIP_UNTIL = 'profileStageSkipUntil';
export const AUTH_MODAL_OPEN = 'authModalOpen';
export const AUTH_MODAL_TAB = 'authModalTab';
export const AUTH_NAVIGATION_TARGET = 'authNavigationTarget';
export const AUTH_NAVIGATION_TARGET_KEEP_PATHNAMES = [
  '/menu',
  '/products',
  '/goalbased',
];

export type ProductStatusFilter = {
  _eq?: ProductStatus;
  _in?: ProductStatus[];
  _ne?: ProductStatus;
  _nin?: ProductStatus[];
};
