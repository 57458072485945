import Script from 'next/script';

const MSChatBot: React.FC = () => {
  return (
    <Script
      id="ze-snippet"
      strategy="afterInteractive"
      src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`}
      onLoad={() => {
        window.zE('messenger:set', 'zIndex', 50);
      }}
    ></Script>
  );
};

export default MSChatBot;
