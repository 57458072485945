import { Instance, types } from 'mobx-state-tree';
import { WebPromoBannerRecordModel } from '../api';
import { withRootStore } from '../helpers/with-root-store';
import _ from 'lodash';

export const BannerModel = types
  .model('Banner')
  .props({
    banner: types.union(types.undefined, types.null, WebPromoBannerRecordModel),
  })
  .extend(withRootStore)
  .views((self) => ({
    get code() {
      return self.banner?.code;
    },
    get isPromoCodeEligible() {
      if (!self.banner) return false;
      if (self.banner.isnewcustomer) {
        const { customerStore } = self.rootStore;
        if (!customerStore.isLoggedIn) return true;
        return (
          customerStore.customer?.createdAt &&
          customerStore.customer?.isNewCustomer
        );
      }
      return true;
    },
    getBannerByKey(key: string) {
      if (!this.isPromoCodeEligible) {
        return;
      }
      return _.find(self.banner?.banners, { key });
    },
  }));

export interface Banner extends Instance<typeof BannerModel> {}
