import _ from 'lodash';
import { PaymentType } from '@/models/api';
import { types, destroy } from 'mobx-state-tree';

export const CartPaymentModel = types.model('Payment').props({
  amount: types.number,
  paymentMethodId: types.enumeration(Object.values(PaymentType)),
  paymentMethodToken: types.maybe(types.string),
  paymentMethodNonce: types.maybe(types.string),
  paymentDeviceData: types.union(types.undefined, types.null, types.string),
  account: types.maybe(types.string),
  cardType: types.maybe(types.string),
});

export const GiftPaymentModel = types.model('GiftPayment').props({
  amount: types.number,
  code: types.string,
});

export const CreditPaymentModel = types.model('CreditPayment').props({
  amount: types.number,
});

export const PaymentsModel = types
  .model('Payments')
  .props({
    payment: types.maybe(CartPaymentModel),
    giftCards: types.optional(types.array(GiftPaymentModel), []),
    credit: types.maybe(CreditPaymentModel),
  })
  .actions((self) => ({
    setPayment(payment: any) {
      self.payment = {
        paymentMethodId: payment?.paymentMethodId,
        paymentMethodToken: payment?.paymentMethodToken,
        paymentMethodNonce: '',
        paymentDeviceData: payment?.paymentDeviceData,
        account: '',
        cardType: '',
        amount: 0,
      };
    },
    setCredit(amount: number) {
      self.credit = CreditPaymentModel.create({ amount });
    },
    setNonce(type: string, nonce: string, deviceData?: string) {
      // mapping Braintree payment types
      let paymentMethodId = PaymentType.CREDIT_CARD;
      switch (type) {
        case 'AndroidPayCard':
          paymentMethodId = PaymentType.GOOGLEPAY;
          break;
        case 'ApplePayCard':
          paymentMethodId = PaymentType.APPLEPAY;
          break;
        case 'PayPalAccount':
          paymentMethodId = PaymentType.PAYPAL;
          break;
        default:
          break;
      }
      self.payment = {
        paymentMethodId,
        paymentMethodToken: '',
        paymentMethodNonce: nonce,
        paymentDeviceData: deviceData,
        account: '',
        cardType: '',
        amount: 0,
      };
    },
    addGiftCard(code: string, amount: number) {
      const card = _.find(self.giftCards, (card) => card.code === code);
      if (!card) {
        self.giftCards.push({ code, amount });
      }
    },
    removeGiftCard(code: string) {
      const card = _.find(self.giftCards, (card) => card.code === code);
      if (card) {
        destroy(card);
      }
    },
    isEmpty() {
      return self.payment === undefined;
    },
    clearPayment() {
      self.payment = undefined;
    },
  }));
