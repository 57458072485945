// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * GiftCardBase
 * auto generated base class for the model GiftCardModel.
 */
export const GiftCardModelBase = ModelBase
  .named('GiftCard')
  .props({
    __typename: types.optional(types.literal("GiftCard"), "GiftCard"),
    amount: types.union(types.undefined, types.number),
    balance: types.union(types.undefined, types.number),
    enabled: types.union(types.undefined, types.boolean),
    maskedGiftCertificateCode: types.union(types.undefined, types.string),
    message: types.union(types.undefined, types.null, types.string),
    recipientEmail: types.union(types.undefined, types.string),
    recipientName: types.union(types.undefined, types.string),
    senderName: types.union(types.undefined, types.string),
    status: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GiftCardModelSelector extends QueryBuilder {
  get amount() { return this.__attr(`amount`) }
  get balance() { return this.__attr(`balance`) }
  get enabled() { return this.__attr(`enabled`) }
  get maskedGiftCertificateCode() { return this.__attr(`maskedGiftCertificateCode`) }
  get message() { return this.__attr(`message`) }
  get recipientEmail() { return this.__attr(`recipientEmail`) }
  get recipientName() { return this.__attr(`recipientName`) }
  get senderName() { return this.__attr(`senderName`) }
  get status() { return this.__attr(`status`) }
}
export function selectFromGiftCard() {
  return new GiftCardModelSelector()
}

export const giftCardModelPrimitives = selectFromGiftCard().amount.balance.enabled.maskedGiftCertificateCode.message.recipientEmail.recipientName.senderName.status
