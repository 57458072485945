// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * ToplineBannerV2ModelTextFieldBase
 * auto generated base class for the model ToplineBannerV2ModelTextFieldModel.
 */
export const ToplineBannerV2ModelTextFieldModelBase = ModelBase
  .named('ToplineBannerV2ModelTextField')
  .props({
    __typename: types.optional(types.literal("ToplineBannerV2ModelTextField"), "ToplineBannerV2ModelTextField"),
    blocks: types.union(types.undefined, types.array(types.string)),
    links: types.union(types.undefined, types.array(types.string)),
    value: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ToplineBannerV2ModelTextFieldModelSelector extends QueryBuilder {
  get blocks() { return this.__attr(`blocks`) }
  get links() { return this.__attr(`links`) }
  get value() { return this.__attr(`value`) }
}
export function selectFromToplineBannerV2ModelTextField() {
  return new ToplineBannerV2ModelTextFieldModelSelector()
}

export const toplineBannerV2ModelTextFieldModelPrimitives = selectFromToplineBannerV2ModelTextField().blocks.links.value
