type ProfileStageSectionHeaderProps = {
  heading: string | undefined | null;
  subHeading?: string | undefined | null;
};

const ProfileStageSectionHeader: React.FC<ProfileStageSectionHeaderProps> = ({
  heading,
  subHeading,
}) => {
  return (
    <div>
      <div className="font-interSemibold text-sm text-black">{heading}</div>
      {subHeading && (
        <div className="mt-1 text-xs text-black">{subHeading}</div>
      )}
    </div>
  );
};

export default ProfileStageSectionHeader;
