import { GrowthBook } from '@growthbook/growthbook-react';
import type { NextRequest } from 'next/server';
import { NextResponse } from 'next/server';
import { v4 as uuidv4 } from 'uuid';

export const DEFAULT_GROWTHBOOK_REDIRECT = {
  destination: '',
};

export type GrowthBookRedirects = {
  [k: string]:
    | {
        featureFlag: string;
      }
    | undefined;
};

export const GROWTHBOOK_REDIRECT_FEATURE_FLAGS: GrowthBookRedirects = {
  '/': {
    featureFlag: 'test_1_aa_redirect_home_page',
  },
  '/2': {
    featureFlag: 'test_1_aa_redirect_home_page',
  },
};

export const ONE_YEAR_IN_SECONDS = 60 * 60 * 24 * 365;

function addGBUniqueIdCookie(
  gbUniqueClientId: string,
  request: NextRequest,
  response: NextResponse
) {
  if (!request.cookies.has('gbUniqueClientId')) {
    response.cookies.set('gbUniqueClientId', gbUniqueClientId, {
      path: '/',
      maxAge: ONE_YEAR_IN_SECONDS,
    });
    return response;
  }
  return response;
}

export default async function growthbookRedirects(request: NextRequest) {
  let gbUniqueClientId = request.cookies.get('gbUniqueClientId') as any;
  if (!gbUniqueClientId) {
    gbUniqueClientId = `${uuidv4()}-${Date.now()}` as any;
  }
  // Check if growthbook redirect exists for current url
  const redirect = GROWTHBOOK_REDIRECT_FEATURE_FLAGS[request.nextUrl.pathname];
  if (!redirect) {
    return addGBUniqueIdCookie(gbUniqueClientId, request, NextResponse.next());
  }

  // Create a GrowthBook instance
  const growthbook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_KEY,
    // Enable easier debugging during development
    enableDevMode: process.env.NEXT_PUBLIC_ENV !== 'production',
    attributes: {
      uniqueClientId: gbUniqueClientId,
    },
  });

  // Wait for features to be downloaded
  await growthbook.loadFeatures({
    timeout: 2000,
  });

  const flagValue = growthbook.getFeatureValue(
    redirect.featureFlag,
    DEFAULT_GROWTHBOOK_REDIRECT
  );
  if (
    !flagValue?.destination ||
    flagValue.destination === request.nextUrl.pathname
  ) {
    return addGBUniqueIdCookie(gbUniqueClientId, request, NextResponse.next());
  }
  const url = request.nextUrl.clone();
  url.pathname = flagValue.destination;

  return addGBUniqueIdCookie(
    gbUniqueClientId,
    request,
    NextResponse.redirect(url)
  );
}
