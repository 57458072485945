// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum MealPlanSubCategory {
  CALORIE_CONTROL="CALORIE_CONTROL",
MUSCLE_GAIN="MUSCLE_GAIN",
WLP_FIXED_FEMALE="WLP_FIXED_FEMALE",
WLP_FIXED_MALE="WLP_FIXED_MALE",
WLP_FULL="WLP_FULL",
WLP_LITE="WLP_LITE",
WL_1200="WL_1200",
WL_1500="WL_1500",
WL_1800="WL_1800",
WL_PERSONALISED="WL_PERSONALISED"
}

/**
* MealPlanSubCategory
*/
export const MealPlanSubCategoryEnumType = types.enumeration("MealPlanSubCategory", [
        "CALORIE_CONTROL",
  "MUSCLE_GAIN",
  "WLP_FIXED_FEMALE",
  "WLP_FIXED_MALE",
  "WLP_FULL",
  "WLP_LITE",
  "WL_1200",
  "WL_1500",
  "WL_1800",
  "WL_PERSONALISED",
      ])
