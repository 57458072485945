// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * SubscriptionTotalsBase
 * auto generated base class for the model SubscriptionTotalsModel.
 *
 * Subscription Totals
 */
export const SubscriptionTotalsModelBase = ModelBase
  .named('SubscriptionTotals')
  .props({
    __typename: types.optional(types.literal("SubscriptionTotals"), "SubscriptionTotals"),
    orderPromotionTotal: types.union(types.undefined, types.null, types.number),
    shippingPromotionTotal: types.union(types.undefined, types.null, types.number),
    shippingTotal: types.union(types.undefined, types.null, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class SubscriptionTotalsModelSelector extends QueryBuilder {
  get orderPromotionTotal() { return this.__attr(`orderPromotionTotal`) }
  get shippingPromotionTotal() { return this.__attr(`shippingPromotionTotal`) }
  get shippingTotal() { return this.__attr(`shippingTotal`) }
}
export function selectFromSubscriptionTotals() {
  return new SubscriptionTotalsModelSelector()
}

export const subscriptionTotalsModelPrimitives = selectFromSubscriptionTotals().orderPromotionTotal.shippingPromotionTotal.shippingTotal
