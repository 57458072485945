// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum SortableProductCollectionFields {
  name="name"
}

/**
* SortableProductCollectionFields
*/
export const SortableProductCollectionFieldsEnumType = types.enumeration("SortableProductCollectionFields", [
        "name",
      ])
