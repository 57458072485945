import _ from 'lodash';
import { useState } from 'react';
import ReactSelect from 'react-select';

import Button from '@/components/common/Button/Button';
import type { ProfileStageQuestionRecordModelType } from '@/models/api';

import { YEAR_OPTIONS } from './helper';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    fontSize: '14px', // Change font size for the control (input area)
    minWidth: '100px',
  }),
  option: (provided: any) => ({
    ...provided,
    fontSize: '14px', // Change font size for each option
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '14px', // Change font size for the selected value
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '14px', // Change font size for the dropdown menu
  }),
};

export const transformQuestion = (
  data: ProfileStageQuestionRecordModelType,
) => {
  if (!_.isEmpty(data.options)) {
    return data;
  }
  // Transform birthYear option
  if (data.name === 'birthYear') {
    return {
      ...data,
      options: YEAR_OPTIONS,
    };
  }
  return data;
};

type ProfileStageQuestionProps = {
  data: ProfileStageQuestionRecordModelType;
  onChange: (name: string, value: any) => void;
  className?: string;
};

const ProfileStageInputQuestion: React.FC<ProfileStageQuestionProps> = (
  props,
) => {
  const { data, onChange, className = '' } = props;
  const { placeholder, name } = data;

  const handleChange = (value: any) => {
    if (onChange && name) {
      onChange(name, value);
    }
  };

  return (
    <div className={className}>
      <div className="mt-2 flex gap-4">
        <input
          className="min-w-20 justify-center rounded-md border border-black px-2.5 py-2 text-center"
          placeholder={placeholder || ''}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  );
};

const ProfileStageRadioQuestion: React.FC<ProfileStageQuestionProps> = (
  props,
) => {
  const { data, onChange, className = '' } = props;
  const { options, name } = data;

  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const handleChange = (value: any) => {
    setSelectedValue(value);
    if (onChange && name) {
      onChange(name, value);
    }
  };

  return (
    <div className={className}>
      <div className="mt-2 flex flex-wrap gap-4">
        {options.map((option: any) => (
          <Button
            key={option.label}
            className="max-h-12 min-w-[100px] justify-center rounded-md px-2.5 py-2"
            onClick={() => handleChange(option.value)}
            theme={selectedValue === option.value ? 'septenary' : 'tertiary'}
          >
            {option.label}
          </Button>
        ))}
      </div>
    </div>
  );
};

const ProfileStageDropdownQuestion: React.FC<ProfileStageQuestionProps> = (
  props,
) => {
  const { className = '', data, onChange } = props;
  const { name, isMulti, placeholder, options } = data;

  const handleChange = (result: any) => {
    const value = _.isArray(result)
      ? result.map((v: any) => v.value)
      : result.value;
    if (onChange && name) {
      onChange(name, value);
    }
  };

  return (
    <div className={className}>
      <ReactSelect
        key={name}
        options={options}
        isSearchable={false}
        isMulti={isMulti}
        placeholder={placeholder}
        onChange={(e) => {
          handleChange(e);
        }}
        styles={customStyles} // Apply the custom styles
      />
    </div>
  );
};

const ProfileStageQuestion: React.FC<ProfileStageQuestionProps> = (props) => {
  const { onChange } = props;
  const data = transformQuestion(props.data);

  switch (data.method) {
    case 'input':
      return <ProfileStageInputQuestion data={data} onChange={onChange} />;
    case 'radio':
      return <ProfileStageRadioQuestion data={data} onChange={onChange} />;
    case 'dropdown':
      return <ProfileStageDropdownQuestion data={data} onChange={onChange} />;
    default:
      return null;
  }
};

export default ProfileStageQuestion;
