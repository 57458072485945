// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FrequencyEnumType } from "./FrequencyEnum"
import { RootStoreType } from "./index"


/**
 * SubscriptionInfoBase
 * auto generated base class for the model SubscriptionInfoModel.
 */
export const SubscriptionInfoModelBase = ModelBase
  .named('SubscriptionInfo')
  .props({
    __typename: types.optional(types.literal("SubscriptionInfo"), "SubscriptionInfo"),
    frequency: types.union(types.undefined, types.null, FrequencyEnumType),
    status: types.union(types.undefined, types.string),
    value: types.union(types.undefined, types.null, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class SubscriptionInfoModelSelector extends QueryBuilder {
  get frequency() { return this.__attr(`frequency`) }
  get status() { return this.__attr(`status`) }
  get value() { return this.__attr(`value`) }
}
export function selectFromSubscriptionInfo() {
  return new SubscriptionInfoModelSelector()
}

export const subscriptionInfoModelPrimitives = selectFromSubscriptionInfo().frequency.status.value
