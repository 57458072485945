// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DiscountTypeEnumType } from "./DiscountTypeEnum"
import { RootStoreType } from "./index"


/**
 * BasketDiscountBase
 * auto generated base class for the model BasketDiscountModel.
 */
export const BasketDiscountModelBase = ModelBase
  .named('BasketDiscount')
  .props({
    __typename: types.optional(types.literal("BasketDiscount"), "BasketDiscount"),
    calloutMsg: types.union(types.undefined, types.null, types.string),
    code: types.union(types.undefined, types.null, types.string),
    desc: types.union(types.undefined, types.string),
    id: types.union(types.undefined, types.string),
    type: types.union(types.undefined, DiscountTypeEnumType),
    value: types.union(types.undefined, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class BasketDiscountModelSelector extends QueryBuilder {
  get calloutMsg() { return this.__attr(`calloutMsg`) }
  get code() { return this.__attr(`code`) }
  get desc() { return this.__attr(`desc`) }
  get id() { return this.__attr(`id`) }
  get type() { return this.__attr(`type`) }
  get value() { return this.__attr(`value`) }
}
export function selectFromBasketDiscount() {
  return new BasketDiscountModelSelector()
}

export const basketDiscountModelPrimitives = selectFromBasketDiscount().calloutMsg.code.desc.type.value
