// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum TaxCategory {
  EXEMPT="EXEMPT",
STANDARD="STANDARD"
}

/**
* TaxCategory
*/
export const TaxCategoryEnumType = types.enumeration("TaxCategory", [
        "EXEMPT",
  "STANDARD",
      ])
