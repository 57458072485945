// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MessageRtoModel, MessageRtoModelType } from "./MessageRtoModel"
import { MessageRtoModelSelector } from "./MessageRtoModel.base"
import { OrderPlanModel, OrderPlanModelType } from "./OrderPlanModel"
import { OrderPlanModelSelector } from "./OrderPlanModel.base"
import { RootStoreType } from "./index"


/**
 * ReorderRtoBase
 * auto generated base class for the model ReorderRtoModel.
 *
 * Reorder response
 */
export const ReorderRtoModelBase = ModelBase
  .named('ReorderRto')
  .props({
    __typename: types.optional(types.literal("ReorderRTO"), "ReorderRTO"),
    message: types.union(types.undefined, types.null, types.late((): any => MessageRtoModel)),
    orderPlans: types.union(types.undefined, types.array(types.late((): any => OrderPlanModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ReorderRtoModelSelector extends QueryBuilder {
  message(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined) { return this.__child(`message`, MessageRtoModelSelector, builder) }
  orderPlans(builder: string | OrderPlanModelSelector | ((selector: OrderPlanModelSelector) => OrderPlanModelSelector) | undefined) { return this.__child(`orderPlans`, OrderPlanModelSelector, builder) }
}
export function selectFromReorderRto() {
  return new ReorderRtoModelSelector()
}

export const reorderRtoModelPrimitives = selectFromReorderRto()
