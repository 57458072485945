import { observer } from 'mobx-react-lite';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Modal } from '@/components/common/Modal/Modal';
import { useStore } from '@/models/root-store';
import { Experiments } from '@/utils/constants';
import {
  checkVisiblePathnames,
  setProfileStageSkipUntil,
} from '@/utils/helpers';
import {
  trackFormSubmitted,
  trackFormViewed,
} from '@/utils/track/tracker.helper';

import type { ProfileStageAttribute } from './helper';
import ProfileStageForm from './ProfileStageForm';

type ProfileStageModalProps = {};

const ProfileStageModal: React.FC<ProfileStageModalProps> = observer(() => {
  const store = useStore();
  const {
    customerStore: {
      updateCustomerProfileStageAttributes,
      customer,
      isLoggedIn,
    },
  } = store;

  const showProfileStageModal = useFeatureFlagEnabled(
    Experiments.PROFILE_STAGE,
  );
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (showProfileStageModal === undefined || !isLoggedIn) {
      return;
    }
    if (!showProfileStageModal) {
      setOpen(false);
      setProfileStageSkipUntil();
      customer.updateIsProfileStageNotSkipped();
    }
  }, [showProfileStageModal, isLoggedIn]);

  useEffect(() => {
    const { profileStageCms } = customer;
    if (!profileStageCms) {
      setOpen(false);
      return;
    }
    // the business rule is to show the profile stage modal
    if (!customer.isProfileStageNotSkipped) {
      setOpen(false);
      return;
    }

    // check the pathname to show the modal
    const showModal = checkVisiblePathnames(
      window.location.pathname,
      profileStageCms.visiblePathnames,
      profileStageCms.invisiblePathnames,
    );
    setOpen(showModal);
    if (showModal) {
      trackFormViewed(profileStageCms.name || '');
    }
  }, [
    window.location.pathname,
    customer.profileStageCms?.name,
    customer.isProfileStageNotSkipped,
  ]);

  const handleSkip = () => {
    setProfileStageSkipUntil();
    customer.updateIsProfileStageNotSkipped();
    setOpen(false);
  };

  const handleSubmit = async (data: ProfileStageAttribute[]) => {
    try {
      // update the profile stage to 2, may move this to server side
      const profileStage = customer.profileStageCms?.name || '';
      const message =
        customer.profileStageCms?.message ||
        'We appreciate your input and will use this information to enhance our services and better tailor our offerings to you.';
      const payload = [
        { name: 'profileStages', value: [profileStage] },
        { name: profileStage, value: data },
      ];
      await updateCustomerProfileStageAttributes(payload);
      trackFormSubmitted(profileStage);
      toast.success(message);
      handleSkip();
    } catch (error) {
      toast.error('Failed to update profile stage');
    }
  };

  return open ? (
    <Modal
      isOpen={open}
      customContentStyles={{ maxWidth: 345 }}
      onRequestClose={() => {
        handleSkip();
      }}
      hideCloseIcon
    >
      <div className="h-[600px] max-h-full">
        {customer.profileStageCms && (
          <ProfileStageForm
            onSubmit={handleSubmit}
            onSkip={handleSkip}
            data={customer.profileStageCms}
          />
        )}
      </div>
    </Modal>
  ) : null;
});

export default ProfileStageModal;
