import _ from 'lodash';

import type { ProfileStageSectionRecordModelType } from '@/models/api';

import ProfileStageSectionHeader from './PorfileStageSectionHeader';
import ProfileStageQuestion from './ProfileStageQuestion';

type ProfileStageInputSectionProps = {
  section: ProfileStageSectionRecordModelType;
  onChange: (name: string, value: any) => void;
  className?: string;
};

const ProfileStageSection: React.FC<ProfileStageInputSectionProps> = ({
  section,
  onChange,
  className = '',
}) => {
  const { title, subtitle, questions } = section;
  const isMultiQuestions = _.size(questions) > 1;

  return (
    <div className={className}>
      <ProfileStageSectionHeader heading={title} subHeading={subtitle} />
      <div className={`mt-2 ${isMultiQuestions ? 'flex flex-row gap-4' : ''}`}>
        {questions?.map((question) => (
          <ProfileStageQuestion
            key={question.name}
            data={question}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
};

export default ProfileStageSection;
