// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * DashboardImagesBase
 * auto generated base class for the model DashboardImagesModel.
 *
 * Dashboard Images
 */
export const DashboardImagesModelBase = ModelBase
  .named('DashboardImages')
  .props({
    __typename: types.optional(types.literal("DashboardImages"), "DashboardImages"),
    dark: types.union(types.undefined, types.string),
    light: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DashboardImagesModelSelector extends QueryBuilder {
  get dark() { return this.__attr(`dark`) }
  get light() { return this.__attr(`light`) }
}
export function selectFromDashboardImages() {
  return new DashboardImagesModelSelector()
}

export const dashboardImagesModelPrimitives = selectFromDashboardImages().dark.light
