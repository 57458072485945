// @ts-nocheck
import { Action, Mode } from '@/utils/constants';
import {
  Instance,
  types,
  cast,
  clone,
  destroy,
  getSnapshot,
} from 'mobx-state-tree';
import { LineItem, OrderPlan, OrderPlanModel } from './order-plan';
import { PaymentsModel } from './payments';
import randomise from 'randomatic';
import {
  CheckoutCompletionType,
  CheckoutStep,
} from '@/utils/track/tracker.constant';
import {
  AddressModel,
  DiscountType,
  Frequency,
  GiftCertificateModel,
  OrderSource,
  PlanCategory,
  PromotionModel,
  PromotionModelType,
} from '@/models/api';
import _ from 'lodash';
import { Address } from '../customer/address';
import { DISCOUNTS } from '@/shared/constant';
import { DeliveryModel } from './delivery';
import { withRootStore } from '../helpers/with-root-store';
import dayjs from 'dayjs';
import {
  trackCartViewed,
  trackCouponRemoved,
  trackMealPlanRemovedFromCart,
  trackCheckoutStepCompleted,
} from '@/utils/track/tracker.helper';

export const CartModel = types
  .model('Cart')
  .props({
    cartId: types.string,
    plans: types.optional(types.array(OrderPlanModel), []),
    draftPlans: types.optional(types.array(OrderPlanModel), []),
    giftCertificates: types.optional(types.array(GiftCertificateModel), []),
    frequency: types.maybeNull(types.enumeration(Object.values(Frequency))),
    billingAddress: types.maybe(AddressModel),
    delivery: types.optional(DeliveryModel, {}),
    promotions: types.optional(types.array(PromotionModel), []),
    source: types.optional(
      types.enumeration(Object.values(OrderSource)),
      OrderSource.WEB,
    ),
    shippingCostBase: types.optional(types.number, 0),
    shippingTotal: types.optional(types.number, 0),
    payments: types.maybe(PaymentsModel),
    isCartOpen: types.optional(types.boolean, false),
    isDefaultCartOpen: types.optional(types.boolean, false),
    isMealPlanOpen: types.optional(types.boolean, false),
    action: types.maybe(types.enumeration(Object.values(Action))),
    mode: types.maybe(types.enumeration(Object.values(Mode))),
    planIdToView: types.maybe(types.string),
    planIdToEdit: types.maybe(types.string),
    updatedAt: types.maybeNull(types.Date),
    refreshedAt: types.maybeNull(types.Date),
    loading: types.optional(types.boolean, false),
    loadingDeliveryInfo: types.optional(types.boolean, false),
    isRefreshing: 0,
    couponCode: types.maybe(types.string),
    variant: types.maybe(types.string),
  })
  .extend(withRootStore)
  .views((self) => ({
    get menuBuilderPlan(): OrderPlan | undefined {
      return _.find(self.plans, { category: PlanCategory.MENU });
    },
    get menuBuilderEmpty(): boolean {
      const noMenuBuilderPlan = !self.menuBuilderPlan;
      const menuBuilderPlanEmpty = self.menuBuilderPlan?.numberOfItems === 0;

      return noMenuBuilderPlan || menuBuilderPlanEmpty;
    },
    get numberOfGiftItems() {
      return _.size(self.giftCertificates);
    },
    get numberOfPlansItems() {
      return _.sumBy(self.plans, 'numberOfItems');
    },
    get numberOfItems() {
      return this.numberOfGiftItems + this.numberOfPlansItems;
    },
    get numberOfDiscountedItems(): number {
      return _.sumBy(self.plans, 'numberOfDiscountedItems');
    },
    get discountedAmount(): number {
      return _.subtract(this.lineItemTotal, this.subTotal);
    },
    get plansLookup(): Record<string, OrderPlan> {
      return _.keyBy(self.plans, 'planId');
    },
    get draftPlanLookup(): Record<string, OrderPlan> {
      return _.keyBy(self.draftPlans, 'planId');
    },
    get discount(): number {
      const thresholdDiscounts = Object.values(DISCOUNTS);
      const thresholds = _.map(thresholdDiscounts, 'threshold');
      const numberOfDiscountedItems = this.numberOfDiscountedItems;
      const index = _.findLastIndex(
        thresholds,
        (o: number): boolean => o <= numberOfDiscountedItems,
      );

      return _.nth(thresholdDiscounts, index)?.value ?? 0;
    },
    get itemPromotions(): PromotionModelType[] {
      return _.filter(
        self.promotions,
        (promotion) => promotion.type !== DiscountType.SHIPPING,
      );
    },
    get shippingPromotions(): PromotionModelType[] {
      return _.filter(
        self.promotions,
        (promotion) => promotion.type === DiscountType.SHIPPING,
      );
    },
    get itemPromotionTotal(): number {
      return _.sumBy(this.itemPromotions, 'value');
    },
    get shippingPromotionTotal(): number {
      return _.sumBy(this.shippingPromotions, 'value');
    },
    get itemTotal(): number {
      return _.sumBy(self.plans, 'total');
    },
    get lineItemSubTotal(): number {
      return _.sumBy(self.plans, 'lineItemSubTotal');
    },
    get giftCertificateTotal(): number {
      return _.sumBy(self.giftCertificates, 'amount');
    },
    get giftCardDiscount(): number {
      return _.sumBy(self.payments?.giftCards, 'amount') ?? 0;
    },
    get subTotal(): number {
      return _.round(this.itemTotal + this.giftCertificateTotal, 2);
    },
    get lineItemTotal(): number {
      return _.round(this.lineItemSubTotal + this.giftCertificateTotal, 2);
    },
    get totalLineItems(): number {
      return _.sumBy(self.plans, 'numberOfItems');
    },
    get hasGiftItem(): boolean {
      return self.giftCertificates.length > 0;
    },
    get isGiftItemOnly(): boolean {
      return this.totalLineItems === 0 && this.hasGiftItem;
    },
    get cartTotal(): number {
      const total =
        this.subTotal +
        this.itemPromotionTotal -
        this.shippingPromotionTotal -
        this.giftCardDiscount;
      return _.round(total, 2);
    },
    get total(): number {
      const total =
        this.subTotal +
        self.shippingTotal -
        this.itemPromotionTotal -
        this.shippingPromotionTotal -
        this.giftCardDiscount;
      return _.round(total, 2);
    },
    get orderTotal(): number {
      const total =
        this.subTotal +
        this.shippingTotal -
        this.itemPromotionTotal -
        this.shippingPromotionTotal;
      return _.round(total, 2);
    },
    get totalWithoutDiscounts(): number {
      if (
        self.rootStore.generalStore.SMOV ===
        self.rootStore.generalStore.freeShippingThreshold
      ) {
        return _.round(this.subTotal, 2);
      }
      const total = this.itemTotal - this.itemPromotionTotal;
      return _.round(total, 2);
    },
    get discountedSubtotal(): number {
      const total = this.subTotal - this.itemPromotionTotal;
      return _.round(total, 2);
    },
    get outStandingAmountWithShipping(): number {
      return _.round(
        this.subTotal -
          this.itemPromotionTotal -
          this.credit -
          this.giftCardDiscount,
        2,
      );
    },
    get gst(): number {
      return _.round((this.total - this.giftCertificateTotal) / 11, 2);
    },
    get isSubscriptionOrder(): boolean {
      return self.frequency && self.frequency !== Frequency.ONCE;
    },
    get menuBuilderItems() {
      const menuBuilderPlan = this.menuBuilderPlan;
      const menuBuilderItems = _.groupBy(
        menuBuilderPlan?.lineItems ?? [],
        (item) => item.product.displayCategory,
      );
      return _.map(_.entries(menuBuilderItems), ([title, data]) => ({
        title,
        data,
      }));
    },
    get cartList() {
      const orderPlans = _.filter(
        self.plans,
        (plan) => plan.category !== PlanCategory.MENU,
      );
      const items = this.menuBuilderItems;

      const data = [
        { title: 'Order Plans', data: orderPlans },
        { title: 'Gift Cards', data: self.giftCertificates },
        ...items,
      ];

      return _.filter(data, (item) => item.data.length > 0);
    },
    get isEmpty() {
      return this.numberOfItems === 0;
    },
    get isConfirmButtonDisabled() {
      if (!self.frequency) {
        return true;
      }
      if (this.isGiftItemOnly && _.isEmpty(self.delivery.deliveryAddress)) {
        return _.isEmpty(self.billingAddress);
      }
      return _.isEmpty(self.delivery.deliveryAddress);
    },
    get isConfirmButtonSpinning() {
      return self.loading || self.isRefreshing > 0;
    },
    get isWLP() {
      const orderPlans = _.filter(
        self.plans,
        (plan) => plan.category === PlanCategory.WLP,
      );
      return orderPlans && orderPlans.length > 0;
    },
    get moreThanOneWLPPlan() {
      const orderPlans = _.filter(
        self.plans,
        (plan) => plan.category === PlanCategory.WLP,
      );
      return orderPlans.length > 1;
    },
    get overview() {
      const numberOfDiscountedItems = this.numberOfDiscountedItems;
      const thresholdDiscounts = Object.values(DISCOUNTS);
      const thresholds = _.map(thresholdDiscounts, 'threshold');
      const discountValues = _.map(thresholdDiscounts, 'value');
      const minIndx = _.findIndex(thresholdDiscounts, (o) => o.value > 0);
      const index = _.findLastIndex(
        thresholds,
        (o) => o <= numberOfDiscountedItems,
      );
      const nextIndex = Math.min(
        Math.max(index + 1, minIndx),
        thresholdDiscounts.length - 1,
      );
      const offset = (index > -1 ? thresholds[index] : 0) ?? 0;
      const threshold = thresholds[nextIndex] ?? 0;
      const curPercentage =
        threshold === offset
          ? 1
          : (numberOfDiscountedItems - offset) / (threshold - offset);
      const percentage = Math.min(1, curPercentage);
      const discount = thresholdDiscounts[nextIndex]?.value;
      const currentDiscount = thresholdDiscounts[index]?.value ?? 0;
      return {
        discount,
        currentDiscount,
        percentage,
        numberOfDiscountedItems,
        threshold,
        discountValues,
      };
    },
    get selectedPlan() {
      return this.plansLookup[self.planIdToView as string];
    },
    get isAddedToDraftPlans() {
      return self.draftPlans.length > 0;
    },
    get hidePaymentContainer() {
      return !self.delivery?.deliveryAddress && !this.isGiftItemOnly;
    },
    get outStandingAmount() {
      return _.round(
        this.orderTotal - (this.credit + this.giftCardDiscount),
        2,
      );
    },
    get credit(): number {
      return self?.payments?.credit?.amount ?? 0;
    },
    get isFreeShipping(): boolean {
      return (
        self.totalWithoutDiscounts >
        self.rootStore.generalStore.freeShippingThreshold
      );
    },
    get canSubscribed() {
      return (
        !this.isGiftItemOnly &&
        self.totalWithoutDiscounts > self.rootStore.generalStore.SMOV
      );
    },
    get isBelowFreeShippingThreshold(): boolean {
      return (
        self.rootStore.generalStore.SMOV <
          self.rootStore.generalStore.freeShippingThreshold &&
        !self.isFreeShipping
      );
    },
    get promotionDesc() {
      if (!self.couponCode) {
        return '';
      }
      const promotion = _.find(
        self.promotions,
        (promotion) =>
          promotion.code?.toLowerCase() === self.couponCode.toLowerCase(),
      );
      if (promotion) {
        return promotion.promotionId && promotion.promotionId !== promotion.desc
          ? promotion.desc
          : '';
      }
      return '';
    },
    get selectedPlan() {
      if (!self.planIdToView) {
        return;
      }
      return _.get(
        this.draftPlanLookup,
        self.planIdToView,
        _.get(this.plansLookup, self.planIdToView),
      );
    },
  }))
  .actions((self) => ({
    initOrderPlan(data: any) {
      //if the reorder plan is not menu
      //add it as another plan
      if (data.category !== 'MENU') {
        const reOrderPlanCount = _.filter(self.plans, {
          planId: data.planId,
        }).length;
        if (reOrderPlanCount > 0) {
          data.planId = randomise('Aa0', 16);
        }
        const plan = OrderPlanModel.create({
          planId: randomise('Aa0', 16),
          ...data,
        });
        self.plans.push(plan);
        return plan;
      }
      // Check if there is a menu builder plan in the cart
      const menuBuilderPlan: any = _.find(self.plans, {
        category: 'MENU',
      });
      //If no menu builder plan exists in the cart then initialize with new
      // menu builder plan
      let plan;
      if (!menuBuilderPlan || _.isEmpty(menuBuilderPlan)) {
        plan = OrderPlanModel.create({
          planId: randomise('Aa0', 16),
          ...data,
        });
        self.plans.push(plan);
      } else {
        //Check if the plan being added is menu builder
        if (data.category === PlanCategory.MENU && data.lineItems) {
          data.lineItems.forEach((item: LineItem) => {
            menuBuilderPlan?.increaseLineItem(item, item.qty, '');
          });
        }
        // This is to support null subcategory issue in api
        data.subCategory =
          data.subCategory != null ? data.subCategory : undefined;
      }
      return plan;
    },
    setLoading(loading: boolean) {
      self.loading = loading;
    },
    setLoadingDeliveryInfo(loading: boolean) {
      self.loadingDeliveryInfo = loading;
    },
    setVariant(variant?: string) {
      if (variant) {
        self.variant = variant;
      }
    },
    resetFrequency() {
      self.frequency = null;
    },
    setFrequency(frequency: Frequency | null) {
      self.frequency = frequency;
    },
    setUpdatedAt(updatedAt: Date) {
      self.updatedAt = updatedAt;
    },
    setDeliveryAddress(address: Address, checkoutMethod?: string) {
      self.delivery.setDeliveryAddress(cast(address));
    },
    setNoDelivery(value: boolean) {
      self.noDelivery = value;
    },
    clearDelivery() {
      self.delivery = undefined;
    },
    setDeliveryDate(date: string, checkoutMethod?: string) {
      self.delivery.setDeliveryDate(cast(date));
    },
    setTimeSlot(timeSlot: string, checkoutMethod?: string) {
      self.delivery.setTimeSlot(cast(timeSlot));
    },
    setBillinAddress(address: any) {
      self.billingAddress = { ...address };
    },
    setPayment(payment: any) {
      if (!self.payments) {
        self.payments = PaymentsModel.create({});
      }
      self.payments?.setPayment(cast(payment));
    },
    removeGiftCard(code: string) {
      self.payments?.removeGiftCard(code);
    },
    setCouponCode(code: string) {
      self.couponCode = code;
    },
    setCredit(amount: number) {
      const credit = amount > self.orderTotal ? self.orderTotal : amount;
      self.payments?.setCredit(credit);
    },
    setNonce(type: string, nonce: string, deviceData?: string) {
      if (!self.payments) {
        self.payments = PaymentsModel.create({});
        trackCheckoutStepCompleted(
          cart as Cart,
          CheckoutStep.PaymentMethod,
          CheckoutCompletionType.FirstFill,
          payment.type,
        );
      }
      self.payments?.setNonce(type, nonce, deviceData);
    },
    addGiftCard(code: string, amount: number) {
      // Exclude purchased gift cards
      let redeemedAmount = self.outStandingAmount - self.giftCertificateTotal;
      redeemedAmount = amount > redeemedAmount ? redeemedAmount : amount;
      self.payments?.addGiftCard(code, Math.max(redeemedAmount, 0));
    },
    resetRefreshing() {
      self.isRefreshing = 0;
    },
    addPromoCode(promoCode: any) {
      const promotion = _.find(
        self.promotions,
        (promotion) =>
          promotion.code === (promoCode.code ?? '') &&
          promotion.type === promoCode.type,
      );
      if (!promotion) {
        self.promotions.push(
          PromotionModel.create({
            promotionId: promoCode?.id,
            code: promoCode?.code ?? '',
            desc: promoCode?.desc ?? '',
            value: promoCode?.value,
            type: promoCode?.type,
          }),
        );
      }
    },
    removePromoCode(code: string) {
      const promotion = _.find(
        self.promotions,
        (promotion) => promotion.code?.toLowerCase() === code.toLowerCase(),
      );
      if (promotion) {
        try {
          trackCouponRemoved(self.cartId, promotion);
        } catch (error) {
          // ignore error
        }
        destroy(promotion);
      }
    },
    setZone(zone: string) {
      if (zone !== self.delivery.zone) {
        self.delivery.setZone(zone);
      }
    },
    removeInactiveItems(productsLookup: any) {
      const differentPlans = [self.plans, self.draftPlans];
      differentPlans.forEach((plans) => {
        plans.forEach((plan) => {
          plan.lineItems.forEach((item) => {
            const itemObj = getSnapshot(item);
            if (!productsLookup[itemObj.sku]) {
              if (plan.category === PlanCategory.MENU) {
                destroy(item);
              } else {
                destroy(plan);
              }
            }
          });
        });
      });
    },
    setShippingCostBase(shippingCostBase: number) {
      self.shippingCostBase = shippingCostBase;
    },
    setShippingTotal(shippingTotal: number) {
      self.shippingTotal = shippingTotal;
    },
    setExtraBoxFee(extraBoxFee: number) {
      self.extraBoxFee = extraBoxFee;
    },
    clearPromotions() {
      self.promotions?.clear();
    },
    clearCredit() {
      self.payments?.setCredit(0);
    },
    clearGiftCards() {
      self.payments?.giftCards?.clear();
    },
    initPayment() {
      self.payments = PaymentsModel.create({});
    },
    clearCart() {
      self.plans.clear();
      self.delivery?.clear();
      self.billingAddress = {};
      self.initPayment();
      self.frequency = null;
      self.promotions?.clear();
      self.rootStore.cartStore.goalBased.reset();
      self.rootStore.cartStore.resetDraftCart();
      self.giftCertificates.clear();
    },
    defaultCartOpen() {
      self.isDefaultCartOpen = true;
    },
    openCart() {
      self.isCartOpen = true;
      try {
        trackCartViewed(
          self as Cart,
          self.rootStore.productStore.productsFullList,
        );
      } catch (error) {
        // ignore error
      }
    },
    closeCart() {
      self.isCartOpen = false;
    },
    openMealPlan() {
      self.isMealPlanOpen = true;
    },
    closeMealPlan() {
      self.isMealPlanOpen = false;
    },
    addPlan(plan: OrderPlan) {
      self.plans.push(clone(plan));
    },
    replacePlan(plan: OrderPlan, planId: string) {
      const index = _.findIndex(self.plans, (p) => p.planId === planId);
      if (index > -1) {
        self.plans[index] = clone(plan);
      }
    },
    addDraftPlans(plans: OrderPlan[]) {
      self.draftPlans.push(...plans);
    },
    resetDraftPlans() {
      self.draftPlans.clear();
    },
    removePlan(planId: string) {
      const plan = _.find(self.plans, (plan) => plan.planId === planId);
      if (plan) {
        try {
          trackMealPlanRemovedFromCart(plan);
        } catch (error) {
          // ignore error
        }
        destroy(plan);
      }
    },
    setAction(action: Action) {
      self.action = action;
    },
    setMode(mode: Mode) {
      self.mode = mode;
    },
    setPlanIdToView(planId: string) {
      self.planIdToView = planId;
    },
    addGiftItem(data: any) {
      const giftItem = GiftCertificateModel.create({
        giftCardId: randomise('Aa0', 16),
        grossPrice: data.netPrice,
        ...data,
      });
      self.giftCertificates.push(giftItem);
      return giftItem;
    },
    removeGiftItem(giftCardId: string) {
      const item = _.find(
        self.giftCertificates,
        (item) => item.giftCardId === giftCardId,
      );
      if (item) {
        destroy(item);
      }
    },
  }));

export interface Cart extends Instance<typeof CartModel> {}
