// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DiscoverCardModelDescriptionFieldModel, DiscoverCardModelDescriptionFieldModelType } from "./DiscoverCardModelDescriptionFieldModel"
import { DiscoverCardModelDescriptionFieldModelSelector } from "./DiscoverCardModelDescriptionFieldModel.base"
import { DiscoverCardModelTitleFieldModel, DiscoverCardModelTitleFieldModelType } from "./DiscoverCardModelTitleFieldModel"
import { DiscoverCardModelTitleFieldModelSelector } from "./DiscoverCardModelTitleFieldModel.base"
import { FileFieldModel, FileFieldModelType } from "./FileFieldModel"
import { FileFieldModelSelector } from "./FileFieldModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/**
 * DiscoverCardRecordBase
 * auto generated base class for the model DiscoverCardRecordModel.
 *
 * Block of type Discover Card (discover_card)
 */
export const DiscoverCardRecordModelBase = ModelBase
  .named('DiscoverCardRecord')
  .props({
    __typename: types.optional(types.literal("DiscoverCardRecord"), "DiscoverCardRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    action: types.union(types.undefined, types.null, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    darkImage: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    description: types.union(types.undefined, types.null, types.late((): any => DiscoverCardModelDescriptionFieldModel)),
    id: types.union(types.undefined, types.frozen()),
    image: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    param: types.union(types.undefined, types.null, types.frozen()),
    root: types.union(types.undefined, types.null, types.string),
    screen: types.union(types.undefined, types.null, types.string),
    title: types.union(types.undefined, types.null, types.late((): any => DiscoverCardModelTitleFieldModel)),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DiscoverCardRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get action() { return this.__attr(`action`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get id() { return this.__attr(`id`) }
  get param() { return this.__attr(`param`) }
  get root() { return this.__attr(`root`) }
  get screen() { return this.__attr(`screen`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  darkImage(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`darkImage`, FileFieldModelSelector, builder) }
  description(builder: string | DiscoverCardModelDescriptionFieldModelSelector | ((selector: DiscoverCardModelDescriptionFieldModelSelector) => DiscoverCardModelDescriptionFieldModelSelector) | undefined) { return this.__child(`description`, DiscoverCardModelDescriptionFieldModelSelector, builder) }
  image(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`image`, FileFieldModelSelector, builder) }
  title(builder: string | DiscoverCardModelTitleFieldModelSelector | ((selector: DiscoverCardModelTitleFieldModelSelector) => DiscoverCardModelTitleFieldModelSelector) | undefined) { return this.__child(`title`, DiscoverCardModelTitleFieldModelSelector, builder) }
}
export function selectFromDiscoverCardRecord() {
  return new DiscoverCardRecordModelSelector()
}

export const discoverCardRecordModelPrimitives = selectFromDiscoverCardRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._status._unpublishingScheduledAt._updatedAt.action.createdAt.param.root.screen.updatedAt
