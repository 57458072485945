// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DeliveryDetailModel, DeliveryDetailModelType } from "./DeliveryDetailModel"
import { DeliveryDetailModelSelector } from "./DeliveryDetailModel.base"
import { MessageRtoModel, MessageRtoModelType } from "./MessageRtoModel"
import { MessageRtoModelSelector } from "./MessageRtoModel.base"
import { RootStoreType } from "./index"


/**
 * OrderDeliveryInfoBase
 * auto generated base class for the model OrderDeliveryInfoModel.
 *
 * Order Delivery Info Response
 */
export const OrderDeliveryInfoModelBase = ModelBase
  .named('OrderDeliveryInfo')
  .props({
    __typename: types.optional(types.literal("OrderDeliveryInfo"), "OrderDeliveryInfo"),
    availableDeliveryDetails: types.union(types.undefined, types.array(types.late((): any => DeliveryDetailModel))),
    cutoffTime: types.union(types.undefined, types.null, types.frozen()),
    message: types.union(types.undefined, types.null, types.late((): any => MessageRtoModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class OrderDeliveryInfoModelSelector extends QueryBuilder {
  get cutoffTime() { return this.__attr(`cutoffTime`) }
  availableDeliveryDetails(builder: string | DeliveryDetailModelSelector | ((selector: DeliveryDetailModelSelector) => DeliveryDetailModelSelector) | undefined) { return this.__child(`availableDeliveryDetails`, DeliveryDetailModelSelector, builder) }
  message(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined) { return this.__child(`message`, MessageRtoModelSelector, builder) }
}
export function selectFromOrderDeliveryInfo() {
  return new OrderDeliveryInfoModelSelector()
}

export const orderDeliveryInfoModelPrimitives = selectFromOrderDeliveryInfo().cutoffTime
