// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ActivityTypeEnumType } from "./ActivityTypeEnum"
import { RootStoreType } from "./index"


/**
 * CustomerActivityBase
 * auto generated base class for the model CustomerActivityModel.
 *
 * Customer Activity
 */
export const CustomerActivityModelBase = ModelBase
  .named('CustomerActivity')
  .props({
    __typename: types.optional(types.literal("CustomerActivity"), "CustomerActivity"),
    _id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    meta: types.union(types.undefined, types.null, types.frozen()),
    name: types.union(types.undefined, types.string),
    recordDate: types.union(types.undefined, types.frozen()),
    type: types.union(types.undefined, ActivityTypeEnumType),
    unit: types.union(types.undefined, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
    value: types.union(types.undefined, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CustomerActivityModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get meta() { return this.__attr(`meta`) }
  get name() { return this.__attr(`name`) }
  get recordDate() { return this.__attr(`recordDate`) }
  get type() { return this.__attr(`type`) }
  get unit() { return this.__attr(`unit`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get value() { return this.__attr(`value`) }
}
export function selectFromCustomerActivity() {
  return new CustomerActivityModelSelector()
}

export const customerActivityModelPrimitives = selectFromCustomerActivity()._id.createdAt.meta.name.recordDate.type.unit.updatedAt.value
