// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum DashboardActionType {
  FUNCTION="FUNCTION",
MODAL="MODAL",
SCREEN="SCREEN"
}

/**
* DashboardActionType
*/
export const DashboardActionTypeEnumType = types.enumeration("DashboardActionType", [
        "FUNCTION",
  "MODAL",
  "SCREEN",
      ])
