import { Instance, types } from 'mobx-state-tree';
import { PaymentType } from '../api';

export const PaymentCardModel = types
  .model({
    type: 'payment_card',
    cardType: types.union(types.null, types.string),
    creditCardExpired: false,
    expirationMonth: types.maybeNull(types.number),
    expirationYear: types.maybeNull(types.number),
    holder: types.union(types.undefined, types.null, types.string),
    maskedNumber: types.union(types.undefined, types.null, types.string),
    numberLastDigits: types.union(types.undefined, types.null, types.string),
  })
  .views((self: any) => ({
    get cardTypeTrim() {
      return self.cardType.replace(/\s+-.*/, '');
    },
    get expiryDate() {
      return `${self.expirationMonth}/${self.expirationYear?.toString()?.slice(-2)}`
    }
  }));

export const PaymentModel = types
  .model({
    paymentInstrumentId: types.identifier,
    paymentMethodId: types.enumeration(Object.values(PaymentType)),
    paypalAccountEmail: types.union(types.undefined, types.null, types.string),
    paymentCard: types.union(types.undefined, types.null, PaymentCardModel),
    paymentMethodToken: types.union(types.undefined, types.null, types.string),
    paymentNonce: types.union(types.undefined, types.null, types.string),
    preferred: false,
  })
  .views((self: any) => ({
    get accountType() {
      if (self.paymentMethodId === 'CREDIT_CARD') {
        return self.paymentCard?.cardType || 'Credit Card';
      }
      if (self.paymentMethodId === 'PAYPAL') {
        return 'PayPal';
      }
      if (self.paymentMethodId === 'ZIPPAY') {
        return 'ZipPay';
      }
      return self.paymentMethodId;
    },
    get account() {
      return self.paypalAccountEmail ?? self.paymentCard?.numberLastDigits?.padStart(8,'*') ?? '';
    },
  }));

export interface Payment extends Instance<typeof PaymentModel> {}
