// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ShipNowOrder {
  CURRENT_ORDER="CURRENT_ORDER",
UPCOMING_ORDER="UPCOMING_ORDER"
}

/**
* ShipNowOrder
*/
export const ShipNowOrderEnumType = types.enumeration("ShipNowOrder", [
        "CURRENT_ORDER",
  "UPCOMING_ORDER",
      ])
