import { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import { ProductModel } from '@/models/api';
import _ from 'lodash';

export const ProductRestrictionLimitsModel = types
	.model('ProductRestrictionLimits')
	.props({
		products: types.optional(
      types.array(types.late(() => types.reference(ProductModel))),
      []
    ),
    quantityLimit: types.optional(types.number, 0),
    errorMessage: types.optional(types.string, ''),
	})
	.views((self) => ({
    containsProduct(sku: string): boolean {
      return _.some(self.products, (p) => p.sku === sku);
    },
  }));


  export type ProductRestrictionLimitsType = Instance<typeof ProductRestrictionLimitsModel>;
  export interface ProductRestrictionLimits extends ProductRestrictionLimitsType {}

