// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum MessageType {
  BANNER="BANNER",
DIALOG="DIALOG",
SNACKBAR="SNACKBAR",
TOAST="TOAST"
}

/**
* MessageType
*/
export const MessageTypeEnumType = types.enumeration("MessageType", [
        "BANNER",
  "DIALOG",
  "SNACKBAR",
  "TOAST",
      ])
