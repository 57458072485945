// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CustomerActivityModel, CustomerActivityModelType } from "./CustomerActivityModel"
import { CustomerActivityModelSelector } from "./CustomerActivityModel.base"
import { RootStoreType } from "./index"


/**
 * DailyOrderExtraBase
 * auto generated base class for the model DailyOrderExtraModel.
 */
export const DailyOrderExtraModelBase = ModelBase
  .named('DailyOrderExtra')
  .props({
    __typename: types.optional(types.literal("DailyOrderExtra"), "DailyOrderExtra"),
    _id: types.identifier,
    activity: types.union(types.undefined, types.null, types.late((): any => CustomerActivityModel)),
    activityId: types.union(types.undefined, types.null, types.string),
    calories: types.union(types.undefined, types.number),
    name: types.union(types.undefined, types.string),
    quantity: types.union(types.undefined, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DailyOrderExtraModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get activityId() { return this.__attr(`activityId`) }
  get calories() { return this.__attr(`calories`) }
  get name() { return this.__attr(`name`) }
  get quantity() { return this.__attr(`quantity`) }
  activity(builder: string | CustomerActivityModelSelector | ((selector: CustomerActivityModelSelector) => CustomerActivityModelSelector) | undefined) { return this.__child(`activity`, CustomerActivityModelSelector, builder) }
}
export function selectFromDailyOrderExtra() {
  return new DailyOrderExtraModelSelector()
}

export const dailyOrderExtraModelPrimitives = selectFromDailyOrderExtra()._id.activityId.calories.name.quantity
