// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ImgixParamsAuto {
  compress="compress",
enhance="enhance",
format="format",
redeye="redeye"
}

/**
* ImgixParamsAuto
*/
export const ImgixParamsAutoEnumType = types.enumeration("ImgixParamsAuto", [
        "compress",
  "enhance",
  "format",
  "redeye",
      ])
