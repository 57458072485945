import { render as toPlainText } from 'datocms-structured-text-to-plain-text';
import { isLink, isParagraph } from 'datocms-structured-text-utils';
import Link from 'next/link';
import React from 'react';
import { renderNodeRule, StructuredText } from 'react-datocms';

interface StructuredTextProps {
  content: any;
}

const TopLineStructuredText: React.FC<StructuredTextProps> = ({
  content,
}: StructuredTextProps) => {
  return (
    <div className="mx-auto max-w-2xl break-words">
      <StructuredText
        data={content}
        customNodeRules={[
          renderNodeRule(isParagraph, ({ children, key }) => {
            return (
              <p
                className="font-interMedium text-xs leading-6 text-black lg:text-sm"
                key={key}
              >
                {children}
              </p>
            );
          }),
          renderNodeRule(isLink, ({ node, key }) => {
            const linkUrl = node.url.replace(
              /https?:\/\/(www\.)?my(muscle|code)chef\.com/,
              ''
            );
            const isInternal = linkUrl.startsWith('/');
            const linkText = toPlainText(node);
            return (
              <span key={key} className="cursor-pointer text-black underline">
                {isInternal ? (
                  <Link href={linkUrl} legacyBehavior>
                    {linkText}
                  </Link>
                ) : (
                  <a href={linkUrl} target="_blank" rel="noreferrer">
                    {linkText}
                  </a>
                )}
              </span>
            );
          }),
        ]}
      />
    </div>
  );
};

export default TopLineStructuredText;
