// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DashboardImagesModel, DashboardImagesModelType } from "./DashboardImagesModel"
import { DashboardImagesModelSelector } from "./DashboardImagesModel.base"
import { RootStoreType } from "./index"


/**
 * WellnessRtoBase
 * auto generated base class for the model WellnessRtoModel.
 */
export const WellnessRtoModelBase = ModelBase
  .named('WellnessRto')
  .props({
    __typename: types.optional(types.literal("WellnessRTO"), "WellnessRTO"),
    content: types.union(types.undefined, types.string),
    goalTitle: types.union(types.undefined, types.string),
    goals: types.union(types.undefined, types.array(types.string)),
    icons: types.union(types.undefined, types.late((): any => DashboardImagesModel)),
    images: types.union(types.undefined, types.late((): any => DashboardImagesModel)),
    title: types.union(types.undefined, types.string),
    value: types.union(types.undefined, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class WellnessRtoModelSelector extends QueryBuilder {
  get content() { return this.__attr(`content`) }
  get goalTitle() { return this.__attr(`goalTitle`) }
  get goals() { return this.__attr(`goals`) }
  get title() { return this.__attr(`title`) }
  get value() { return this.__attr(`value`) }
  icons(builder: string | DashboardImagesModelSelector | ((selector: DashboardImagesModelSelector) => DashboardImagesModelSelector) | undefined) { return this.__child(`icons`, DashboardImagesModelSelector, builder) }
  images(builder: string | DashboardImagesModelSelector | ((selector: DashboardImagesModelSelector) => DashboardImagesModelSelector) | undefined) { return this.__child(`images`, DashboardImagesModelSelector, builder) }
}
export function selectFromWellnessRto() {
  return new WellnessRtoModelSelector()
}

export const wellnessRtoModelPrimitives = selectFromWellnessRto().content.goalTitle.goals.title.value
