// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ShippingStatus {
  CANCELLED="CANCELLED",
DEFAULT="DEFAULT",
DELIVERED="DELIVERED",
DISPATCHING="DISPATCHING",
INVOICED="INVOICED",
LOST="LOST",
NOT_DELIVERED="NOT_DELIVERED",
NOT_SHIPPED="NOT_SHIPPED",
OPENORDER="OPENORDER",
ORDER_CONFIRMED="ORDER_CONFIRMED",
OUT_FOR_DELIVERY="OUT_FOR_DELIVERY",
PICKING="PICKING",
SHIPPED="SHIPPED"
}

/**
* ShippingStatus
*/
export const ShippingStatusEnumType = types.enumeration("ShippingStatus", [
        "CANCELLED",
  "DEFAULT",
  "DELIVERED",
  "DISPATCHING",
  "INVOICED",
  "LOST",
  "NOT_DELIVERED",
  "NOT_SHIPPED",
  "OPENORDER",
  "ORDER_CONFIRMED",
  "OUT_FOR_DELIVERY",
  "PICKING",
  "SHIPPED",
      ])
