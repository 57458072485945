import _ from 'lodash';

import type { ProfileStageRecordModelType } from '@/models/api';
import { isNotEmpty } from '@/utils/helpers';

export type ProfileStageAttribute = {
  name: string;
  value: any;
};

export const YEAR_OPTIONS = _.reverse(
  _.map(_.range(1900, new Date().getFullYear() - 16), (year) => ({
    value: year.toString(),
    label: year.toString(),
  })),
);

export const DEFAULT_YEAR_OPTION = _.nth(YEAR_OPTIONS, 10);

export const extractAttributes = (data: ProfileStageRecordModelType) => {
  const sections = data.sections || [];

  const attributes = _.flattenDeep(
    _.map(sections, (section) => {
      return _.map(section.questions, (question) => {
        return {
          name: question.name,
          value: question.value,
        };
      });
    }),
  );
  return attributes;
};

export const validateAttributes = (
  data: ProfileStageRecordModelType,
  attributes: ProfileStageAttribute[],
): boolean => {
  const sections = data.sections || [];
  const attributesMap = _.keyBy(attributes, 'name');

  const questions = _.flattenDeep(
    _.map(sections, (section) => {
      return _.map(section.questions, (question) => {
        return {
          name: question.name,
          value: question.value,
          required: section.isRequired,
        };
      });
    }),
  );

  const isFullFilled = _.every(questions, (question) => {
    if (question.required) {
      return isNotEmpty(attributesMap[question.name]?.value);
    }
    return true;
  });

  return isFullFilled;
};
