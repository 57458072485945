// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * TokenRtoBase
 * auto generated base class for the model TokenRtoModel.
 */
export const TokenRtoModelBase = ModelBase
  .named('TokenRto')
  .props({
    __typename: types.optional(types.literal("TokenRTO"), "TokenRTO"),
    token: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class TokenRtoModelSelector extends QueryBuilder {
  get token() { return this.__attr(`token`) }
}
export function selectFromTokenRto() {
  return new TokenRtoModelSelector()
}

export const tokenRtoModelPrimitives = selectFromTokenRto().token
