// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * GeneralPageModelSeoBlurbFieldBase
 * auto generated base class for the model GeneralPageModelSeoBlurbFieldModel.
 */
export const GeneralPageModelSeoBlurbFieldModelBase = ModelBase
  .named('GeneralPageModelSeoBlurbField')
  .props({
    __typename: types.optional(types.literal("GeneralPageModelSeoBlurbField"), "GeneralPageModelSeoBlurbField"),
    blocks: types.union(types.undefined, types.array(types.string)),
    links: types.union(types.undefined, types.array(types.string)),
    value: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GeneralPageModelSeoBlurbFieldModelSelector extends QueryBuilder {
  get blocks() { return this.__attr(`blocks`) }
  get links() { return this.__attr(`links`) }
  get value() { return this.__attr(`value`) }
}
export function selectFromGeneralPageModelSeoBlurbField() {
  return new GeneralPageModelSeoBlurbFieldModelSelector()
}

export const generalPageModelSeoBlurbFieldModelPrimitives = selectFromGeneralPageModelSeoBlurbField().blocks.links.value
