// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { QueryBuilder } from "mst-gql"
import { QuizButtonSectionRecordModelType } from "./QuizButtonSectionRecordModel"
import { QuizButtonSectionRecordModelSelector, quizButtonSectionRecordModelPrimitives } from "./QuizButtonSectionRecordModel.base"
import { QuizInputSectionRecordModelType } from "./QuizInputSectionRecordModel"
import { QuizInputSectionRecordModelSelector, quizInputSectionRecordModelPrimitives } from "./QuizInputSectionRecordModel.base"
import { QuizPlanSectionRecordModelType } from "./QuizPlanSectionRecordModel"
import { QuizPlanSectionRecordModelSelector, quizPlanSectionRecordModelPrimitives } from "./QuizPlanSectionRecordModel.base"

export type GoalBasedQuizModelSectionsFieldUnion = QuizButtonSectionRecordModelType | QuizInputSectionRecordModelType | QuizPlanSectionRecordModelType

export class GoalBasedQuizModelSectionsFieldModelSelector extends QueryBuilder {
  quizButtonSectionRecord(builder?: string | QuizButtonSectionRecordModelSelector | ((selector: QuizButtonSectionRecordModelSelector) => QuizButtonSectionRecordModelSelector)) { return this.__inlineFragment(`QuizButtonSectionRecord`, QuizButtonSectionRecordModelSelector, builder) }
  quizInputSectionRecord(builder?: string | QuizInputSectionRecordModelSelector | ((selector: QuizInputSectionRecordModelSelector) => QuizInputSectionRecordModelSelector)) { return this.__inlineFragment(`QuizInputSectionRecord`, QuizInputSectionRecordModelSelector, builder) }
  quizPlanSectionRecord(builder?: string | QuizPlanSectionRecordModelSelector | ((selector: QuizPlanSectionRecordModelSelector) => QuizPlanSectionRecordModelSelector)) { return this.__inlineFragment(`QuizPlanSectionRecord`, QuizPlanSectionRecordModelSelector, builder) }
}
export function selectFromGoalBasedQuizModelSectionsField() {
  return new GoalBasedQuizModelSectionsFieldModelSelector()
}

// provides all primitive fields of union member types combined together
export const goalBasedQuizModelSectionsFieldModelPrimitives = selectFromGoalBasedQuizModelSectionsField().quizButtonSectionRecord(quizButtonSectionRecordModelPrimitives).quizInputSectionRecord(quizInputSectionRecordModelPrimitives).quizPlanSectionRecord(quizPlanSectionRecordModelPrimitives)