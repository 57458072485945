// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FilterSectionModelTipsFieldModel, FilterSectionModelTipsFieldModelType } from "./FilterSectionModelTipsFieldModel"
import { FilterSectionModelTipsFieldModelSelector } from "./FilterSectionModelTipsFieldModel.base"
import { FilterValueRecordModel, FilterValueRecordModelType } from "./FilterValueRecordModel"
import { FilterValueRecordModelSelector } from "./FilterValueRecordModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { OrderPlanCategoryRecordModel, OrderPlanCategoryRecordModelType } from "./OrderPlanCategoryRecordModel"
import { OrderPlanCategoryRecordModelSelector } from "./OrderPlanCategoryRecordModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/**
 * FilterSectionRecordBase
 * auto generated base class for the model FilterSectionRecordModel.
 *
 * Block of type Filter Section (filter_section)
 */
export const FilterSectionRecordModelBase = ModelBase
  .named('FilterSectionRecord')
  .props({
    __typename: types.optional(types.literal("FilterSectionRecord"), "FilterSectionRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    category: types.union(types.undefined, types.array(types.late((): any => OrderPlanCategoryRecordModel))),
    createdAt: types.union(types.undefined, types.frozen()),
    displayType: types.union(types.undefined, types.null, types.string),
    filterType: types.union(types.undefined, types.null, types.string),
    id: types.union(types.undefined, types.frozen()),
    key: types.union(types.undefined, types.null, types.string),
    name: types.union(types.undefined, types.null, types.string),
    tips: types.union(types.undefined, types.null, types.late((): any => FilterSectionModelTipsFieldModel)),
    updatedAt: types.union(types.undefined, types.frozen()),
    values: types.union(types.undefined, types.array(types.late((): any => FilterValueRecordModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FilterSectionRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get displayType() { return this.__attr(`displayType`) }
  get filterType() { return this.__attr(`filterType`) }
  get id() { return this.__attr(`id`) }
  get key() { return this.__attr(`key`) }
  get name() { return this.__attr(`name`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  category(builder: string | OrderPlanCategoryRecordModelSelector | ((selector: OrderPlanCategoryRecordModelSelector) => OrderPlanCategoryRecordModelSelector) | undefined) { return this.__child(`category`, OrderPlanCategoryRecordModelSelector, builder) }
  tips(builder: string | FilterSectionModelTipsFieldModelSelector | ((selector: FilterSectionModelTipsFieldModelSelector) => FilterSectionModelTipsFieldModelSelector) | undefined) { return this.__child(`tips`, FilterSectionModelTipsFieldModelSelector, builder) }
  values(builder: string | FilterValueRecordModelSelector | ((selector: FilterValueRecordModelSelector) => FilterValueRecordModelSelector) | undefined) { return this.__child(`values`, FilterValueRecordModelSelector, builder) }
}
export function selectFromFilterSectionRecord() {
  return new FilterSectionRecordModelSelector()
}

export const filterSectionRecordModelPrimitives = selectFromFilterSectionRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._status._unpublishingScheduledAt._updatedAt.createdAt.displayType.filterType.key.name.updatedAt
