import _ from 'lodash';
import { randomId, roundTotal } from '../../utils/helpers';
import { SubscriptionEntityModelType } from '../api';

export const parseDraftOrderFromSubscription = (
  subscription: SubscriptionEntityModelType
) => {
  const _id = randomId(48);
  const delivery = subscription.delivery ?? {};
  const orderPlans = subscription.plans;
  const total = _.sumBy(orderPlans, 'netPrice');
  const shippingTotal = subscription.totals?.shippingTotal || 0;
  const shippingPromotionTotal = subscription.totals?.shippingPromotionTotal || 0;
  const orderPromotionTotal = subscription.totals?.orderPromotionTotal || 0;
  const shippingFees = shippingTotal - shippingPromotionTotal;

  const orderTotal = roundTotal(
    total + shippingTotal - orderPromotionTotal - shippingPromotionTotal,
  );
  const taxTotal = roundTotal(orderTotal / 11);

  const totals = {
    orderTotal: orderTotal,
    itemTotal: total,
    totalPaid: total + shippingFees,
    shippingTotal: shippingTotal,
    shippingPromotionTotal: shippingPromotionTotal,
    orderPromotionTotal: orderPromotionTotal,
    taxTotal: taxTotal,
  };
  return {
    _id,
    orderPlans,
    delivery,
    totals,
  };
};
