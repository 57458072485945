// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { QueryBuilder } from "mst-gql"
import { UpsellCustomerAttributeRuleRecordModelType } from "./UpsellCustomerAttributeRuleRecordModel"
import { UpsellCustomerAttributeRuleRecordModelSelector, upsellCustomerAttributeRuleRecordModelPrimitives } from "./UpsellCustomerAttributeRuleRecordModel.base"
import { UpsellMealPlanRuleRecordModelType } from "./UpsellMealPlanRuleRecordModel"
import { UpsellMealPlanRuleRecordModelSelector, upsellMealPlanRuleRecordModelPrimitives } from "./UpsellMealPlanRuleRecordModel.base"
import { UpsellMenuHierarchyRuleRecordModelType } from "./UpsellMenuHierarchyRuleRecordModel"
import { UpsellMenuHierarchyRuleRecordModelSelector, upsellMenuHierarchyRuleRecordModelPrimitives } from "./UpsellMenuHierarchyRuleRecordModel.base"
import { UpsellProductRuleRecordModelType } from "./UpsellProductRuleRecordModel"
import { UpsellProductRuleRecordModelSelector, upsellProductRuleRecordModelPrimitives } from "./UpsellProductRuleRecordModel.base"

export type ProductUpsellModelConditionsFieldUnion = UpsellCustomerAttributeRuleRecordModelType | UpsellMealPlanRuleRecordModelType | UpsellMenuHierarchyRuleRecordModelType | UpsellProductRuleRecordModelType

export class ProductUpsellModelConditionsFieldModelSelector extends QueryBuilder {
  upsellCustomerAttributeRuleRecord(builder?: string | UpsellCustomerAttributeRuleRecordModelSelector | ((selector: UpsellCustomerAttributeRuleRecordModelSelector) => UpsellCustomerAttributeRuleRecordModelSelector)) { return this.__inlineFragment(`UpsellCustomerAttributeRuleRecord`, UpsellCustomerAttributeRuleRecordModelSelector, builder) }
  upsellMealPlanRuleRecord(builder?: string | UpsellMealPlanRuleRecordModelSelector | ((selector: UpsellMealPlanRuleRecordModelSelector) => UpsellMealPlanRuleRecordModelSelector)) { return this.__inlineFragment(`UpsellMealPlanRuleRecord`, UpsellMealPlanRuleRecordModelSelector, builder) }
  upsellMenuHierarchyRuleRecord(builder?: string | UpsellMenuHierarchyRuleRecordModelSelector | ((selector: UpsellMenuHierarchyRuleRecordModelSelector) => UpsellMenuHierarchyRuleRecordModelSelector)) { return this.__inlineFragment(`UpsellMenuHierarchyRuleRecord`, UpsellMenuHierarchyRuleRecordModelSelector, builder) }
  upsellProductRuleRecord(builder?: string | UpsellProductRuleRecordModelSelector | ((selector: UpsellProductRuleRecordModelSelector) => UpsellProductRuleRecordModelSelector)) { return this.__inlineFragment(`UpsellProductRuleRecord`, UpsellProductRuleRecordModelSelector, builder) }
}
export function selectFromProductUpsellModelConditionsField() {
  return new ProductUpsellModelConditionsFieldModelSelector()
}

// provides all primitive fields of union member types combined together
export const productUpsellModelConditionsFieldModelPrimitives = selectFromProductUpsellModelConditionsField().upsellCustomerAttributeRuleRecord(upsellCustomerAttributeRuleRecordModelPrimitives).upsellMealPlanRuleRecord(upsellMealPlanRuleRecordModelPrimitives).upsellMenuHierarchyRuleRecord(upsellMenuHierarchyRuleRecordModelPrimitives).upsellProductRuleRecord(upsellProductRuleRecordModelPrimitives)