// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * ZipCheckoutsRtoBase
 * auto generated base class for the model ZipCheckoutsRtoModel.
 */
export const ZipCheckoutsRtoModelBase = ModelBase
  .named('ZipCheckoutsRto')
  .props({
    __typename: types.optional(types.literal("ZipCheckoutsRTO"), "ZipCheckoutsRTO"),
    id: types.union(types.undefined, types.string),
    redirect_uri: types.union(types.undefined, types.string),
    uri: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ZipCheckoutsRtoModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get redirect_uri() { return this.__attr(`redirect_uri`) }
  get uri() { return this.__attr(`uri`) }
}
export function selectFromZipCheckoutsRto() {
  return new ZipCheckoutsRtoModelSelector()
}

export const zipCheckoutsRtoModelPrimitives = selectFromZipCheckoutsRto().redirect_uri.uri
