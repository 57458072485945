// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum CustomerType {
  GUEST="GUEST",
NON_SUB="NON_SUB",
SUB="SUB",
WLP_LITE="WLP_LITE",
WLP_OTHER="WLP_OTHER"
}

/**
* CustomerType
*/
export const CustomerTypeEnumType = types.enumeration("CustomerType", [
        "GUEST",
  "NON_SUB",
  "SUB",
  "WLP_LITE",
  "WLP_OTHER",
      ])
