// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MuxThumbnailFormatType } from "./MuxThumbnailFormatTypeEnum"
import { ImgixParams } from "./RootStore.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { VideoMp4Res } from "./VideoMp4ResEnum"
import { RootStoreType } from "./index"


/**
 * UploadVideoFieldBase
 * auto generated base class for the model UploadVideoFieldModel.
 */
export const UploadVideoFieldModelBase = ModelBase
  .named('UploadVideoField')
  .props({
    __typename: types.optional(types.literal("UploadVideoField"), "UploadVideoField"),
    alt: types.union(types.undefined, types.null, types.string),
    blurUpThumb: types.union(types.undefined, types.null, types.string),
    blurhash: types.union(types.undefined, types.null, types.string),
    duration: types.union(types.undefined, types.null, types.integer),
    framerate: types.union(types.undefined, types.null, types.integer),
    height: types.union(types.undefined, types.frozen()),
    mp4Url: types.union(types.undefined, types.null, types.string),
    muxAssetId: types.union(types.undefined, types.string),
    muxPlaybackId: types.union(types.undefined, types.string),
    streamingUrl: types.union(types.undefined, types.string),
    thumbhash: types.union(types.undefined, types.null, types.string),
    thumbnailUrl: types.union(types.undefined, types.string),
    title: types.union(types.undefined, types.null, types.string),
    width: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class UploadVideoFieldModelSelector extends QueryBuilder {
  get alt() { return this.__attr(`alt`) }
  get blurUpThumb() { return this.__attr(`blurUpThumb`) }
  get blurhash() { return this.__attr(`blurhash`) }
  get duration() { return this.__attr(`duration`) }
  get framerate() { return this.__attr(`framerate`) }
  get height() { return this.__attr(`height`) }
  get mp4Url() { return this.__attr(`mp4Url`) }
  get muxAssetId() { return this.__attr(`muxAssetId`) }
  get muxPlaybackId() { return this.__attr(`muxPlaybackId`) }
  get streamingUrl() { return this.__attr(`streamingUrl`) }
  get thumbhash() { return this.__attr(`thumbhash`) }
  get thumbnailUrl() { return this.__attr(`thumbnailUrl`) }
  get title() { return this.__attr(`title`) }
  get width() { return this.__attr(`width`) }
}
export function selectFromUploadVideoField() {
  return new UploadVideoFieldModelSelector()
}

export const uploadVideoFieldModelPrimitives = selectFromUploadVideoField().alt.blurUpThumb.blurhash.duration.framerate.height.mp4Url.muxAssetId.muxPlaybackId.streamingUrl.thumbhash.thumbnailUrl.title.width
