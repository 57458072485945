// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FileFieldModel, FileFieldModelType } from "./FileFieldModel"
import { FileFieldModelSelector } from "./FileFieldModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { MenuPageSeoModelSeoBlurbFieldModel, MenuPageSeoModelSeoBlurbFieldModelType } from "./MenuPageSeoModelSeoBlurbFieldModel"
import { MenuPageSeoModelSeoBlurbFieldModelSelector } from "./MenuPageSeoModelSeoBlurbFieldModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/**
 * MenuPageSeoRecordBase
 * auto generated base class for the model MenuPageSeoRecordModel.
 *
 * Record of type Menu Page SEO (menu_page_seo)
 */
export const MenuPageSeoRecordModelBase = ModelBase
  .named('MenuPageSeoRecord')
  .props({
    __typename: types.optional(types.literal("MenuPageSeoRecord"), "MenuPageSeoRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    id: types.union(types.undefined, types.frozen()),
    ogImage: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    seoBlurb: types.union(types.undefined, types.null, types.late((): any => MenuPageSeoModelSeoBlurbFieldModel)),
    seoDescription: types.union(types.undefined, types.null, types.string),
    seoTitle: types.union(types.undefined, types.null, types.string),
    slug: types.union(types.undefined, types.null, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MenuPageSeoRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get id() { return this.__attr(`id`) }
  get seoDescription() { return this.__attr(`seoDescription`) }
  get seoTitle() { return this.__attr(`seoTitle`) }
  get slug() { return this.__attr(`slug`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  ogImage(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`ogImage`, FileFieldModelSelector, builder) }
  seoBlurb(builder: string | MenuPageSeoModelSeoBlurbFieldModelSelector | ((selector: MenuPageSeoModelSeoBlurbFieldModelSelector) => MenuPageSeoModelSeoBlurbFieldModelSelector) | undefined) { return this.__child(`seoBlurb`, MenuPageSeoModelSeoBlurbFieldModelSelector, builder) }
}
export function selectFromMenuPageSeoRecord() {
  return new MenuPageSeoRecordModelSelector()
}

export const menuPageSeoRecordModelPrimitives = selectFromMenuPageSeoRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._status._unpublishingScheduledAt._updatedAt.createdAt.seoDescription.seoTitle.slug.updatedAt
