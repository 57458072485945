// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DashboardActionTypeEnumType } from "./DashboardActionTypeEnum"
import { RootStoreType } from "./index"


/**
 * DashboardTargetActionBase
 * auto generated base class for the model DashboardTargetActionModel.
 *
 * Dashboard Target Action
 */
export const DashboardTargetActionModelBase = ModelBase
  .named('DashboardTargetAction')
  .props({
    __typename: types.optional(types.literal("DashboardTargetAction"), "DashboardTargetAction"),
    functionName: types.union(types.undefined, types.null, types.string),
    params: types.union(types.undefined, types.null, types.frozen()),
    root: types.union(types.undefined, types.null, types.string),
    screen: types.union(types.undefined, types.null, types.string),
    type: types.union(types.undefined, DashboardActionTypeEnumType),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DashboardTargetActionModelSelector extends QueryBuilder {
  get functionName() { return this.__attr(`functionName`) }
  get params() { return this.__attr(`params`) }
  get root() { return this.__attr(`root`) }
  get screen() { return this.__attr(`screen`) }
  get type() { return this.__attr(`type`) }
}
export function selectFromDashboardTargetAction() {
  return new DashboardTargetActionModelSelector()
}

export const dashboardTargetActionModelPrimitives = selectFromDashboardTargetAction().functionName.params.root.screen.type
