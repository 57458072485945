// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AccordionSectionRecordModel, AccordionSectionRecordModelType } from "./AccordionSectionRecordModel"
import { AccordionSectionRecordModelSelector } from "./AccordionSectionRecordModel.base"
import { CountdownSectionRecordModel, CountdownSectionRecordModelType } from "./CountdownSectionRecordModel"
import { CountdownSectionRecordModelSelector } from "./CountdownSectionRecordModel.base"
import { FileFieldModel, FileFieldModelType } from "./FileFieldModel"
import { FileFieldModelSelector } from "./FileFieldModel.base"
import { GeneralCampaignSectionRecordModel, GeneralCampaignSectionRecordModelType } from "./GeneralCampaignSectionRecordModel"
import { GeneralCampaignSectionRecordModelSelector } from "./GeneralCampaignSectionRecordModel.base"
import { GeneralDeliveryBannerSectionRecordModel, GeneralDeliveryBannerSectionRecordModelType } from "./GeneralDeliveryBannerSectionRecordModel"
import { GeneralDeliveryBannerSectionRecordModelSelector } from "./GeneralDeliveryBannerSectionRecordModel.base"
import { GeneralHowItWorksSectionRecordModel, GeneralHowItWorksSectionRecordModelType } from "./GeneralHowItWorksSectionRecordModel"
import { GeneralHowItWorksSectionRecordModelSelector } from "./GeneralHowItWorksSectionRecordModel.base"
import { GeneralNutritionalTestimonialRecordModel, GeneralNutritionalTestimonialRecordModelType } from "./GeneralNutritionalTestimonialRecordModel"
import { GeneralNutritionalTestimonialRecordModelSelector } from "./GeneralNutritionalTestimonialRecordModel.base"
import { GeneralPageLearnMoreRecordModel, GeneralPageLearnMoreRecordModelType } from "./GeneralPageLearnMoreRecordModel"
import { GeneralPageLearnMoreRecordModelSelector } from "./GeneralPageLearnMoreRecordModel.base"
import { GeneralPlanSectionRecordModel, GeneralPlanSectionRecordModelType } from "./GeneralPlanSectionRecordModel"
import { GeneralPlanSectionRecordModelSelector } from "./GeneralPlanSectionRecordModel.base"
import { GeneralReviewSectionRecordModel, GeneralReviewSectionRecordModelType } from "./GeneralReviewSectionRecordModel"
import { GeneralReviewSectionRecordModelSelector } from "./GeneralReviewSectionRecordModel.base"
import { GeneralStockistSectionRecordModel, GeneralStockistSectionRecordModelType } from "./GeneralStockistSectionRecordModel"
import { GeneralStockistSectionRecordModelSelector } from "./GeneralStockistSectionRecordModel.base"
import { HomePageHeaderSectionRecordModel, HomePageHeaderSectionRecordModelType } from "./HomePageHeaderSectionRecordModel"
import { HomePageHeaderSectionRecordModelSelector } from "./HomePageHeaderSectionRecordModel.base"
import { ImageSectionRecordModel, ImageSectionRecordModelType } from "./ImageSectionRecordModel"
import { ImageSectionRecordModelSelector } from "./ImageSectionRecordModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { LandingPageBenefitsSectionRecordModel, LandingPageBenefitsSectionRecordModelType } from "./LandingPageBenefitsSectionRecordModel"
import { LandingPageBenefitsSectionRecordModelSelector } from "./LandingPageBenefitsSectionRecordModel.base"
import { LandingPageBundleSectionRecordModel, LandingPageBundleSectionRecordModelType } from "./LandingPageBundleSectionRecordModel"
import { LandingPageBundleSectionRecordModelSelector } from "./LandingPageBundleSectionRecordModel.base"
import { LandingPageHeaderSectionRecordModel, LandingPageHeaderSectionRecordModelType } from "./LandingPageHeaderSectionRecordModel"
import { LandingPageHeaderSectionRecordModelSelector } from "./LandingPageHeaderSectionRecordModel.base"
import { LandingPageIngredientsSectionRecordModel, LandingPageIngredientsSectionRecordModelType } from "./LandingPageIngredientsSectionRecordModel"
import { LandingPageIngredientsSectionRecordModelSelector } from "./LandingPageIngredientsSectionRecordModel.base"
import { LandingPageProductSectionRecordModel, LandingPageProductSectionRecordModelType } from "./LandingPageProductSectionRecordModel"
import { LandingPageProductSectionRecordModelSelector } from "./LandingPageProductSectionRecordModel.base"
import { MarketingContentApprovalWorkflowStageEnumType } from "./MarketingContentApprovalWorkflowStageEnum"
import { MenuTileRecordModel, MenuTileRecordModelType } from "./MenuTileRecordModel"
import { MenuTileRecordModelSelector } from "./MenuTileRecordModel.base"
import { ProductCategorySectionRecordModel, ProductCategorySectionRecordModelType } from "./ProductCategorySectionRecordModel"
import { ProductCategorySectionRecordModelSelector } from "./ProductCategorySectionRecordModel.base"
import { RetailPageModelSectionsFieldModelSelector } from "./RetailPageModelSectionsFieldModelSelector"
import { RetailPageModelSeoBlurbFieldModel, RetailPageModelSeoBlurbFieldModelType } from "./RetailPageModelSeoBlurbFieldModel"
import { RetailPageModelSeoBlurbFieldModelSelector } from "./RetailPageModelSeoBlurbFieldModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { TileSectionRecordModel, TileSectionRecordModelType } from "./TileSectionRecordModel"
import { TileSectionRecordModelSelector } from "./TileSectionRecordModel.base"
import { RootStoreType } from "./index"


/**
 * RetailPageRecordBase
 * auto generated base class for the model RetailPageRecordModel.
 *
 * Record of type Retail Page (retail_page)
 */
export const RetailPageRecordModelBase = ModelBase
  .named('RetailPageRecord')
  .props({
    __typename: types.optional(types.literal("RetailPageRecord"), "RetailPageRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _stage: types.union(types.undefined, MarketingContentApprovalWorkflowStageEnumType),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    id: types.union(types.undefined, types.frozen()),
    ogImage: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    sections: types.union(types.undefined, types.array(types.union(types.late((): any => AccordionSectionRecordModel), types.late((): any => CountdownSectionRecordModel), types.late((): any => GeneralCampaignSectionRecordModel), types.late((): any => GeneralDeliveryBannerSectionRecordModel), types.late((): any => GeneralHowItWorksSectionRecordModel), types.late((): any => GeneralNutritionalTestimonialRecordModel), types.late((): any => GeneralPageLearnMoreRecordModel), types.late((): any => GeneralPlanSectionRecordModel), types.late((): any => GeneralReviewSectionRecordModel), types.late((): any => GeneralStockistSectionRecordModel), types.late((): any => HomePageHeaderSectionRecordModel), types.late((): any => ImageSectionRecordModel), types.late((): any => LandingPageBenefitsSectionRecordModel), types.late((): any => LandingPageBundleSectionRecordModel), types.late((): any => LandingPageHeaderSectionRecordModel), types.late((): any => LandingPageIngredientsSectionRecordModel), types.late((): any => LandingPageProductSectionRecordModel), types.late((): any => MenuTileRecordModel), types.late((): any => ProductCategorySectionRecordModel), types.late((): any => TileSectionRecordModel)))),
    seoBlurb: types.union(types.undefined, types.null, types.late((): any => RetailPageModelSeoBlurbFieldModel)),
    seoDescription: types.union(types.undefined, types.null, types.string),
    seoTitle: types.union(types.undefined, types.null, types.string),
    slug: types.union(types.undefined, types.null, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class RetailPageRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _stage() { return this.__attr(`_stage`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get id() { return this.__attr(`id`) }
  get seoDescription() { return this.__attr(`seoDescription`) }
  get seoTitle() { return this.__attr(`seoTitle`) }
  get slug() { return this.__attr(`slug`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  ogImage(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`ogImage`, FileFieldModelSelector, builder) }
  sections(builder: string | RetailPageModelSectionsFieldModelSelector | ((selector: RetailPageModelSectionsFieldModelSelector) => RetailPageModelSectionsFieldModelSelector) | undefined) { return this.__child(`sections`, RetailPageModelSectionsFieldModelSelector, builder) }
  seoBlurb(builder: string | RetailPageModelSeoBlurbFieldModelSelector | ((selector: RetailPageModelSeoBlurbFieldModelSelector) => RetailPageModelSeoBlurbFieldModelSelector) | undefined) { return this.__child(`seoBlurb`, RetailPageModelSeoBlurbFieldModelSelector, builder) }
}
export function selectFromRetailPageRecord() {
  return new RetailPageRecordModelSelector()
}

export const retailPageRecordModelPrimitives = selectFromRetailPageRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._stage._status._unpublishingScheduledAt._updatedAt.createdAt.seoDescription.seoTitle.slug.updatedAt
