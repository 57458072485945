// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ContentSubType {
  ACTIVITY="ACTIVITY",
BANNER="BANNER",
CONTACT_US="CONTACT_US",
CORE="CORE",
DATA_VERSION="DATA_VERSION",
DISCOVER="DISCOVER",
FEEDBACK="FEEDBACK",
FOOTER="FOOTER",
LIVE_CHAT="LIVE_CHAT",
MECCA="MECCA",
MENU_BUILDER="MENU_BUILDER",
MOVEMENT_RESULT="MOVEMENT_RESULT",
ORDER="ORDER",
PLANS="PLANS",
PLAN_DETAILS="PLAN_DETAILS",
PRODUCT_CONFIG="PRODUCT_CONFIG",
SUBSCRIPTION_CANCEL="SUBSCRIPTION_CANCEL",
SUGGESTED="SUGGESTED",
USER_CREDIT="USER_CREDIT",
WEEKLY_PLAN="WEEKLY_PLAN",
WELLNESS_RESULT="WELLNESS_RESULT",
WLP="WLP",
WLP_FIXED_FEMALE="WLP_FIXED_FEMALE",
WLP_FIXED_MALE="WLP_FIXED_MALE",
WLP_FULL="WLP_FULL",
WLP_FULL_FEMALE="WLP_FULL_FEMALE",
WLP_FULL_MALE="WLP_FULL_MALE",
WLP_LITE="WLP_LITE",
WLP_TAB="WLP_TAB"
}

/**
* ContentSubType
*/
export const ContentSubTypeEnumType = types.enumeration("ContentSubType", [
        "ACTIVITY",
  "BANNER",
  "CONTACT_US",
  "CORE",
  "DATA_VERSION",
  "DISCOVER",
  "FEEDBACK",
  "FOOTER",
  "LIVE_CHAT",
  "MECCA",
  "MENU_BUILDER",
  "MOVEMENT_RESULT",
  "ORDER",
  "PLANS",
  "PLAN_DETAILS",
  "PRODUCT_CONFIG",
  "SUBSCRIPTION_CANCEL",
  "SUGGESTED",
  "USER_CREDIT",
  "WEEKLY_PLAN",
  "WELLNESS_RESULT",
  "WLP",
  "WLP_FIXED_FEMALE",
  "WLP_FIXED_MALE",
  "WLP_FULL",
  "WLP_FULL_FEMALE",
  "WLP_FULL_MALE",
  "WLP_LITE",
  "WLP_TAB",
      ])
