export const PACK_SELECTOR = `
cms {
  squareImage {
    url
    alt
    title
  }
}
attributes {
  name
  value
}
category
lineItems {
  sku
  qty
  attributes {
    name
    value
  }
}
name
netPrice
subCategory
`;

export const SWAP_CONFIG_SELECTOR = `
  id
  goal
  swapCategory {
  showBulkDiscountBar
    id
    key
    name
    menuHierarchy {
      key
    }
  }
`;

export const TILE_SELECTOR = `
id
key
contentType
blocks {
  key
  actionPath
  backgroundColor
  buttonText
  buttonTheme
  description
  title
  image {
    url
    alt
    title
  }
}
`;
