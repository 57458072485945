// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { ReasonModelDescriptionFieldModel, ReasonModelDescriptionFieldModelType } from "./ReasonModelDescriptionFieldModel"
import { ReasonModelDescriptionFieldModelSelector } from "./ReasonModelDescriptionFieldModel.base"
import { ReasonModelNoteFieldModel, ReasonModelNoteFieldModelType } from "./ReasonModelNoteFieldModel"
import { ReasonModelNoteFieldModelSelector } from "./ReasonModelNoteFieldModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { TextRecordModel, TextRecordModelType } from "./TextRecordModel"
import { TextRecordModelSelector } from "./TextRecordModel.base"
import { RootStoreType } from "./index"


/**
 * ReasonRecordBase
 * auto generated base class for the model ReasonRecordModel.
 *
 * Record of type CancelReason (reason)
 */
export const ReasonRecordModelBase = ModelBase
  .named('ReasonRecord')
  .props({
    __typename: types.optional(types.literal("ReasonRecord"), "ReasonRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    ctaAction: types.union(types.undefined, types.null, types.string),
    ctaTitle: types.union(types.undefined, types.null, types.string),
    description: types.union(types.undefined, types.null, types.late((): any => ReasonModelDescriptionFieldModel)),
    id: types.union(types.undefined, types.frozen()),
    key: types.union(types.undefined, types.null, types.string),
    note: types.union(types.undefined, types.null, types.late((): any => ReasonModelNoteFieldModel)),
    position: types.union(types.undefined, types.null, types.frozen()),
    reasonType: types.union(types.undefined, types.null, types.string),
    subOptions: types.union(types.undefined, types.array(types.late((): any => TextRecordModel))),
    subTitle: types.union(types.undefined, types.null, types.string),
    title: types.union(types.undefined, types.null, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ReasonRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get ctaAction() { return this.__attr(`ctaAction`) }
  get ctaTitle() { return this.__attr(`ctaTitle`) }
  get id() { return this.__attr(`id`) }
  get key() { return this.__attr(`key`) }
  get position() { return this.__attr(`position`) }
  get reasonType() { return this.__attr(`reasonType`) }
  get subTitle() { return this.__attr(`subTitle`) }
  get title() { return this.__attr(`title`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  description(builder: string | ReasonModelDescriptionFieldModelSelector | ((selector: ReasonModelDescriptionFieldModelSelector) => ReasonModelDescriptionFieldModelSelector) | undefined) { return this.__child(`description`, ReasonModelDescriptionFieldModelSelector, builder) }
  note(builder: string | ReasonModelNoteFieldModelSelector | ((selector: ReasonModelNoteFieldModelSelector) => ReasonModelNoteFieldModelSelector) | undefined) { return this.__child(`note`, ReasonModelNoteFieldModelSelector, builder) }
  subOptions(builder: string | TextRecordModelSelector | ((selector: TextRecordModelSelector) => TextRecordModelSelector) | undefined) { return this.__child(`subOptions`, TextRecordModelSelector, builder) }
}
export function selectFromReasonRecord() {
  return new ReasonRecordModelSelector()
}

export const reasonRecordModelPrimitives = selectFromReasonRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._status._unpublishingScheduledAt._updatedAt.createdAt.ctaAction.ctaTitle.key.position.reasonType.subTitle.title.updatedAt
