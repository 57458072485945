// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ImgixParamsFillGradientCs {
  hsl="hsl",
lch="lch",
linear="linear",
oklab="oklab",
srgb="srgb"
}

/**
* ImgixParamsFillGradientCs
*/
export const ImgixParamsFillGradientCsEnumType = types.enumeration("ImgixParamsFillGradientCs", [
        "hsl",
  "lch",
  "linear",
  "oklab",
  "srgb",
      ])
