// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * ProductRecommendationBase
 * auto generated base class for the model ProductRecommendationModel.
 *
 * Product Recommendation
 */
export const ProductRecommendationModelBase = ModelBase
  .named('ProductRecommendation')
  .props({
    __typename: types.optional(types.literal("ProductRecommendation"), "ProductRecommendation"),
    sku: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ProductRecommendationModelSelector extends QueryBuilder {
  get sku() { return this.__attr(`sku`) }
}
export function selectFromProductRecommendation() {
  return new ProductRecommendationModelSelector()
}

export const productRecommendationModelPrimitives = selectFromProductRecommendation().sku
