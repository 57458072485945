import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Modal } from '@/components/common/Modal/Modal';
import type { MessageRtoModelType } from '@/models/api';
import { MessageStatus, MessageType } from '@/models/api';
import { useStore } from '@/models/root-store';

export const Notification: React.FC = observer(() => {
  const store = useStore();
  const { generalStore } = store;
  const { notification } = generalStore;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    toast.onChange((content) => {
      if (content.status === 'removed' && notification?.message) {
        generalStore.removeNotification();
      }
    });
  }, []);

  const DEFAULT_ID = 'default-toast-id';

  const handleToastMessage = (message: MessageRtoModelType) => {
    switch (message.status) {
      case MessageStatus.SUCCESS:
        toast.success(message.message, { toastId: DEFAULT_ID });
        break;
      case MessageStatus.ERROR:
        toast.error(message.message, { toastId: DEFAULT_ID });
        break;
      case MessageStatus.WARNING:
        toast.warn(message.message, { toastId: DEFAULT_ID });
        break;
      case MessageStatus.INFO:
        toast.info(message.message, { toastId: DEFAULT_ID });
        break;
      default:
        toast(message.message, { toastId: DEFAULT_ID });
        break;
    }
  };

  const handleMessage = () => {
    switch (notification.type) {
      case MessageType.TOAST:
        handleToastMessage(notification);
        break;
      case MessageType.DIALOG:
        setIsOpen(true);
        break;
      default:
        handleToastMessage(notification);
        break;
    }
  };

  const onClose = () => {
    setIsOpen(false);
    generalStore.removeNotification();
  };

  useEffect(() => {
    if (notification?.message) {
      handleMessage();
    }
  }, [notification?.uuid]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      headerTitle={notification?.title}
    >
      <div className="p-5">{notification?.message}</div>
    </Modal>
  );
});
