import { Instance, types } from "mobx-state-tree";

export const CtaModel = types.model("CTA").props({
  title: types.union(types.undefined, types.null, types.string),
  action: types.union(types.undefined, types.null, types.string),
});

export const ReasonsModel = types.model("Reasons").props({
  key: types.optional(types.string, ""),
  category: types.optional(types.string, ""),
  title: types.optional(types.string, ""),
  cta: types.optional(CtaModel, {}),
});

export interface Reasons extends Instance<typeof ReasonsModel> {}
