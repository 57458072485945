// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FilterSection1ModelTipsFieldModel, FilterSection1ModelTipsFieldModelType } from "./FilterSection1ModelTipsFieldModel"
import { FilterSection1ModelTipsFieldModelSelector } from "./FilterSection1ModelTipsFieldModel.base"
import { FilterValueRecordModel, FilterValueRecordModelType } from "./FilterValueRecordModel"
import { FilterValueRecordModelSelector } from "./FilterValueRecordModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/**
 * FilterSection1RecordBase
 * auto generated base class for the model FilterSection1RecordModel.
 *
 * Block of type App Filter Section (filter_section1)
 */
export const FilterSection1RecordModelBase = ModelBase
  .named('FilterSection1Record')
  .props({
    __typename: types.optional(types.literal("FilterSection1Record"), "FilterSection1Record"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    defaultValue: types.union(types.undefined, types.null, types.string),
    displayType: types.union(types.undefined, types.null, types.string),
    filterType: types.union(types.undefined, types.null, types.string),
    hideNumber: types.union(types.undefined, types.null, types.frozen()),
    id: types.union(types.undefined, types.frozen()),
    isMulti: types.union(types.undefined, types.null, types.frozen()),
    isSingleColumn: types.union(types.undefined, types.null, types.frozen()),
    key: types.union(types.undefined, types.null, types.string),
    name: types.union(types.undefined, types.null, types.string),
    showMore: types.union(types.undefined, types.null, types.frozen()),
    tips: types.union(types.undefined, types.null, types.late((): any => FilterSection1ModelTipsFieldModel)),
    tipsAsHtml: types.union(types.undefined, types.null, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
    values: types.union(types.undefined, types.array(types.late((): any => FilterValueRecordModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FilterSection1RecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get defaultValue() { return this.__attr(`defaultValue`) }
  get displayType() { return this.__attr(`displayType`) }
  get filterType() { return this.__attr(`filterType`) }
  get hideNumber() { return this.__attr(`hideNumber`) }
  get id() { return this.__attr(`id`) }
  get isMulti() { return this.__attr(`isMulti`) }
  get isSingleColumn() { return this.__attr(`isSingleColumn`) }
  get key() { return this.__attr(`key`) }
  get name() { return this.__attr(`name`) }
  get showMore() { return this.__attr(`showMore`) }
  get tipsAsHtml() { return this.__attr(`tipsAsHtml`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  tips(builder: string | FilterSection1ModelTipsFieldModelSelector | ((selector: FilterSection1ModelTipsFieldModelSelector) => FilterSection1ModelTipsFieldModelSelector) | undefined) { return this.__child(`tips`, FilterSection1ModelTipsFieldModelSelector, builder) }
  values(builder: string | FilterValueRecordModelSelector | ((selector: FilterValueRecordModelSelector) => FilterValueRecordModelSelector) | undefined) { return this.__child(`values`, FilterValueRecordModelSelector, builder) }
}
export function selectFromFilterSection1Record() {
  return new FilterSection1RecordModelSelector()
}

export const filterSection1RecordModelPrimitives = selectFromFilterSection1Record()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._status._unpublishingScheduledAt._updatedAt.createdAt.defaultValue.displayType.filterType.hideNumber.isMulti.isSingleColumn.key.name.showMore.tipsAsHtml.updatedAt
