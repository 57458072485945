// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ImgixParamsFillGradientLinearDirection {
  bottom="bottom",
left="left",
right="right",
top="top"
}

/**
* ImgixParamsFillGradientLinearDirection
*/
export const ImgixParamsFillGradientLinearDirectionEnumType = types.enumeration("ImgixParamsFillGradientLinearDirection", [
        "bottom",
  "left",
  "right",
  "top",
      ])
