// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum OperationStatus {
  ERROR="ERROR",
SUCCESS="SUCCESS"
}

/**
* OperationStatus
*/
export const OperationStatusEnumType = types.enumeration("OperationStatus", [
        "ERROR",
  "SUCCESS",
      ])
