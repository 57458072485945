import { Instance, types } from 'mobx-state-tree';
import { CountryCode, State } from '../api';
import { CartAddressInput } from '../api/RootStore.base';

// address
export enum AddressCategory {
  Residential = 'home',
  Business = 'work',
}

export const AddressModel = types
  .model('Address')
  .props({
    addressId: '',
    method: types.string,
    zone: types.maybe(types.string),
    street: types.string,
    suburb: types.string,
    postcode: types.string,
    state: types.maybe(types.enumeration(Object.values(State))),
    firstName: types.union(types.undefined, types.null, types.string),
    lastName: types.union(types.undefined, types.null, types.string),
    phone: types.optional(types.string, ''),
    businessName: types.union(types.undefined, types.null, types.string),
    instructions: types.union(types.undefined, types.null, types.string),
    country: 'AU',
    preferred: false,
  })
  .views((self) => ({
    get showBusinessName() {
      return self.method === AddressCategory.Business;
    },
    get cartAddressInput(): CartAddressInput {
      return {
        addressId: self.addressId,
        businessName: self.businessName,
        method: self.method,
        country: self.country as CountryCode,
        firstName: self.firstName,
        lastName: self.lastName,
        phone: self.phone,
        postcode: self.postcode,
        state: self.state as State,
        street: self.street,
        suburb: self.suburb,
        instructions: self.instructions,
      };
    },
  }));

export interface Address extends Instance<typeof AddressModel> {}
