const Cart = () => {
  return null;
};

export async function getServerSideProps() {
  return {
    redirect: {
      destination: '/?cartLogin=true',
      permanent: false,
    },
  };
}

export default Cart;
