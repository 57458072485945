// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * MenuTileModelDescriptionFieldBase
 * auto generated base class for the model MenuTileModelDescriptionFieldModel.
 */
export const MenuTileModelDescriptionFieldModelBase = ModelBase
  .named('MenuTileModelDescriptionField')
  .props({
    __typename: types.optional(types.literal("MenuTileModelDescriptionField"), "MenuTileModelDescriptionField"),
    blocks: types.union(types.undefined, types.array(types.string)),
    links: types.union(types.undefined, types.array(types.string)),
    value: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MenuTileModelDescriptionFieldModelSelector extends QueryBuilder {
  get blocks() { return this.__attr(`blocks`) }
  get links() { return this.__attr(`links`) }
  get value() { return this.__attr(`value`) }
}
export function selectFromMenuTileModelDescriptionField() {
  return new MenuTileModelDescriptionFieldModelSelector()
}

export const menuTileModelDescriptionFieldModelPrimitives = selectFromMenuTileModelDescriptionField().blocks.links.value
