// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * SampleImageBase
 * auto generated base class for the model SampleImageModel.
 *
 * Sample Image
 */
export const SampleImageModelBase = ModelBase
  .named('SampleImage')
  .props({
    __typename: types.optional(types.literal("SampleImage"), "SampleImage"),
    link: types.union(types.undefined, types.null, types.string),
    location: types.union(types.undefined, types.string),
    url: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class SampleImageModelSelector extends QueryBuilder {
  get link() { return this.__attr(`link`) }
  get location() { return this.__attr(`location`) }
  get url() { return this.__attr(`url`) }
}
export function selectFromSampleImage() {
  return new SampleImageModelSelector()
}

export const sampleImageModelPrimitives = selectFromSampleImage().link.location.url
