import { observer } from 'mobx-react-lite';
import Image from 'next/legacy/image';
import Link from 'next/link';

import Button from '@/components/common/Button/Button';
import { useMediaQuery } from '@/hooks';
import { useStore } from '@/models/root-store';
import { CHATBOT_VERSION } from '@/utils/constants';

export const Error500: React.FC = observer(() => {
  const {
    generalStore: { setChatOpen },
  } = useStore();
  const isMobile = useMediaQuery('(max-width:1023px)');

  const imageHeight500 = isMobile ? '244' : '280';
  const imagewidth500 = isMobile ? '366' : '420';

  const openMSChat = () => {
    setChatOpen(true);
  };

  const openZendeskChat = () => {
    if ((window as any).zE) {
      (window as any).zE('messenger', 'open');
    }
  };

  const openChat = () => {
    switch (CHATBOT_VERSION) {
      case 'microsoft':
        return openMSChat();
      case 'zendesk':
      default:
        return openZendeskChat();
    }
  };

  return (
    <div>
      <div className="container flex w-full flex-col items-center justify-center px-4 pb-11 md:px-56 lg:grid lg:grid-cols-2 lg:px-16 lg:pb-[229px] lg:pt-[180px] xl:px-[203px] 2xl:px-[283px]">
        <div className="mb-4 mt-8 flex justify-end lg:order-1 lg:my-0">
          <Image
            src="/assets/images/jpg/404-page-image.jpg"
            alt="errorpage"
            height={imageHeight500}
            width={imagewidth500}
            className="rounded-2xl"
          />
        </div>
        <div className="flex flex-col xl:w-[388px] 2xl:w-auto">
          <div className="mb-8 font-interMedium text-2xl leading-[30px] lg:text-4xl lg:leading-9">
            Uh oh spaghetti-o!
            <br /> An error has occurred.
          </div>
          <div className="text-center font-interMedium text-base leading-[22px]">
            <p className="pb-4 text-left lg:text-justify ">
              Our tech team is working on this problem now, and we should be up
              and running again soon. We appreciate your patience.
            </p>

            <div className="mb-8 text-left leading-[22px]">
              <p>In the meantime, you could: </p>
              <ul className="flex list-disc flex-col justify-start pl-5">
                <li>Refresh the page (sometimes this helps)</li>
                <li>Try again in 30 minutes</li>
                <li>
                  <a href="#" onClick={openChat}>
                    <span className="cursor-pointer text-attention">
                      {' '}
                      Get in touch
                    </span>{' '}
                  </a>
                  with us or if urgent, please call us on 1300 364 993 so we can
                  help
                </li>
              </ul>
            </div>
          </div>
          <Link href="/" legacyBehavior>
            <div className="self-center lg:self-start">
              <Button
                theme="primary"
                className="!rounded-32 !px-6 !py-3 !font-interMedium !text-base !leading-4"
              >
                Return Home
              </Button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
});
