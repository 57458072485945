// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum MessageStatus {
  ERROR="ERROR",
INFO="INFO",
SUCCESS="SUCCESS",
WARNING="WARNING"
}

/**
* MessageStatus
*/
export const MessageStatusEnumType = types.enumeration("MessageStatus", [
        "ERROR",
  "INFO",
  "SUCCESS",
  "WARNING",
      ])
