// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ActivityLinkedCollection {
  WEEKLY_PLAN="WEEKLY_PLAN"
}

/**
* ActivityLinkedCollection
*/
export const ActivityLinkedCollectionEnumType = types.enumeration("ActivityLinkedCollection", [
        "WEEKLY_PLAN",
      ])
