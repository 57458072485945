// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { QueryBuilder } from "mst-gql"
import { ProductBenefitSectionRecordModelType } from "./ProductBenefitSectionRecordModel"
import { ProductBenefitSectionRecordModelSelector, productBenefitSectionRecordModelPrimitives } from "./ProductBenefitSectionRecordModel.base"
import { RangeDescriptionSectionRecordModelType } from "./RangeDescriptionSectionRecordModel"
import { RangeDescriptionSectionRecordModelSelector, rangeDescriptionSectionRecordModelPrimitives } from "./RangeDescriptionSectionRecordModel.base"

export type RetailMenuHierarchyModelSectionsFieldUnion = ProductBenefitSectionRecordModelType | RangeDescriptionSectionRecordModelType

export class RetailMenuHierarchyModelSectionsFieldModelSelector extends QueryBuilder {
  productBenefitSectionRecord(builder?: string | ProductBenefitSectionRecordModelSelector | ((selector: ProductBenefitSectionRecordModelSelector) => ProductBenefitSectionRecordModelSelector)) { return this.__inlineFragment(`ProductBenefitSectionRecord`, ProductBenefitSectionRecordModelSelector, builder) }
  rangeDescriptionSectionRecord(builder?: string | RangeDescriptionSectionRecordModelSelector | ((selector: RangeDescriptionSectionRecordModelSelector) => RangeDescriptionSectionRecordModelSelector)) { return this.__inlineFragment(`RangeDescriptionSectionRecord`, RangeDescriptionSectionRecordModelSelector, builder) }
}
export function selectFromRetailMenuHierarchyModelSectionsField() {
  return new RetailMenuHierarchyModelSectionsFieldModelSelector()
}

// provides all primitive fields of union member types combined together
export const retailMenuHierarchyModelSectionsFieldModelPrimitives = selectFromRetailMenuHierarchyModelSectionsField().productBenefitSectionRecord(productBenefitSectionRecordModelPrimitives).rangeDescriptionSectionRecord(rangeDescriptionSectionRecordModelPrimitives)