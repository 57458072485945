// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { QueryBuilder } from "mst-gql"
import { AccordionSectionRecordModelType } from "./AccordionSectionRecordModel"
import { AccordionSectionRecordModelSelector, accordionSectionRecordModelPrimitives } from "./AccordionSectionRecordModel.base"
import { CountdownSectionRecordModelType } from "./CountdownSectionRecordModel"
import { CountdownSectionRecordModelSelector, countdownSectionRecordModelPrimitives } from "./CountdownSectionRecordModel.base"
import { GeneralCampaignSectionRecordModelType } from "./GeneralCampaignSectionRecordModel"
import { GeneralCampaignSectionRecordModelSelector, generalCampaignSectionRecordModelPrimitives } from "./GeneralCampaignSectionRecordModel.base"
import { GeneralDeliveryBannerSectionRecordModelType } from "./GeneralDeliveryBannerSectionRecordModel"
import { GeneralDeliveryBannerSectionRecordModelSelector, generalDeliveryBannerSectionRecordModelPrimitives } from "./GeneralDeliveryBannerSectionRecordModel.base"
import { GeneralHowItWorksSectionRecordModelType } from "./GeneralHowItWorksSectionRecordModel"
import { GeneralHowItWorksSectionRecordModelSelector, generalHowItWorksSectionRecordModelPrimitives } from "./GeneralHowItWorksSectionRecordModel.base"
import { GeneralNutritionalTestimonialRecordModelType } from "./GeneralNutritionalTestimonialRecordModel"
import { GeneralNutritionalTestimonialRecordModelSelector, generalNutritionalTestimonialRecordModelPrimitives } from "./GeneralNutritionalTestimonialRecordModel.base"
import { GeneralPageLearnMoreRecordModelType } from "./GeneralPageLearnMoreRecordModel"
import { GeneralPageLearnMoreRecordModelSelector, generalPageLearnMoreRecordModelPrimitives } from "./GeneralPageLearnMoreRecordModel.base"
import { GeneralPlanSectionRecordModelType } from "./GeneralPlanSectionRecordModel"
import { GeneralPlanSectionRecordModelSelector, generalPlanSectionRecordModelPrimitives } from "./GeneralPlanSectionRecordModel.base"
import { GeneralReviewSectionRecordModelType } from "./GeneralReviewSectionRecordModel"
import { GeneralReviewSectionRecordModelSelector, generalReviewSectionRecordModelPrimitives } from "./GeneralReviewSectionRecordModel.base"
import { GeneralStockistSectionRecordModelType } from "./GeneralStockistSectionRecordModel"
import { GeneralStockistSectionRecordModelSelector, generalStockistSectionRecordModelPrimitives } from "./GeneralStockistSectionRecordModel.base"
import { HomePageHeaderSectionRecordModelType } from "./HomePageHeaderSectionRecordModel"
import { HomePageHeaderSectionRecordModelSelector, homePageHeaderSectionRecordModelPrimitives } from "./HomePageHeaderSectionRecordModel.base"
import { ImageSectionRecordModelType } from "./ImageSectionRecordModel"
import { ImageSectionRecordModelSelector, imageSectionRecordModelPrimitives } from "./ImageSectionRecordModel.base"
import { LandingPageBenefitsSectionRecordModelType } from "./LandingPageBenefitsSectionRecordModel"
import { LandingPageBenefitsSectionRecordModelSelector, landingPageBenefitsSectionRecordModelPrimitives } from "./LandingPageBenefitsSectionRecordModel.base"
import { LandingPageBundleSectionRecordModelType } from "./LandingPageBundleSectionRecordModel"
import { LandingPageBundleSectionRecordModelSelector, landingPageBundleSectionRecordModelPrimitives } from "./LandingPageBundleSectionRecordModel.base"
import { LandingPageHeaderSectionRecordModelType } from "./LandingPageHeaderSectionRecordModel"
import { LandingPageHeaderSectionRecordModelSelector, landingPageHeaderSectionRecordModelPrimitives } from "./LandingPageHeaderSectionRecordModel.base"
import { LandingPageIngredientsSectionRecordModelType } from "./LandingPageIngredientsSectionRecordModel"
import { LandingPageIngredientsSectionRecordModelSelector, landingPageIngredientsSectionRecordModelPrimitives } from "./LandingPageIngredientsSectionRecordModel.base"
import { LandingPageProductSectionRecordModelType } from "./LandingPageProductSectionRecordModel"
import { LandingPageProductSectionRecordModelSelector, landingPageProductSectionRecordModelPrimitives } from "./LandingPageProductSectionRecordModel.base"
import { MenuTileRecordModelType } from "./MenuTileRecordModel"
import { MenuTileRecordModelSelector, menuTileRecordModelPrimitives } from "./MenuTileRecordModel.base"
import { ProductCategorySectionRecordModelType } from "./ProductCategorySectionRecordModel"
import { ProductCategorySectionRecordModelSelector, productCategorySectionRecordModelPrimitives } from "./ProductCategorySectionRecordModel.base"
import { TileSectionRecordModelType } from "./TileSectionRecordModel"
import { TileSectionRecordModelSelector, tileSectionRecordModelPrimitives } from "./TileSectionRecordModel.base"

export type RetailPageModelSectionsFieldUnion = AccordionSectionRecordModelType | CountdownSectionRecordModelType | GeneralCampaignSectionRecordModelType | GeneralDeliveryBannerSectionRecordModelType | GeneralHowItWorksSectionRecordModelType | GeneralNutritionalTestimonialRecordModelType | GeneralPageLearnMoreRecordModelType | GeneralPlanSectionRecordModelType | GeneralReviewSectionRecordModelType | GeneralStockistSectionRecordModelType | HomePageHeaderSectionRecordModelType | ImageSectionRecordModelType | LandingPageBenefitsSectionRecordModelType | LandingPageBundleSectionRecordModelType | LandingPageHeaderSectionRecordModelType | LandingPageIngredientsSectionRecordModelType | LandingPageProductSectionRecordModelType | MenuTileRecordModelType | ProductCategorySectionRecordModelType | TileSectionRecordModelType

export class RetailPageModelSectionsFieldModelSelector extends QueryBuilder {
  accordionSectionRecord(builder?: string | AccordionSectionRecordModelSelector | ((selector: AccordionSectionRecordModelSelector) => AccordionSectionRecordModelSelector)) { return this.__inlineFragment(`AccordionSectionRecord`, AccordionSectionRecordModelSelector, builder) }
  countdownSectionRecord(builder?: string | CountdownSectionRecordModelSelector | ((selector: CountdownSectionRecordModelSelector) => CountdownSectionRecordModelSelector)) { return this.__inlineFragment(`CountdownSectionRecord`, CountdownSectionRecordModelSelector, builder) }
  generalCampaignSectionRecord(builder?: string | GeneralCampaignSectionRecordModelSelector | ((selector: GeneralCampaignSectionRecordModelSelector) => GeneralCampaignSectionRecordModelSelector)) { return this.__inlineFragment(`GeneralCampaignSectionRecord`, GeneralCampaignSectionRecordModelSelector, builder) }
  generalDeliveryBannerSectionRecord(builder?: string | GeneralDeliveryBannerSectionRecordModelSelector | ((selector: GeneralDeliveryBannerSectionRecordModelSelector) => GeneralDeliveryBannerSectionRecordModelSelector)) { return this.__inlineFragment(`GeneralDeliveryBannerSectionRecord`, GeneralDeliveryBannerSectionRecordModelSelector, builder) }
  generalHowItWorksSectionRecord(builder?: string | GeneralHowItWorksSectionRecordModelSelector | ((selector: GeneralHowItWorksSectionRecordModelSelector) => GeneralHowItWorksSectionRecordModelSelector)) { return this.__inlineFragment(`GeneralHowItWorksSectionRecord`, GeneralHowItWorksSectionRecordModelSelector, builder) }
  generalNutritionalTestimonialRecord(builder?: string | GeneralNutritionalTestimonialRecordModelSelector | ((selector: GeneralNutritionalTestimonialRecordModelSelector) => GeneralNutritionalTestimonialRecordModelSelector)) { return this.__inlineFragment(`GeneralNutritionalTestimonialRecord`, GeneralNutritionalTestimonialRecordModelSelector, builder) }
  generalPageLearnMoreRecord(builder?: string | GeneralPageLearnMoreRecordModelSelector | ((selector: GeneralPageLearnMoreRecordModelSelector) => GeneralPageLearnMoreRecordModelSelector)) { return this.__inlineFragment(`GeneralPageLearnMoreRecord`, GeneralPageLearnMoreRecordModelSelector, builder) }
  generalPlanSectionRecord(builder?: string | GeneralPlanSectionRecordModelSelector | ((selector: GeneralPlanSectionRecordModelSelector) => GeneralPlanSectionRecordModelSelector)) { return this.__inlineFragment(`GeneralPlanSectionRecord`, GeneralPlanSectionRecordModelSelector, builder) }
  generalReviewSectionRecord(builder?: string | GeneralReviewSectionRecordModelSelector | ((selector: GeneralReviewSectionRecordModelSelector) => GeneralReviewSectionRecordModelSelector)) { return this.__inlineFragment(`GeneralReviewSectionRecord`, GeneralReviewSectionRecordModelSelector, builder) }
  generalStockistSectionRecord(builder?: string | GeneralStockistSectionRecordModelSelector | ((selector: GeneralStockistSectionRecordModelSelector) => GeneralStockistSectionRecordModelSelector)) { return this.__inlineFragment(`GeneralStockistSectionRecord`, GeneralStockistSectionRecordModelSelector, builder) }
  homePageHeaderSectionRecord(builder?: string | HomePageHeaderSectionRecordModelSelector | ((selector: HomePageHeaderSectionRecordModelSelector) => HomePageHeaderSectionRecordModelSelector)) { return this.__inlineFragment(`HomePageHeaderSectionRecord`, HomePageHeaderSectionRecordModelSelector, builder) }
  imageSectionRecord(builder?: string | ImageSectionRecordModelSelector | ((selector: ImageSectionRecordModelSelector) => ImageSectionRecordModelSelector)) { return this.__inlineFragment(`ImageSectionRecord`, ImageSectionRecordModelSelector, builder) }
  landingPageBenefitsSectionRecord(builder?: string | LandingPageBenefitsSectionRecordModelSelector | ((selector: LandingPageBenefitsSectionRecordModelSelector) => LandingPageBenefitsSectionRecordModelSelector)) { return this.__inlineFragment(`LandingPageBenefitsSectionRecord`, LandingPageBenefitsSectionRecordModelSelector, builder) }
  landingPageBundleSectionRecord(builder?: string | LandingPageBundleSectionRecordModelSelector | ((selector: LandingPageBundleSectionRecordModelSelector) => LandingPageBundleSectionRecordModelSelector)) { return this.__inlineFragment(`LandingPageBundleSectionRecord`, LandingPageBundleSectionRecordModelSelector, builder) }
  landingPageHeaderSectionRecord(builder?: string | LandingPageHeaderSectionRecordModelSelector | ((selector: LandingPageHeaderSectionRecordModelSelector) => LandingPageHeaderSectionRecordModelSelector)) { return this.__inlineFragment(`LandingPageHeaderSectionRecord`, LandingPageHeaderSectionRecordModelSelector, builder) }
  landingPageIngredientsSectionRecord(builder?: string | LandingPageIngredientsSectionRecordModelSelector | ((selector: LandingPageIngredientsSectionRecordModelSelector) => LandingPageIngredientsSectionRecordModelSelector)) { return this.__inlineFragment(`LandingPageIngredientsSectionRecord`, LandingPageIngredientsSectionRecordModelSelector, builder) }
  landingPageProductSectionRecord(builder?: string | LandingPageProductSectionRecordModelSelector | ((selector: LandingPageProductSectionRecordModelSelector) => LandingPageProductSectionRecordModelSelector)) { return this.__inlineFragment(`LandingPageProductSectionRecord`, LandingPageProductSectionRecordModelSelector, builder) }
  menuTileRecord(builder?: string | MenuTileRecordModelSelector | ((selector: MenuTileRecordModelSelector) => MenuTileRecordModelSelector)) { return this.__inlineFragment(`MenuTileRecord`, MenuTileRecordModelSelector, builder) }
  productCategorySectionRecord(builder?: string | ProductCategorySectionRecordModelSelector | ((selector: ProductCategorySectionRecordModelSelector) => ProductCategorySectionRecordModelSelector)) { return this.__inlineFragment(`ProductCategorySectionRecord`, ProductCategorySectionRecordModelSelector, builder) }
  tileSectionRecord(builder?: string | TileSectionRecordModelSelector | ((selector: TileSectionRecordModelSelector) => TileSectionRecordModelSelector)) { return this.__inlineFragment(`TileSectionRecord`, TileSectionRecordModelSelector, builder) }
}
export function selectFromRetailPageModelSectionsField() {
  return new RetailPageModelSectionsFieldModelSelector()
}

// provides all primitive fields of union member types combined together
export const retailPageModelSectionsFieldModelPrimitives = selectFromRetailPageModelSectionsField().accordionSectionRecord(accordionSectionRecordModelPrimitives).countdownSectionRecord(countdownSectionRecordModelPrimitives).generalCampaignSectionRecord(generalCampaignSectionRecordModelPrimitives).generalDeliveryBannerSectionRecord(generalDeliveryBannerSectionRecordModelPrimitives).generalHowItWorksSectionRecord(generalHowItWorksSectionRecordModelPrimitives).generalNutritionalTestimonialRecord(generalNutritionalTestimonialRecordModelPrimitives).generalPageLearnMoreRecord(generalPageLearnMoreRecordModelPrimitives).generalPlanSectionRecord(generalPlanSectionRecordModelPrimitives).generalReviewSectionRecord(generalReviewSectionRecordModelPrimitives).generalStockistSectionRecord(generalStockistSectionRecordModelPrimitives).homePageHeaderSectionRecord(homePageHeaderSectionRecordModelPrimitives).imageSectionRecord(imageSectionRecordModelPrimitives).landingPageBenefitsSectionRecord(landingPageBenefitsSectionRecordModelPrimitives).landingPageBundleSectionRecord(landingPageBundleSectionRecordModelPrimitives).landingPageHeaderSectionRecord(landingPageHeaderSectionRecordModelPrimitives).landingPageIngredientsSectionRecord(landingPageIngredientsSectionRecordModelPrimitives).landingPageProductSectionRecord(landingPageProductSectionRecordModelPrimitives).menuTileRecord(menuTileRecordModelPrimitives).productCategorySectionRecord(productCategorySectionRecordModelPrimitives).tileSectionRecord(tileSectionRecordModelPrimitives)