import { useAuth } from '@clerk/nextjs';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { PopupButton } from '@typeform/embed-react';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef } from 'react';

import { useTabFocus } from '@/hooks/useTabFocus';
import { useStore } from '@/models/root-store';
import {
  CHATBOT_VERSION,
  CHECK_DATA_VERSION_INTERVAL,
  LoadingState,
} from '@/utils/constants';
import { getClientDetails, isInWorkingHours } from '@/utils/helpers';

import ZendeskChatBot from '../ZendeskChatBot/ZendeskChatBot';

const MSChatBot = dynamic(() => import('../ms-chatbot/MSChatBot'), {
  ssr: false,
});

export const AppLoader: React.FC = observer(() => {
  const {
    customerStore: { isLoggedIn, growthbookCustomerAttributes },
    productStore: { productState },
    cartStore: { syncCart, cart, resetDraftCart, draftCart },
    generalStore: {
      checkDataVersion,
      removeNotification,
      setChatOpen,
      chatOpen,
      setChatReady,
      chatReady,
    },
  } = useStore();
  const hasFocus = useTabFocus();
  const growthbook = useGrowthBook();
  const { isSignedIn } = useAuth();
  const ref = useRef();

  const router = useRouter();
  const { mode } = router.query;
  const cartOpen = router?.query?.cartOpen;

  // reset filters on the below routes
  const routesThatDontResetFilter = ['menu', 'products'];

  const modeDifference = draftCart && draftCart.mode && draftCart.mode !== mode;
  if (router.isReady && !mode && modeDifference) {
    resetDraftCart();
  }

  useEffect(() => {
    if (cartOpen) {
      cart.openCart();
    }
  }, [cartOpen]);

  useEffect(() => {
    if (CHATBOT_VERSION !== 'microsoft') {
      return () => {};
    }
    const handleLivechatReadyEvent = () => {
      setChatReady(true);
    };
    const handleLivechatCloseEvent = () => {
      setChatOpen(false);
    };
    window.addEventListener('lcw:ready', handleLivechatReadyEvent);
    window.addEventListener('lcw:onClose', handleLivechatCloseEvent);
    return () => {
      window.removeEventListener('lcw:ready', handleLivechatReadyEvent);
      window.removeEventListener('lcw:ready', handleLivechatCloseEvent);
      setChatReady(false);
      setChatOpen(false);
    };
  }, []);

  useEffect(() => {
    if (CHATBOT_VERSION !== 'microsoft') {
      return;
    }
    if (chatOpen) {
      if (isInWorkingHours()) {
        if (window.lcw && window.Microsoft && chatReady) {
          window.Microsoft.Omnichannel.LiveChatWidget.SDK.startChat();
        }
      } else {
        // open a modal with a message that chat is not available
        (ref.current as any)?.open();
      }
    } else {
      document.body.style.overflow = '';
    }
  }, [chatOpen, chatReady]);

  useEffect(() => {
    const currentRoute = router.pathname;
    const currentRouteParts = currentRoute.split('/');
    const currentRouteFirstPart = currentRouteParts[1];
    if (!currentRouteFirstPart) {
      return;
    }

    const isRouteThatDoesntResetFilter = routesThatDontResetFilter.includes(
      currentRouteFirstPart,
    );
    if (
      !isRouteThatDoesntResetFilter &&
      cart?.menuBuilderPlan?.selectedItemPosition
    ) {
      cart?.menuBuilderPlan?.selectedItemPosition.setScrollPosition(0);
    }
    removeNotification();
  }, [router.pathname]);

  useEffect(() => {
    if (isLoggedIn && isSignedIn && productState === LoadingState.DONE) {
      syncCart();
    }
  }, [isLoggedIn, isSignedIn, productState]);

  useEffect(() => {
    if (router.pathname !== '/energy-drinks') {
      const chatIcon = document.querySelector('#launcher');
      const notificationIcon = document.querySelector(
        '[title="Number of unread messages"]',
      );
      chatIcon?.classList.remove('hidden');
      notificationIcon?.classList.remove('hidden');
    }
  }, [router.asPath]);

  useEffect(() => {
    if (!growthbook) return;
    const newAttributes = {
      ...growthbook.getAttributes(),
      ...growthbookCustomerAttributes,
      ...getClientDetails(),
    };
    growthbook.setAttributes(newAttributes);
  }, [growthbook, growthbookCustomerAttributes]);

  useEffect(() => {
    if (hasFocus) {
      const interval = setInterval(() => {
        checkDataVersion();
      }, CHECK_DATA_VERSION_INTERVAL);

      return () => {
        clearInterval(interval);
      };
    }

    return () => {};
  }, [hasFocus]);

  return (
    <>
      {CHATBOT_VERSION === 'microsoft' && (
        <>
          {(chatOpen || chatReady) && <MSChatBot />}
          <PopupButton
            // eslint-disable-next-line
            embedRef={ref}
            id={process.env.NEXT_PUBLIC_OFF_CHAT_TYPE_FORM_ID || ''}
            onClose={() => setChatOpen(false)}
            className="hidden"
          />
        </>
      )}
      {CHATBOT_VERSION === 'zendesk' && <ZendeskChatBot />}
      <Script src="https://js.braintreegateway.com/web/3.84.0/js/client.min.js"></Script>
      <Script src="https://js.braintreegateway.com/web/3.84.0/js/apple-pay.min.js"></Script>
    </>
  );
});
