// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * FilterSection1ModelTipsFieldBase
 * auto generated base class for the model FilterSection1ModelTipsFieldModel.
 */
export const FilterSection1ModelTipsFieldModelBase = ModelBase
  .named('FilterSection1ModelTipsField')
  .props({
    __typename: types.optional(types.literal("FilterSection1ModelTipsField"), "FilterSection1ModelTipsField"),
    blocks: types.union(types.undefined, types.array(types.string)),
    links: types.union(types.undefined, types.array(types.string)),
    value: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FilterSection1ModelTipsFieldModelSelector extends QueryBuilder {
  get blocks() { return this.__attr(`blocks`) }
  get links() { return this.__attr(`links`) }
  get value() { return this.__attr(`value`) }
}
export function selectFromFilterSection1ModelTipsField() {
  return new FilterSection1ModelTipsFieldModelSelector()
}

export const filterSection1ModelTipsFieldModelPrimitives = selectFromFilterSection1ModelTipsField().blocks.links.value
