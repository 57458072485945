export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    throw new Error('Invalid token');
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function decodeSFJWTIsb(isb: string) {
  return isb.split('::').reduce((acc, cur) => {
    const [k, v] = cur.split(':');
    if (!k || !v) {
      return acc;
    }
    acc[k] = v;
    return acc;
  }, {} as Record<string, string>);
}
