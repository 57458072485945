// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ImgixParamsFillGradientType {
  linear="linear",
radial="radial"
}

/**
* ImgixParamsFillGradientType
*/
export const ImgixParamsFillGradientTypeEnumType = types.enumeration("ImgixParamsFillGradientType", [
        "linear",
  "radial",
      ])
