import _ from 'lodash';
import dayjs from 'dayjs';

import { OrderCategory, OrderSource } from '@/models/api';
import type { Cart } from '@/models/cart/cart';
import type { Customer } from '@/models/customer/customer';
import { roundTotal } from '@/utils/helpers';

export function formatLineItem(
  item: any,
  planId: string,
  discount: number,
  isWLP: Boolean,
  netPriceOverride?: number
) {
  const netPrice =
    netPriceOverride ??
    (isWLP
      ? item.product.getWLPDiscountedPrice(discount)
      : item.product.getDiscountedPrice(discount));
  const { grossPrice } = item.product;
  const promotionTotal = Number((grossPrice - netPrice).toFixed(2));
  const attributes = _.map(
    item.attributes,
    ({ __typename, ...item }: any) => item
  );

  return {
    product: item.product._id,
    attributes,
    qty: item.qty,
    planId,
    category: item.product.displayCategory,
    name: item.product.name,
    sku: item.product.sku,
    grossPrice,
    netPrice,
    promotionTotal,
    hasSubProducts: item.product.hasSubProducts,
  };
}

export function formatGiftCardItem(item: any) {
  return {
    grossPrice: item.netPrice,
    netPrice: item.netPrice,
    lineitemText: item.lineitemText,
    recipientEmail: item.recipientEmail,
    recipientName: item.recipientName,
    senderName: item.senderName,
  };
}

export function formatOrderPlan(
  plan: any,
  discount: number,
  isSub: boolean = false
) {
  const netPrice = plan.total;

  const grossPrice = _.sumBy(
    plan.lineItems,
    ({ product, qty }: any) => product.grossPrice * qty
  );
  const promotionTotal = grossPrice - netPrice;
  const attributes = _.map(
    plan.attributes,
    ({ __typename, ...item }: any) => item
  );
  const isWlp = plan.isWlpPlan;
  let curPrice = 0;
  const formattedOrderPlan = {
    planId: plan.planId,
    attributes,
    category: plan.category,
    subCategory: plan.subCategory ?? undefined,
    image: plan.image,
    name: plan.name,
    netPrice: Number(netPrice.toFixed(2)),
    lineItems: plan.lineItems.map((item: any, index: number) => {
      const isLast = index === plan.lineItems.length - 1;
      if (isWlp && isLast) {
        return formatLineItem(
          item,
          plan.planId,
          isWlp ? plan.discount : discount,
          isWlp,
          roundTotal(netPrice - curPrice)
        );
      }
      const lineItem = formatLineItem(
        item,
        plan.planId,
        isWlp ? plan.discount : discount,
        isWlp
      );
      curPrice += lineItem.netPrice * lineItem.qty;
      return lineItem;
    }),
  };

  if (isSub) {
    return formattedOrderPlan;
  }
  return {
    grossPrice: Number(grossPrice.toFixed(2)),
    promotionTotal: Number(promotionTotal.toFixed(2)),
    ...formattedOrderPlan,
  };
}

export function formatOrder(customer: Customer, cart: Cart) {
  const { firstName, lastName, email } = customer;
  const {
    subTotal,
    outStandingAmount,
    shippingTotal,
    shippingPromotionTotal,
    itemPromotionTotal,
    discount,
    isWLP,
    orderTotal,
    isGiftItemOnly,
  } = cart;

  // Delivery Info
  let addressId = cart?.delivery?.deliveryAddress?.addressId;
  let suburb = cart.delivery?.deliveryAddress?.suburb;
  let state =
    cart.delivery?.deliveryAddress?.state || cart.billingAddress?.state;
  let street = cart.delivery?.deliveryAddress?.street;
  let postcode = cart.delivery?.deliveryAddress?.postcode;
  const instructions = cart.delivery?.deliveryAddress?.instructions;
  let phone =
    cart.delivery?.deliveryAddress?.phone || cart?.billingAddress?.phone || '';
  const name = `${cart.delivery?.deliveryAddress?.firstName || firstName} ${
    cart.delivery?.deliveryAddress?.lastName || lastName
  }`;
  const zoneCode = cart?.delivery?.deliveryAddress?.zone;
  let country = cart?.delivery?.deliveryAddress?.country;
  let businessName = cart?.delivery?.deliveryAddress?.businessName;

  const today = dayjs(new Date()).format('YYYY-MM-DD');
  if (
    isGiftItemOnly &&
    !cart.delivery?.deliveryAddress &&
    cart.billingAddress
  ) {
    street = cart.billingAddress.street;
    postcode = cart.billingAddress.postcode;
    country = cart.billingAddress.country;
    suburb = cart.billingAddress.suburb;
    // @ts-ignore
    phone = cart.billingAddress.phone;
  }

  // Format gift cards
  const giftCertificates = _.map(cart?.giftCertificates, (item) =>
    formatGiftCardItem(item)
  );

  // Format promotions
  const promotions = _.map(
    cart?.promotions,
    ({ __typename, ...item }: any) => item
  );

  let plans = cart.plans.map((plan) => formatOrderPlan(plan, discount));
  // If there is a menu builder plan with no item
  plans = plans.filter((plan) => plan.netPrice > 0);
  return {
    input: {
      category: isWLP ? OrderCategory.WLP : OrderCategory.DEFAULT,
      frequency: cart?.frequency,
      source: OrderSource.WEB,
      giftCertificates,
      orderPlans: plans,
      delivery: {
        timeSlot: isGiftItemOnly ? '8am - 6pm' : cart?.delivery?.timeSlot,
        deliveryAddress: {
          addressId,
          suburb,
          name,
          state,
          street,
          postcode,
          country,
          zoneCode,
          ...(businessName && { businessName }),
        },
        deliveryDate: isGiftItemOnly ? today : cart?.delivery?.deliveryDate,
        instructions,
      },
      billingAddress: {
        suburb: cart?.billingAddress?.suburb || suburb,
        state: cart?.billingAddress?.state || state,
        street: cart?.billingAddress?.street || street,
        postcode: cart?.billingAddress?.postcode || postcode,
        name,
        country,
        zoneCode,
      },
      customer: {
        firstName,
        lastName,
        email,
        phone,
      },
      payments: {
        ...(cart?.payments?.payment
          ? {
              payment: {
                paymentMethodId: cart?.payments?.payment?.paymentMethodId,
                paymentMethodToken: cart?.payments?.payment?.paymentMethodToken,
                paymentMethodNonce: cart?.payments?.payment?.paymentMethodNonce,
                paymentDeviceData: cart?.payments?.payment?.paymentDeviceData,
                amount: outStandingAmount,
              },
            }
          : {}),
        giftCards: cart?.payments?.giftCards,
        credit: cart?.payments?.credit,
      },
      promotions,
      totals: {
        orderTotal,
        shippingTotal,
        itemTotal: subTotal,
        orderPromotionTotal: itemPromotionTotal,
        shippingPromotionTotal,
      },
    },
  };
}
