// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { BasketDiscountModel, BasketDiscountModelType } from "./BasketDiscountModel"
import { BasketDiscountModelSelector } from "./BasketDiscountModel.base"
import { RootStoreType } from "./index"


/**
 * BasketRtoBase
 * auto generated base class for the model BasketRtoModel.
 */
export const BasketRtoModelBase = ModelBase
  .named('BasketRto')
  .props({
    __typename: types.optional(types.literal("BasketRto"), "BasketRto"),
    credit: types.union(types.undefined, types.number),
    discounts: types.union(types.undefined, types.array(types.late((): any => BasketDiscountModel))),
    itemTotal: types.union(types.undefined, types.number),
    orderTotal: types.union(types.undefined, types.number),
    shippingDiscount: types.union(types.undefined, types.null, types.late((): any => BasketDiscountModel)),
    shippingTotal: types.union(types.undefined, types.number),
    subTotal: types.union(types.undefined, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class BasketRtoModelSelector extends QueryBuilder {
  get credit() { return this.__attr(`credit`) }
  get itemTotal() { return this.__attr(`itemTotal`) }
  get orderTotal() { return this.__attr(`orderTotal`) }
  get shippingTotal() { return this.__attr(`shippingTotal`) }
  get subTotal() { return this.__attr(`subTotal`) }
  discounts(builder: string | BasketDiscountModelSelector | ((selector: BasketDiscountModelSelector) => BasketDiscountModelSelector) | undefined) { return this.__child(`discounts`, BasketDiscountModelSelector, builder) }
  shippingDiscount(builder: string | BasketDiscountModelSelector | ((selector: BasketDiscountModelSelector) => BasketDiscountModelSelector) | undefined) { return this.__child(`shippingDiscount`, BasketDiscountModelSelector, builder) }
}
export function selectFromBasketRto() {
  return new BasketRtoModelSelector()
}

export const basketRtoModelPrimitives = selectFromBasketRto().credit.itemTotal.orderTotal.shippingTotal.subTotal
