// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum SortableCustomerActivityFields {
  createdAt="createdAt",
recordDate="recordDate"
}

/**
* SortableCustomerActivityFields
*/
export const SortableCustomerActivityFieldsEnumType = types.enumeration("SortableCustomerActivityFields", [
        "createdAt",
  "recordDate",
      ])
