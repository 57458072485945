// @ts-nocheck
import * as Sentry from '@sentry/nextjs';
import _ from 'lodash';
import type { Instance, IStateTreeNode, IType } from 'mobx-state-tree';
import { applySnapshot, cast, flow, getSnapshot, types } from 'mobx-state-tree';

import {
  CMS_PRODUCT_SELECTOR,
  PRODUCT_LIKE_SELECTOR,
  PRODUCT_RESTRICTION_LIMITS_SELECTOR,
  PRODUCT_SELECTOR_NEW,
} from '@/graphql/selectors';
import {
  MenuHierarchyRecordModel, MenuHierarchyRecordModelType,
  ProductCategory,
  ProductModelType,
  ProductRestrictionLimitRecordModelType,
  ProductSubcategoryRecordModelType
} from '@/models/api';
import {
  FilterValueRecordModel,
  ProductModel,
  ProductStatus,
  ProductSubCategory,
} from '@/models/api';
import { withRootStore } from '@/models/helpers/with-root-store';
import { DEFAULT_SORT, ProductFilterModel } from '@/models/product/filters';
import { searchStoreModel } from '@/models/product/search';
import {
  createFilterGraphQLQuery,
  createSortGraphQLQuery,
} from '@/utils/api-helpers';
import {
  GQL_QUERY_OPTIONS,
  LoadingState,
  SWAP_CONFIG,
  SwapCategory,
} from '@/utils/constants';
import { getFilterFunction } from '@/utils/helpers';
import { objectParser } from '@/utils/track/format-helpers';
import {
  trackProductListFiltered,
  trackProductListViewed,
} from '@/utils/track/tracker.helper';

import { ProductRestrictionLimitsModel } from './product-restrictions';

export const ProductStoreModel = types
  .model('ProductStore')
  .props({
    products: types.optional(types.array(ProductModel), []),
    productState: types.optional(
      types.enumeration(Object.values(LoadingState)),
      LoadingState.INITIAL,
    ),
    isFiltering: types.optional(types.boolean, false),
    initialFilter: types.optional(types.boolean, false),
    filteredProducts: types.optional(
      types.array(types.late(() => types.reference(ProductModel))),
      [],
    ),
    menuHierarchy: types.maybe(MenuHierarchyRecordModel),
    filters: types.optional(ProductFilterModel, {}),
    rangeSearchResultFilter: types.optional(
      types.array(FilterValueRecordModel),
      [],
    ),
    productCollection: types.optional(types.string, 'default'),
    errorMessage: types.maybe(types.string),
    swapCategory: types.optional(types.string, SwapCategory.DEFAULT),
    swapConfig: types.optional(types.frozen(), SWAP_CONFIG),
    productRestrictionLimits: types.optional(
      types.array(ProductRestrictionLimitsModel),
      [],
    ),
    productRecommendationTitle: types.optional(
      types.string,
      'Others also bought',
    ),
    search: types.optional(searchStoreModel, {}),
  })
  .extend(withRootStore)
  .views((self) => ({
    get productsLookup(): Record<string, ProductModelType> {
      return _.keyBy(self.products, 'sku');
    },
    get filteredProductLookup(): Record<string, ProductModelType> {
      return _.keyBy(self.filteredProducts, 'sku');
    },
    get productsList(): ProductModelType[] {
      return self.filteredProducts;
    },
    get productsFullList(): ProductModelType[] {
      return self.products;
    },
    get productListItemCount(): number {
      return this.productsList.length;
    },
    get productsCount(): number {
      return this.productsList.length;
    },
    get allProductsCount(): number {
      return this.products.length;
    },
    get productsByCategory(): Record<ProductCategory, ProductModelType[]> {
      return _.groupBy(this.productsList, 'displayCategory') as Record<
        ProductCategory,
        ProductModelType[]
      >;
    },
    get productBySubCategory(): Record<string, ProductModelType[]> {
      return _.groupBy(this.productsList, (product: ProductModelType) => {
        return product.cms.displaySubcategory!.map(
          (subCategory: ProductSubcategoryRecordModelType) => subCategory.key,
        );
      }) as Record<ProductCategory, ProductModelType[]>;
    },
    get meals(): ProductModelType[] {
      return this.productsByCategory.MEALS;
    },
    get salads(): ProductModelType[] {
      return this.productsByCategory.SALADS;
    },
    get snacks(): ProductModelType[] {
      return this.productsByCategory.SNACKS;
    },
    get soups(): ProductModelType[] {
      return this.productsByCategory.SOUPS;
    },
    get brekkie(): ProductModelType[] {
      return this.productsByCategory.BREKKIE;
    },
    get drinks(): ProductModelType[] {
      return this.productsByCategory.DRINKS;
    },
    categoryCount(category: ProductCategory): number | undefined {
      return (
        this.productsByCategory[category] &&
        this.productsByCategory[category].length
      );
    },
    subCategoryCount(subCategory: string): number | undefined {
      return (
        this.productBySubCategory[subCategory] &&
        this.productBySubCategory[subCategory]!.length
      );
    },
    findCountByKey(key: string): number {
      // @ts-ignore
      const categoryCount = self.categoryCount(key as ProductCategory);
      if (categoryCount) return categoryCount;
      // @ts-ignore
      const subCategoryCount = self.subCategoryCount(key);
      if (subCategoryCount) return subCategoryCount;
      return 0;
    },
    getRandomProductByCategory(
      category: ProductCategory,
    ): ProductModelType | undefined {
      const products = this.productsByCategory[category];
      return products && products[Math.floor(Math.random() * products.length)];
    },
  }))
  .actions((self) => ({
    setMenuHierarchy(menuHierarchy?: MenuHierarchyRecordModelType) {
      self.menuHierarchy = menuHierarchy;
    },
    setProducts(products: ProductModelType[]) {
      applySnapshot(self.products, products);
    },
    postProductLikes: flow(function* (liked: boolean, sku: string) {
      // Product liked/unliked
      try {
        const variables = { input: { productSku: sku, liked } };
        self.rootStore.customerStore.updateProductLike(sku, liked);
        const { likeProduct: product } =
          yield self.rootStore.api.mutateLikeProduct(
            variables,
            PRODUCT_LIKE_SELECTOR,
          );
        const selfLike = self.productsLookup[sku];
        if (selfLike) {
          selfLike.likes = product.likes;
        }
        // Update customer profile
        yield self.rootStore.customerStore.getCustomerProfile();
        return product.likes;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    setFilterdProducts(products: string[]) {
      applySnapshot(self.filteredProducts, products);
    },
    setProductCollection(collection: string) {
      self.productCollection = collection;
    },
    setRangeSearchResultFilter() {
      const products = self.filteredProducts;
      const filteredValues =
        self.rootStore.generalStore.searchFilter?.sections[0]?.values?.filter(
          (value: {
            key: any;
            data: { value: any };
            value: string | undefined;
          }) => {
            const key = value?.key;
            const filterValue = value?.data?.value;
            if (!products || products.length === 0) {
              // If there are no products, don't keep any filters
              return false;
            }

            const filterFunction = getFilterFunction(key, filterValue);
            if (!filterFunction) {
              // If key is not recognized, don't include this filter
              return false;
            }

            // Keep the filter if at least one product matches it
            return products.some(filterFunction);
          },
        );
      const filteredSnapshots = filteredValues?.map(
        (value: IStateTreeNode<IType<any, unknown, any>>) => getSnapshot(value),
      );
      self.rangeSearchResultFilter = cast(filteredSnapshots);
    },
    getNewFilteredProducts: flow(function* (searchQuery?: string) {
      try {
        const { filters } = self;
        const {
          productWhereInput,
          selectedFilters,
          filterKeyLookup,
          sort,
          showOnlyLiked,
          filterCount,
        } = filters;
        const filterQuery = createFilterGraphQLQuery(
          selectedFilters,
          filterKeyLookup,
        );
        const sortQuery = createSortGraphQLQuery(sort);

        let selectedSkus: string[] = [];

        if (showOnlyLiked) {
          const productLikes =
            self.rootStore.customerStore.customer?.productLikes ?? [];
          selectedSkus = JSON.parse(JSON.stringify(productLikes));
        }
        self.isFiltering = true;
        const response = yield self.rootStore.api.queryProducts(
          {
            where: {
              status: {
                _eq: ProductStatus.ACTIVE,
              },
              productCollection: {
                _eq: self.productCollection,
              },
              ...filterQuery,
              ...productWhereInput,
              ...(showOnlyLiked && { sku: { _in: selectedSkus } }),
            },
            searchText: searchQuery,
            sort: sortQuery,
          },
          'sku',
          GQL_QUERY_OPTIONS,
        ).promise;
        const productSkus = response.products.map(
          (product: { sku: string }) => product.sku,
        );

        // need to remove products that are not within the productLookup before applying snapshot
        const filteredProducts = productSkus.filter(
          (sku: string) => self.productsLookup[sku],
        );

        applySnapshot(self.filteredProducts, filteredProducts);

        try {
          if (sort !== JSON.stringify(DEFAULT_SORT) || filterCount > 0) {
            trackProductListFiltered(
              objectParser(filters),
              objectParser(self.filteredProducts),
              objectParser(filters.sort),
              selectedFilters,
            );
          } else {
            trackProductListViewed(
              objectParser(filters),
              objectParser(self.filteredProducts),
            );
          }
        } catch (error) {
          // ignore error
        }

        self.initialFilter = true;
        self.isFiltering = false;
      } catch (error) {
        console.error('Error getNewFilteredProducts: ', error);
        Sentry.captureException(error);
      }
    }),
    getPlanProducts: flow(function* () {
      try {
        const { filters } = self;
        const { filterKeyLookup, selectedFilters, sort } = filters;
        const query = createFilterGraphQLQuery(
          selectedFilters,
          filterKeyLookup,
        );
        self.isFiltering = true;

        const sortQuery = createSortGraphQLQuery(sort);

        const response = yield self.rootStore.api.queryProducts(
          {
            where: {
              status: {
                _eq: ProductStatus.ACTIVE,
              },
              displaySubCategory: {
                _nin: [
                  ProductSubCategory.WLP_BUNDLES,
                  ProductSubCategory.WLP_SNACKS,
                ],
              },
              ...query,
            },
            sort: sortQuery,
          },
          'sku',
        ).promise;

        const productSkus = response.products.map(
          (product: { sku: string }) => product.sku,
        );
        applySnapshot(self.filteredProducts, productSkus);
        self.initialFilter = true;
        self.isFiltering = false;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    fetchProducts: flow(function* () {
      const {
        filters: { sort },
      } = self;
      const sortQuery = createSortGraphQLQuery(sort);
      try {
        const { products }: { products: ProductModelType[] } =
          yield self.rootStore.api.queryProducts(
            {
              where: {
                status: {
                  _eq: ProductStatus.ACTIVE,
                },
              },
              sort: sortQuery,
            },
            PRODUCT_SELECTOR_NEW,
            GQL_QUERY_OPTIONS,
          ).promise;
        return products;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    fetchProductsCms: flow(function* () {
      try {
        let skip = 0;
        const allProductsCms = [];

        while (true) {
          const result = yield self.rootStore.api.queryAllProductsCms(
            {
              filter: {
                productStatus: {
                  eq: 'active',
                },
              },
              imgixParams: {
                auto: 'format',
              },
              first: 100,
              skip,
            },
            CMS_PRODUCT_SELECTOR,
            GQL_QUERY_OPTIONS,
          ).promise;

          const { allProductsCms: currentProducts } = result;

          if (currentProducts.length > 0) {
            // Add the fetched products to the array
            allProductsCms.push(...currentProducts);

            // Increment the skip value for the next iteration
            skip += 100;
          } else {
            // No more products, exit the loop
            break;
          }
        }

        return allProductsCms;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    getProducts: flow(function* (view: string) {
      try {
        self.productState = LoadingState.LOADING;
        const response = yield fetch('/api/products');

        if (!response.ok) {
          throw new Error(
            `Failed to fetch products. Status: ${response.status}`,
          );
        }
        const products = yield response.json();

        const activeCmsProducts = _.filter(
          products,
          (product) => product.cms,
        ) as ProductModelType[];
        const productLookup = _.keyBy(activeCmsProducts, 'sku');
        const { cart } = self.rootStore.cartStore;
        const { draftCart } = self.rootStore.cartStore;
        cart.removeInactiveItems(productLookup);
        draftCart.removeInactiveItems(productLookup);
        const inactiveFilterProducts = self.filteredProducts
          .map((item) => item.sku)
          .filter((sku) => productLookup[sku]);
        if (inactiveFilterProducts.length !== self.filteredProducts.length) {
          applySnapshot(self.filteredProducts, inactiveFilterProducts);
        }
        applySnapshot(self.products, activeCmsProducts);
        self.productState = LoadingState.DONE;
      } catch (error) {
        console.error('Error getProducts: ', error);
        Sentry.captureException(error);
        self.productState = LoadingState.ERROR;
        self.errorMessage = 'Something went wrong';
      }
    }),
    getProductLikes(sku: string): number | undefined {
      return self.productsLookup[sku]?.likes ?? 0;
    },
    getProductRestrictionLimits: flow(function* () {
      try {
        const {
          allProductRestrictionLimitsCms,
        }: {
          allProductRestrictionLimitsCms: ProductRestrictionLimitRecordModelType[];
        } = yield self.rootStore.api.queryAllProductRestrictionLimitsCms(
          {
            filter: {
              active: {
                eq: true,
              },
            },
          },
          PRODUCT_RESTRICTION_LIMITS_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;

        if (!allProductRestrictionLimitsCms) {
          return;
        }
        const formattedProductRestrictionLimits =
          allProductRestrictionLimitsCms.map(
            (
              productRestrictionLimit: ProductRestrictionLimitRecordModelType,
            ) => {
              return {
                products:
                  productRestrictionLimit?.products?.map(
                    (product: ProductModelType) => product.sku,
                  ) || [],
                quantityLimit: productRestrictionLimit.quantityLimit || 0,
                errorMessage: productRestrictionLimit.errorMessage || '',
              };
            },
          );

        applySnapshot(
          self.productRestrictionLimits,
          formattedProductRestrictionLimits,
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    checkProductsVisible: flow(function* (skus: string[]) {
      if (_.isEmpty(skus)) return false;
      try {
        const { products }: { products: ProductModelType[] } =
          yield self.rootStore.api.queryProducts(
            {
              where: {
                status: {
                  _eq: ProductStatus.ACTIVE,
                },
                sku: {
                  _in: skus,
                },
              },
            },
            'sku',
            GQL_QUERY_OPTIONS,
          );
        const productSkus = _.keyBy(products, 'sku');
        return _.every(skus, (sku) => productSkus.hasOwnProperty(sku));
      } catch (error) {
        Sentry.captureException(error);
        return false;
      }
    }),
    setSwapConfig(config: Record<string, string[]>) {
      self.swapConfig = config;
    },
    stitchData(products: ProductModelType[], productsCms: ProductModelType[]) {
      // using sku in productsCms we want to attach that data minus the sku to products with a attribute cms
      // then return the products array
      const cloneProducts = JSON.parse(JSON.stringify(products));
      productsCms.forEach((product) => {
        const cloneProduct = _.find(cloneProducts, { sku: product.sku });
        if (cloneProduct) {
          cloneProduct.cms = product;
        }
      });
      return cloneProducts;
    },
  }));

export type ProductStoreType = Instance<typeof ProductStoreModel>;
export interface ProductStore extends ProductStoreType {}
