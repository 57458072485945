// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum DeliveryMethod {
  BUSINESS="BUSINESS",
HIGHRISE="HIGHRISE",
PICKUP="PICKUP",
RESIDENTIAL="RESIDENTIAL"
}

/**
* DeliveryMethod
*/
export const DeliveryMethodEnumType = types.enumeration("DeliveryMethod", [
        "BUSINESS",
  "HIGHRISE",
  "PICKUP",
  "RESIDENTIAL",
      ])
