export const SUBSCRIPTION_SELECTOR = `
_id
category
frequency
subId
chargeDate
payment {
  token
}
totals {
  orderPromotionTotal
  shippingTotal
  shippingPromotionTotal
}
currentOrder {
  _id
  invoiceNumber
  orderStatus
  orderPlans {
    name
    category
    image
    planId
    netPrice
    lineItems {
      cms {
        tileImage {
          url
        }
      }
      attributes {
        name
        value
      }
      promotionTotal
      category
      sku
      name
      qty
      netPrice
    }
    attributes {
      name
      value
    }
  }
  delivery {
    deliveryDate
    timeSlot
  }
  totals {
    orderTotal
    shippingTotal
    shippingPromotionTotal
    itemTotal
    taxTotal
    orderPromotionTotal
  }
}
delivery {
  deliveryDate
  deliveryAddress {
    name
    street
    postcode
    suburb
    state
  }
  timeSlot
  trackingNumber
  instructions
}
futures {
  deliveryDate
  status
}
plans {
  name
  planId
  category
  subCategory
  image
  netPrice
  lineItems {
    sku
    qty
    name
    category
    promotionTotal
    netPrice
    cms {
      tileImage {
        url
      }
    }
    attributes {
      name
      value
    }
  }
  attributes {
    name
    value
  }
}
customer {
  email
  firstName
  lastName
  phone
}
billingAddress {
  businessName
  country
  name
  postcode
  state
  street
  suburb
}
status
`;

export const SUBSCRIPTION_DELIVERY_INFO_SELECTOR = `
deliveryDetails {
  date
  formattedDate
  timeslots
}
cms {
  subscriptionInstruction {
    thresholdDescription {
      threshold
    }
  }
}
`;

export const SUBSCRIPTION_DELIVERY_CHECK = `
delivery {
  deliveryAddress {
    country
    name
    postcode
    state
    street
    suburb
    businessName
  }
}
chargeDate
status
category
`;
