// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CustomerTypeEnumType } from "./CustomerTypeEnum"
import { DashboardImagesModel, DashboardImagesModelType } from "./DashboardImagesModel"
import { DashboardImagesModelSelector } from "./DashboardImagesModel.base"
import { DashboardTargetActionModel, DashboardTargetActionModelType } from "./DashboardTargetActionModel"
import { DashboardTargetActionModelSelector } from "./DashboardTargetActionModel.base"
import { RootStoreType } from "./index"


/**
 * DashboardBannerBase
 * auto generated base class for the model DashboardBannerModel.
 */
export const DashboardBannerModelBase = ModelBase
  .named('DashboardBanner')
  .props({
    __typename: types.optional(types.literal("DashboardBanner"), "DashboardBanner"),
    componentData: types.union(types.undefined, types.null, types.frozen()),
    componentName: types.union(types.undefined, types.string),
    customerType: types.union(types.undefined, CustomerTypeEnumType),
    icon: types.union(types.undefined, types.null, types.late((): any => DashboardImagesModel)),
    images: types.union(types.undefined, types.null, types.late((): any => DashboardImagesModel)),
    targetAction: types.union(types.undefined, types.null, types.late((): any => DashboardTargetActionModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DashboardBannerModelSelector extends QueryBuilder {
  get componentData() { return this.__attr(`componentData`) }
  get componentName() { return this.__attr(`componentName`) }
  get customerType() { return this.__attr(`customerType`) }
  icon(builder: string | DashboardImagesModelSelector | ((selector: DashboardImagesModelSelector) => DashboardImagesModelSelector) | undefined) { return this.__child(`icon`, DashboardImagesModelSelector, builder) }
  images(builder: string | DashboardImagesModelSelector | ((selector: DashboardImagesModelSelector) => DashboardImagesModelSelector) | undefined) { return this.__child(`images`, DashboardImagesModelSelector, builder) }
  targetAction(builder: string | DashboardTargetActionModelSelector | ((selector: DashboardTargetActionModelSelector) => DashboardTargetActionModelSelector) | undefined) { return this.__child(`targetAction`, DashboardTargetActionModelSelector, builder) }
}
export function selectFromDashboardBanner() {
  return new DashboardBannerModelSelector()
}

export const dashboardBannerModelPrimitives = selectFromDashboardBanner().componentData.componentName.customerType
