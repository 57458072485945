// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MessageRtoModel, MessageRtoModelType } from "./MessageRtoModel"
import { MessageRtoModelSelector } from "./MessageRtoModel.base"
import { OperationStatusEnumType } from "./OperationStatusEnum"
import { OrderModel, OrderModelType } from "./OrderModel"
import { OrderModelSelector } from "./OrderModel.base"
import { RootStoreType } from "./index"


/**
 * UpdateOrderResponseBase
 * auto generated base class for the model UpdateOrderResponseModel.
 *
 * Order Delivery Info Response
 */
export const UpdateOrderResponseModelBase = ModelBase
  .named('UpdateOrderResponse')
  .props({
    __typename: types.optional(types.literal("UpdateOrderResponse"), "UpdateOrderResponse"),
    message: types.union(types.undefined, types.late((): any => MessageRtoModel)),
    order: types.union(types.undefined, types.late((): any => OrderModel)),
    status: types.union(types.undefined, OperationStatusEnumType),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class UpdateOrderResponseModelSelector extends QueryBuilder {
  get status() { return this.__attr(`status`) }
  message(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined) { return this.__child(`message`, MessageRtoModelSelector, builder) }
  order(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined) { return this.__child(`order`, OrderModelSelector, builder) }
}
export function selectFromUpdateOrderResponse() {
  return new UpdateOrderResponseModelSelector()
}

export const updateOrderResponseModelPrimitives = selectFromUpdateOrderResponse().status
