// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * CartGiftCertificateBase
 * auto generated base class for the model CartGiftCertificateModel.
 *
 * Cart Gift Card
 */
export const CartGiftCertificateModelBase = ModelBase
  .named('CartGiftCertificate')
  .props({
    __typename: types.optional(types.literal("CartGiftCertificate"), "CartGiftCertificate"),
    lineitemText: types.union(types.undefined, types.null, types.string),
    netPrice: types.union(types.undefined, types.number),
    recipientEmail: types.union(types.undefined, types.string),
    recipientName: types.union(types.undefined, types.string),
    senderName: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CartGiftCertificateModelSelector extends QueryBuilder {
  get lineitemText() { return this.__attr(`lineitemText`) }
  get netPrice() { return this.__attr(`netPrice`) }
  get recipientEmail() { return this.__attr(`recipientEmail`) }
  get recipientName() { return this.__attr(`recipientName`) }
  get senderName() { return this.__attr(`senderName`) }
}
export function selectFromCartGiftCertificate() {
  return new CartGiftCertificateModelSelector()
}

export const cartGiftCertificateModelPrimitives = selectFromCartGiftCertificate().lineitemText.netPrice.recipientEmail.recipientName.senderName
