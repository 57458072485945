// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ColorFieldModel, ColorFieldModelType } from "./ColorFieldModel"
import { ColorFieldModelSelector } from "./ColorFieldModel.base"
import { FileFieldModel, FileFieldModelType } from "./FileFieldModel"
import { FileFieldModelSelector } from "./FileFieldModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { NavbarBannerRecordModel, NavbarBannerRecordModelType } from "./NavbarBannerRecordModel"
import { NavbarBannerRecordModelSelector } from "./NavbarBannerRecordModel.base"
import { NavbarLinkExtraRecordModel, NavbarLinkExtraRecordModelType } from "./NavbarLinkExtraRecordModel"
import { NavbarLinkExtraRecordModelSelector } from "./NavbarLinkExtraRecordModel.base"
import { NavbarSubLinkRecordModel, NavbarSubLinkRecordModelType } from "./NavbarSubLinkRecordModel"
import { NavbarSubLinkRecordModelSelector } from "./NavbarSubLinkRecordModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/**
 * NavbarLinkRecordBase
 * auto generated base class for the model NavbarLinkRecordModel.
 *
 * Block of type Navbar Link (navbar_link)
 */
export const NavbarLinkRecordModelBase = ModelBase
  .named('NavbarLinkRecord')
  .props({
    __typename: types.optional(types.literal("NavbarLinkRecord"), "NavbarLinkRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    accessibilityText: types.union(types.undefined, types.null, types.string),
    backgroundColour: types.union(types.undefined, types.null, types.late((): any => ColorFieldModel)),
    category: types.union(types.undefined, types.null, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    discoveryBanner: types.union(types.undefined, types.null, types.late((): any => NavbarBannerRecordModel)),
    externalUrl: types.union(types.undefined, types.null, types.string),
    extraLabel: types.union(types.undefined, types.null, types.string),
    extraLinks: types.union(types.undefined, types.array(types.late((): any => NavbarLinkExtraRecordModel))),
    fullMenu: types.union(types.undefined, types.null, types.frozen()),
    icon: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    id: types.union(types.undefined, types.frozen()),
    linkType: types.union(types.undefined, types.null, types.string),
    mobileOnly: types.union(types.undefined, types.null, types.frozen()),
    relativePathLink: types.union(types.undefined, types.null, types.string),
    subLinks: types.union(types.undefined, types.array(types.late((): any => NavbarSubLinkRecordModel))),
    text: types.union(types.undefined, types.null, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class NavbarLinkRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get accessibilityText() { return this.__attr(`accessibilityText`) }
  get category() { return this.__attr(`category`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get externalUrl() { return this.__attr(`externalUrl`) }
  get extraLabel() { return this.__attr(`extraLabel`) }
  get fullMenu() { return this.__attr(`fullMenu`) }
  get id() { return this.__attr(`id`) }
  get linkType() { return this.__attr(`linkType`) }
  get mobileOnly() { return this.__attr(`mobileOnly`) }
  get relativePathLink() { return this.__attr(`relativePathLink`) }
  get text() { return this.__attr(`text`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  backgroundColour(builder: string | ColorFieldModelSelector | ((selector: ColorFieldModelSelector) => ColorFieldModelSelector) | undefined) { return this.__child(`backgroundColour`, ColorFieldModelSelector, builder) }
  discoveryBanner(builder: string | NavbarBannerRecordModelSelector | ((selector: NavbarBannerRecordModelSelector) => NavbarBannerRecordModelSelector) | undefined) { return this.__child(`discoveryBanner`, NavbarBannerRecordModelSelector, builder) }
  extraLinks(builder: string | NavbarLinkExtraRecordModelSelector | ((selector: NavbarLinkExtraRecordModelSelector) => NavbarLinkExtraRecordModelSelector) | undefined) { return this.__child(`extraLinks`, NavbarLinkExtraRecordModelSelector, builder) }
  icon(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`icon`, FileFieldModelSelector, builder) }
  subLinks(builder: string | NavbarSubLinkRecordModelSelector | ((selector: NavbarSubLinkRecordModelSelector) => NavbarSubLinkRecordModelSelector) | undefined) { return this.__child(`subLinks`, NavbarSubLinkRecordModelSelector, builder) }
}
export function selectFromNavbarLinkRecord() {
  return new NavbarLinkRecordModelSelector()
}

export const navbarLinkRecordModelPrimitives = selectFromNavbarLinkRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._status._unpublishingScheduledAt._updatedAt.accessibilityText.category.createdAt.externalUrl.extraLabel.fullMenu.linkType.mobileOnly.relativePathLink.text.updatedAt
