// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DashboardMetaModel, DashboardMetaModelType } from "./DashboardMetaModel"
import { DashboardMetaModelSelector } from "./DashboardMetaModel.base"
import { RootStoreType } from "./index"


/**
 * DashboardBase
 * auto generated base class for the model DashboardModel.
 */
export const DashboardModelBase = ModelBase
  .named('Dashboard')
  .props({
    __typename: types.optional(types.literal("Dashboard"), "Dashboard"),
    activity: types.union(types.undefined, types.array(types.late((): any => DashboardMetaModel))),
    discover: types.union(types.undefined, types.array(types.late((): any => DashboardMetaModel))),
    footer: types.union(types.undefined, types.array(types.late((): any => DashboardMetaModel))),
    suggested: types.union(types.undefined, types.array(types.late((): any => DashboardMetaModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DashboardModelSelector extends QueryBuilder {
  activity(builder: string | DashboardMetaModelSelector | ((selector: DashboardMetaModelSelector) => DashboardMetaModelSelector) | undefined) { return this.__child(`activity`, DashboardMetaModelSelector, builder) }
  discover(builder: string | DashboardMetaModelSelector | ((selector: DashboardMetaModelSelector) => DashboardMetaModelSelector) | undefined) { return this.__child(`discover`, DashboardMetaModelSelector, builder) }
  footer(builder: string | DashboardMetaModelSelector | ((selector: DashboardMetaModelSelector) => DashboardMetaModelSelector) | undefined) { return this.__child(`footer`, DashboardMetaModelSelector, builder) }
  suggested(builder: string | DashboardMetaModelSelector | ((selector: DashboardMetaModelSelector) => DashboardMetaModelSelector) | undefined) { return this.__child(`suggested`, DashboardMetaModelSelector, builder) }
}
export function selectFromDashboard() {
  return new DashboardModelSelector()
}

export const dashboardModelPrimitives = selectFromDashboard()
