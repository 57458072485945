// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum Platform {
  ANDROID="ANDROID",
IOS="IOS"
}

/**
* Platform
*/
export const PlatformEnumType = types.enumeration("Platform", [
        "ANDROID",
  "IOS",
      ])
