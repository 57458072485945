// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AttributeModel, AttributeModelType } from "./AttributeModel"
import { AttributeModelSelector } from "./AttributeModel.base"
import { CustomerModel, CustomerModelType } from "./CustomerModel"
import { CustomerModelSelector } from "./CustomerModel.base"
import { DailyOrderModel, DailyOrderModelType } from "./DailyOrderModel"
import { DailyOrderModelSelector } from "./DailyOrderModel.base"
import { OrderModel, OrderModelType } from "./OrderModel"
import { OrderModelSelector } from "./OrderModel.base"
import { PackCategoryEnumType } from "./PackCategoryEnum"
import { RootStoreType } from "./index"


/**
 * WeeklyPlanRtoBase
 * auto generated base class for the model WeeklyPlanRtoModel.
 */
export const WeeklyPlanRtoModelBase = ModelBase
  .named('WeeklyPlanRto')
  .props({
    __typename: types.optional(types.literal("WeeklyPlanRTO"), "WeeklyPlanRTO"),
    _id: types.union(types.undefined, types.string),
    archived: types.union(types.undefined, types.boolean),
    attributes: types.union(types.undefined, types.null, types.array(types.late((): any => AttributeModel))),
    customer: types.union(types.undefined, types.late((): any => CustomerModel)),
    days: types.union(types.undefined, types.array(types.late((): any => DailyOrderModel))),
    endDate: types.union(types.undefined, types.frozen()),
    name: types.union(types.undefined, types.null, types.string),
    order: types.union(types.undefined, types.late((): any => OrderModel)),
    planId: types.union(types.undefined, types.null, types.string),
    startDate: types.union(types.undefined, types.frozen()),
    startDateRange: types.union(types.undefined, types.array(types.string)),
    type: types.union(types.undefined, PackCategoryEnumType),
    weight: types.union(types.undefined, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class WeeklyPlanRtoModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get archived() { return this.__attr(`archived`) }
  get endDate() { return this.__attr(`endDate`) }
  get name() { return this.__attr(`name`) }
  get planId() { return this.__attr(`planId`) }
  get startDate() { return this.__attr(`startDate`) }
  get startDateRange() { return this.__attr(`startDateRange`) }
  get type() { return this.__attr(`type`) }
  get weight() { return this.__attr(`weight`) }
  attributes(builder: string | AttributeModelSelector | ((selector: AttributeModelSelector) => AttributeModelSelector) | undefined) { return this.__child(`attributes`, AttributeModelSelector, builder) }
  customer(builder: string | CustomerModelSelector | ((selector: CustomerModelSelector) => CustomerModelSelector) | undefined) { return this.__child(`customer`, CustomerModelSelector, builder) }
  days(builder: string | DailyOrderModelSelector | ((selector: DailyOrderModelSelector) => DailyOrderModelSelector) | undefined) { return this.__child(`days`, DailyOrderModelSelector, builder) }
  order(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined) { return this.__child(`order`, OrderModelSelector, builder) }
}
export function selectFromWeeklyPlanRto() {
  return new WeeklyPlanRtoModelSelector()
}

export const weeklyPlanRtoModelPrimitives = selectFromWeeklyPlanRto()._id.archived.endDate.name.planId.startDate.startDateRange.type.weight
