// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ColorFieldModel, ColorFieldModelType } from "./ColorFieldModel"
import { ColorFieldModelSelector } from "./ColorFieldModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { RangeDescriptionSectionModelDescriptionFieldModel, RangeDescriptionSectionModelDescriptionFieldModelType } from "./RangeDescriptionSectionModelDescriptionFieldModel"
import { RangeDescriptionSectionModelDescriptionFieldModelSelector } from "./RangeDescriptionSectionModelDescriptionFieldModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/**
 * RangeDescriptionSectionRecordBase
 * auto generated base class for the model RangeDescriptionSectionRecordModel.
 *
 * Block of type Range Description Section (range_description_section)
 */
export const RangeDescriptionSectionRecordModelBase = ModelBase
  .named('RangeDescriptionSectionRecord')
  .props({
    __typename: types.optional(types.literal("RangeDescriptionSectionRecord"), "RangeDescriptionSectionRecord"),
    // _createdAt: types.union(types.undefined, types.frozen()),
    // /** Editing URL */
    // _editingUrl: types.union(types.undefined, types.null, types.string),
    // _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    // _isValid: types.union(types.undefined, types.frozen()),
    // _modelApiKey: types.union(types.undefined, types.string),
    // _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    // _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    // /** Generates SEO and Social card meta tags to be used in your frontend */
    // _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    // _status: types.union(types.undefined, ItemStatusEnumType),
    // _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    // _updatedAt: types.union(types.undefined, types.frozen()),
    buttonText: types.union(types.undefined, types.null, types.string),
    buttonLink: types.union(types.undefined, types.null, types.string),
    // createdAt: types.union(types.undefined, types.frozen()),
    description: types.union(types.undefined, types.null, types.late((): any => RangeDescriptionSectionModelDescriptionFieldModel)),
    // id: types.union(types.undefined, types.frozen()),
    title: types.union(types.undefined, types.null, types.string),
    titleBackgroundColor: types.union(types.undefined, types.null, types.late((): any => ColorFieldModel)),
    // updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class RangeDescriptionSectionRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get buttonText() { return this.__attr(`buttonText`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get id() { return this.__attr(`id`) }
  get title() { return this.__attr(`title`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  description(builder: string | RangeDescriptionSectionModelDescriptionFieldModelSelector | ((selector: RangeDescriptionSectionModelDescriptionFieldModelSelector) => RangeDescriptionSectionModelDescriptionFieldModelSelector) | undefined) { return this.__child(`description`, RangeDescriptionSectionModelDescriptionFieldModelSelector, builder) }
  titleBackgroundColor(builder: string | ColorFieldModelSelector | ((selector: ColorFieldModelSelector) => ColorFieldModelSelector) | undefined) { return this.__child(`titleBackgroundColor`, ColorFieldModelSelector, builder) }
}
export function selectFromRangeDescriptionSectionRecord() {
  return new RangeDescriptionSectionRecordModelSelector()
}

export const rangeDescriptionSectionRecordModelPrimitives = selectFromRangeDescriptionSectionRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._status._unpublishingScheduledAt._updatedAt.buttonText.createdAt.title.updatedAt
