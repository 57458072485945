import { useEffect, useState } from 'react';

const EVENT_NAME = 'visibilitychange';

const isVisible = () =>
  typeof document !== 'undefined' && document.visibilityState === 'visible';

export const useTabFocus = () => {
  const [hasFocus, setHasFocus] = useState(isVisible());

  useEffect(() => {
    if (typeof document === 'undefined') {
      return () => {};
    }
    const onVisibilityChange = () => {
      setHasFocus(isVisible());
    };

    document.addEventListener(EVENT_NAME, onVisibilityChange);

    return () => {
      document.removeEventListener(EVENT_NAME, onVisibilityChange);
    };
  }, []);

  return hasFocus;
};
