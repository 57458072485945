// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { BulletElementRecordModel, BulletElementRecordModelType } from "./BulletElementRecordModel"
import { BulletElementRecordModelSelector } from "./BulletElementRecordModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/**
 * AccordionSectionRecordBase
 * auto generated base class for the model AccordionSectionRecordModel.
 *
 * Block of type Accordion Section (accordion_section)
 */
export const AccordionSectionRecordModelBase = ModelBase
  .named('AccordionSectionRecord')
  .props({
    __typename: types.optional(types.literal("AccordionSectionRecord"), "AccordionSectionRecord"),
    //_createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    //_editingUrl: types.union(types.undefined, types.null, types.string),
    //_firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    //_isValid: types.union(types.undefined, types.frozen()),
    //_modelApiKey: types.union(types.undefined, types.string),
    //_publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    //_publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    //_seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    //_status: types.union(types.undefined, ItemStatusEnumType),
    //_unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    //_updatedAt: types.union(types.undefined, types.frozen()),
    //createdAt: types.union(types.undefined, types.frozen()),
    elements: types.union(types.undefined, types.array(types.late((): any => BulletElementRecordModel))),
    heading: types.union(types.undefined, types.null, types.string),
    id: types.union(types.undefined, types.frozen()),
    //updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AccordionSectionRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get heading() { return this.__attr(`heading`) }
  get id() { return this.__attr(`id`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  elements(builder: string | BulletElementRecordModelSelector | ((selector: BulletElementRecordModelSelector) => BulletElementRecordModelSelector) | undefined) { return this.__child(`elements`, BulletElementRecordModelSelector, builder) }
}
export function selectFromAccordionSectionRecord() {
  return new AccordionSectionRecordModelSelector()
}

export const accordionSectionRecordModelPrimitives = selectFromAccordionSectionRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._status._unpublishingScheduledAt._updatedAt.createdAt.heading.updatedAt
