// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AttributeModel, AttributeModelType } from "./AttributeModel"
import { AttributeModelSelector } from "./AttributeModel.base"
import { GoalBasedPlanModelOrderBy } from "./GoalBasedPlanModelOrderByEnum"
import { GoalBasedPlanRecordModel, GoalBasedPlanRecordModelType } from "./GoalBasedPlanRecordModel"
import { GoalBasedPlanRecordModelSelector } from "./GoalBasedPlanRecordModel.base"
import { MealPlanItemModel, MealPlanItemModelType } from "./MealPlanItemModel"
import { MealPlanItemModelSelector } from "./MealPlanItemModel.base"
import { PackCategoryEnumType } from "./PackCategoryEnum"
import { PlanCategoryEnumType } from "./PlanCategoryEnum"
import { GoalBasedPlanModelFilterRemoteRelRomecms } from "./RootStore.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { RootStoreType } from "./index"


/**
 * GoalBasedPlanRtoBase
 * auto generated base class for the model GoalBasedPlanRtoModel.
 *
 * Goal Based Plan
 */
export const GoalBasedPlanRtoModelBase = ModelBase
  .named('GoalBasedPlanRto')
  .props({
    __typename: types.optional(types.literal("GoalBasedPlanRto"), "GoalBasedPlanRto"),
    attributes: types.union(types.undefined, types.array(types.late((): any => AttributeModel))),
    category: types.union(types.undefined, PlanCategoryEnumType),
    cms: types.union(types.undefined, types.null, types.late((): any => GoalBasedPlanRecordModel)),
    key: types.union(types.undefined, types.string),
    lineItems: types.union(types.undefined, types.array(types.late((): any => MealPlanItemModel))),
    name: types.union(types.undefined, types.string),
    netPrice: types.union(types.undefined, types.number),
    subCategory: types.union(types.undefined, PackCategoryEnumType),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GoalBasedPlanRtoModelSelector extends QueryBuilder {
  get category() { return this.__attr(`category`) }
  get key() { return this.__attr(`key`) }
  get name() { return this.__attr(`name`) }
  get netPrice() { return this.__attr(`netPrice`) }
  get subCategory() { return this.__attr(`subCategory`) }
  attributes(builder: string | AttributeModelSelector | ((selector: AttributeModelSelector) => AttributeModelSelector) | undefined) { return this.__child(`attributes`, AttributeModelSelector, builder) }
  cms(builder: string | GoalBasedPlanRecordModelSelector | ((selector: GoalBasedPlanRecordModelSelector) => GoalBasedPlanRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GoalBasedPlanModelFilterRemoteRelRomecms | null), locale?: (SiteLocale | null), orderBy?: (GoalBasedPlanModelOrderBy | null)[] | null }) { return this.__child(`cms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GoalBasedPlanRecordModelSelector, builder) }
  lineItems(builder: string | MealPlanItemModelSelector | ((selector: MealPlanItemModelSelector) => MealPlanItemModelSelector) | undefined) { return this.__child(`lineItems`, MealPlanItemModelSelector, builder) }
}
export function selectFromGoalBasedPlanRto() {
  return new GoalBasedPlanRtoModelSelector()
}

export const goalBasedPlanRtoModelPrimitives = selectFromGoalBasedPlanRto().category.key.name.netPrice.subCategory
