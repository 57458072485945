// @ts-nocheck
import _ from 'lodash';
import {
  OrderCategory,
  Frequency,
  OrderSource,
  GiftCertificateModelType,
  CartModelType,
  ProductModelType,
} from '../api';
import { CartInput, GiftCertificateInput } from '../api/RootStore.base';
import type { Cart } from '../cart/cart';
import { OrderPlan } from '../cart/order-plan';
import type { Customer } from '../customer/customer';
import { roundTotal, sanitize } from '../../utils/helpers';
import randomise from 'randomatic';

export const formatAbandonCart = (cart: Cart, user: Customer): CartInput => {
  const customer = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    phone: user.phone || ' ',
  };

  const plans = _.map(cart.plans, (plan: OrderPlan) =>
    formatCartOrderPlan(plan),
  ).filter((plan) => plan.netPrice > 0);

  // format giftCertificate
  const giftCertificates = cart.giftCertificates.map(formatGiftCertificate);

  // convert address -- ignore by gift card only order
  const deliveryAddress = cart?.delivery?.deliveryAddress?.cartAddressInput;
  const deliveryDate = cart?.delivery.deliveryDate;
  const timeSlot = cart?.delivery.timeSlot;
  const delivery = deliveryAddress
    ? { delivery: { deliveryAddress, deliveryDate, timeSlot } }
    : {};
  const billing = cart.billingAddress
    ? { billing: cart?.billingAddress?.cartAddressInput }
    : {};

  // Fix below
  const category = 'DEFAULT' as OrderCategory;
  const frequency =
    (cart.isGiftItemOnly ? Frequency.ONCE : cart.frequency) || undefined;
  const source = OrderSource.WEB as OrderSource;

  const totals = {
    orderTotal: cart.total,
    shippingTotal: cart.shippingCostBase,
    itemTotal: cart.subTotal,
  };

  // return formatted result
  return {
    plans,
    category,
    giftCertificates,
    customer,
    frequency,
    source,
    totals,
    ...billing,
    ...delivery,
  };
};

/**
 * format order plan for cart
 * *promotionTotal calculation is different from order atm
 * @param orderPlan
 * @returns
 */
export const formatCartOrderPlan = (orderPlan: OrderPlan) => {
  const discount = orderPlan.discount;
  const isWlp = orderPlan.isWlpPlan;
  let calSubTotal = 0;
  const lineItems = _.map(orderPlan.lineItems, (lineItem, index) => {
    const product = lineItem.sku._id;
    const { netPrice, sku } = lineItem.sku;
    const isLast = index === orderPlan.lineItems.length - 1;
    const discountFunction = isWlp
      ? 'getWLPDiscountedPrice'
      : 'getDiscountedPrice';
    const price =
      isWlp && isLast
        ? (orderPlan.total - calSubTotal) / lineItem.qty
        : (lineItem.sku[discountFunction](discount) as ProductModelType);
    calSubTotal += price;
    return {
      sku,
      product,
      qty: lineItem.qty,
      promotionTotal: roundTotal(netPrice - price),
      attributes: lineItem.attributes?.map((attr) => attr.payload),
    };
  });
  const { planId, category, subCategory, name, image, total, attributes } =
    orderPlan;
  return sanitize({
    planId,
    category,
    subCategory,
    name,
    image,
    netPrice: total,
    attributes: attributes.map((att) => att.payload),
    lineItems,
  });
};

export const formatGiftCertificate = (
  giftItem: GiftCertificateModelType,
): GiftCertificateInput => {
  return {
    netPrice: giftItem?.netPrice || 0,
    lineitemText: giftItem.lineitemText?.trim(),
    senderName: giftItem.senderName || '',
    recipientName: giftItem.recipientName || '',
    recipientEmail: giftItem.recipientEmail || '',
  };
};

export const parseCart = (cart: CartModelType) => {
  const updatedAt = new Date(cart.updatedAt);
  // remove all fields that have a null as a value
  const omitNullCart = _.omitBy(cart, _.isNull);
  const cartData = {
    ...omitNullCart,
    cartId: randomise('Aa0', 48),
    updatedAt,
    refreshedAt: updatedAt,
  };
  return cartData;
};
