import { onPatch } from 'mobx-state-tree';
import * as _ from 'lodash';
import type { ProductFilter } from '@/models/product/filters';
import { Cart } from '../cart/cart';
import { GeneralStoreType } from '../general/general-store';
import { FEATURE_FLAGS } from '@/utils/constants';
import { reaction } from 'mobx';

const FILTER_OBSERVER_ATTRIBUTES = ['sort', 'selectedFilters'];
const CART_OBSERVER_ATTRIBUTES = [
  'plans',
  'giftCertificates',
  // 'billingAddress',
  // 'frequency',
  // 'shippingCostBase',
  // 'delivery',
];

export function onPatchFilter(filter: ProductFilter, callback?: any) {
  return onPatch(filter, (patch) => {
    if (patch.path) {
      const attribute = _.nth(_.split(patch.path, '/'), 1);

      if (attribute && FILTER_OBSERVER_ATTRIBUTES.includes(attribute)) {
        callback();
      }
    }
  });
}

export function onPatchCart(cart: Cart, callback?: any) {
  return onPatch(cart, (patch) => {
    if (patch.path) {
      const attribute = _.nth(_.split(patch.path, '/'), 1);
      if (attribute && CART_OBSERVER_ATTRIBUTES.includes(attribute)) {
        callback();
      }
    }
  });
}

export function reactionCart(cart: Cart, generalStore: GeneralStoreType) {
  return reaction(
    () => [
      generalStore.SMOV,
      generalStore.freeShippingThreshold,
      generalStore.reactionCartReady,
      cart?.variant,
    ],
    (
      [smov, freeShippingThreshold, ready, variant],
      [prevSmov, prevFreeShippingThreshold],
    ) => {
      if (!ready) {
        return;
      }
      if (
        smov === prevSmov &&
        freeShippingThreshold === prevFreeShippingThreshold
      ) {
        return;
      }
      if (variant === FEATURE_FLAGS.NEW_ORDER_FREQUENCY) {
        cart?.resetFrequency();
      }
    },
  );
}
