import _ from 'lodash';

import {
  FILTER_SELECTOR,
  MEGA_NAV_SELECTOR,
  NEW_SORT_SELECTOR,
} from '@/graphql/selectors';
import {
  CMS_SEO_SELECTOR,
  GOAL_BASED_QUIZ_SELECTOR,
  MENU_CATEGORY_SELECTOR,
} from '@/graphql/selectors/cms';
import {
  DYNAMIC_LANDING_PAGE_SELECTOR,
  LANDING_PAGE_V2_SELECTOR,
  MENU_V2_SELECTOR,
} from '@/graphql/selectors/landingPage';
import type {
  DynamicLandingPageContentRecordModelType,
  FilterRecordModelType,
  GoalBasedQuizRecordModelType,
  LandingPageV2RecordModelType,
  MenuHierarchyRecordModelType,
  MenuSortOptionRecordModelType,
  NavbarRecordModelType,
} from '@/models/api';
import type { RootStoreType } from '@/models/root-store';
import type { SeoData } from '@/shared/types';
import {
  filterActiveMenuHierarchies,
  populateHierarchySlug,
} from '@/utils/helpers';

export type MenuBuilderDataV2 = {
  filterCmsData: FilterRecordModelType;
  sortMenuCms: MenuSortOptionRecordModelType[];
  allMenuHierarchiesCms: MenuHierarchyRecordModelType[];
  landingPageV2Cms: LandingPageV2RecordModelType;
  searchFilterCmsData: FilterRecordModelType;
};

type DataFetcherName = keyof MenuBuilderDataV2;

type PartialMenuBuilderDataV2 = Partial<MenuBuilderDataV2>;

export const getMenuBuilderDataV2 = async (
  store: RootStoreType,
  dataToFetch?: DataFetcherName[],
): Promise<MenuBuilderDataV2> => {
  const result: PartialMenuBuilderDataV2 = {};

  const fetchAll = !dataToFetch || dataToFetch.length === 0;

  if (fetchAll || dataToFetch.includes('filterCmsData')) {
    const { filterCms } = await store.api.queryFilterCms(
      {
        filter: {
          key: {
            eq: 'website-main-v2',
          },
        },
      },
      FILTER_SELECTOR,
    );
    result.filterCmsData = filterCms;
  }
  if (fetchAll || dataToFetch.includes('searchFilterCmsData')) {
    const { filterCms } = await store.api.queryFilterCms(
      {
        filter: {
          key: {
            eq: 'website-search-filter',
          },
        },
      },
      FILTER_SELECTOR,
    );
    result.searchFilterCmsData = filterCms;
  }

  if (fetchAll || dataToFetch.includes('sortMenuCms')) {
    const { allMenuSortOptionsCms } =
      await store.api.queryAllMenuSortOptionsCms({}, NEW_SORT_SELECTOR);
    result.sortMenuCms = allMenuSortOptionsCms;
  }

  if (fetchAll || dataToFetch.includes('allMenuHierarchiesCms')) {
    const { allMenuHierarchiesCms } =
      await store.api.queryAllMenuHierarchiesCms(
        {
          filter: {
            category: {
              eq: 'category',
            },
            active: {
              eq: true,
            },
          },
        },
        MENU_CATEGORY_SELECTOR,
      );
    result.allMenuHierarchiesCms = allMenuHierarchiesCms;
  }

  if (fetchAll || dataToFetch.includes('landingPageV2Cms')) {
    const { landingPageV2Cms } = await store.api.queryLandingPageV2Cms(
      {
        filter: { slug: { eq: 'menu' } },
      },
      MENU_V2_SELECTOR,
    );
    result.landingPageV2Cms = landingPageV2Cms;
  }

  if (fetchAll) {
    return result as MenuBuilderDataV2;
  }

  return Object.fromEntries(
    Object.entries(result).filter(([key]) =>
      dataToFetch?.includes(key as DataFetcherName),
    ),
  ) as MenuBuilderDataV2;
};

export const getMegaNavCategories = async (
  store: RootStoreType,
): Promise<MenuHierarchyRecordModelType[]> => {
  const { allMenuHierarchiesCms } = await store.api.queryAllMenuHierarchiesCms(
    {
      filter: {
        category: {
          eq: 'category',
        },
        active: {
          eq: true,
        },
      },
    },
    MENU_CATEGORY_SELECTOR,
  );

  // Filter out Liked, Specials and New
  let categories = _.filter(
    allMenuHierarchiesCms,
    (category) => category?.showOnNavbar,
  );

  // Filter out inactive categories
  categories = filterActiveMenuHierarchies(categories);
  populateHierarchySlug(categories);

  return categories;
};

export const getGeneralPageSeoData = async (
  store: RootStoreType,
  slug: string,
): Promise<SeoData> => {
  const { generalPageCms } = await store.api.queryGeneralPageCms(
    {
      filter: {
        slug: {
          eq: slug,
        },
      },
    },
    CMS_SEO_SELECTOR,
  );
  return generalPageCms;
};

export const getGoalBasedQuizQuestions = async (
  store: RootStoreType,
  key: string,
): Promise<GoalBasedQuizRecordModelType> => {
  const { goalBasedQuizCms } = await store.api.queryGoalBasedQuizCms(
    {
      filter: {
        key: {
          eq: key,
        },
      },
    },
    GOAL_BASED_QUIZ_SELECTOR,
  );
  return goalBasedQuizCms;
};

export const getLandingPageVariation = async (
  store: RootStoreType,
  campaign: string,
  slug: string,
  content: string,
): Promise<DynamicLandingPageContentRecordModelType | null> => {
  const { dynamicLandingPageCampaignCms } =
    await store.api.queryDynamicLandingPageCampaignCms(
      {
        filter: { campaign: { eq: campaign } },
      },
      DYNAMIC_LANDING_PAGE_SELECTOR,
    );

  if (!dynamicLandingPageCampaignCms) {
    return null;
  }

  const { landingPageVariations } = dynamicLandingPageCampaignCms;
  const matchingVariations = landingPageVariations.filter(
    (variation: { slug: string }) => variation.slug === slug,
  );

  const foundVariation = matchingVariations.find(
    (variation: { content: string }) => variation.content === content,
  );

  if (foundVariation) {
    return foundVariation;
  }

  const defaultVariation = matchingVariations.find(
    (variation: { content: string }) => variation.content === 'default',
  );

  return defaultVariation;
};

export const getLandingPageV2SeoData = async (
  store: RootStoreType,
  slug: string,
): Promise<SeoData> => {
  const { landingPageV2Cms } = await store.api.queryLandingPageV2Cms(
    {
      filter: {
        slug: {
          eq: slug,
        },
      },
    },
    LANDING_PAGE_V2_SELECTOR,
  );
  return landingPageV2Cms;
};

export const getNavbarData = async (
  store: RootStoreType,
): Promise<NavbarRecordModelType> => {
  const { navbarCms } = await store.api.queryNavbarCms({}, MEGA_NAV_SELECTOR);
  return navbarCms;
};
