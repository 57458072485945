// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ActivityType {
  CHEST="CHEST",
FOOD="FOOD",
HIPS="HIPS",
MOVEMENT="MOVEMENT",
PROFILE="PROFILE",
TRAINING="TRAINING",
WATER="WATER",
WEIGHT="WEIGHT",
WELLNESS="WELLNESS"
}

/**
* ActivityType
*/
export const ActivityTypeEnumType = types.enumeration("ActivityType", [
        "CHEST",
  "FOOD",
  "HIPS",
  "MOVEMENT",
  "PROFILE",
  "TRAINING",
  "WATER",
  "WEIGHT",
  "WELLNESS",
      ])
