// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum MarketingContentApprovalWorkflowStage {
  approved="approved",
inReview="inReview",
writingInDraft="writingInDraft"
}

/**
* MarketingContentApprovalWorkflowStage
*/
export const MarketingContentApprovalWorkflowStageEnumType = types.enumeration("MarketingContentApprovalWorkflowStage", [
        "approved",
  "inReview",
  "writingInDraft",
      ])
